import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TelegramCore } from '@/app-cores/telegram';
import { useWalletStore } from '@/app-store';
import { useMutation } from '@tanstack/react-query';
import { useMutationUpdateUserWallet } from '../api/mpc-wallet';
import { AuthClient } from '@/app-cores/auth';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { queryClient } from '@/app-cores/query-client';

export function useMutationCreateWallet() {
	const { setWalletStatus } = useWalletStore();
	const { mutateAsync: updateUserWallet } = useMutationUpdateUserWallet();

	const { mutateAsync: createMpcWallet, ...result } = useMutation({
		mutationKey: ['create-mpc-wallet'],
		mutationFn: async () => {
			try {
				setWalletStatus('CREATING');
				const [ecdsaWallet, eddsaWallet] = await Promise.all([
					MpcWallet.createEcdsaWallet(),
					MpcWallet.createEddsaWallet(),
				]);
				const payload = JSON.stringify(
					TelegramCore.withInitData({
						address: {
							evm: ecdsaWallet.address,
							'ton-base': eddsaWallet.tonAddress,
							'solana': eddsaWallet.solAddress,
							tron: ecdsaWallet.tronAddress,
							sui: eddsaWallet.suiAddress,
						},
						timestamp: Date.now(),
					}),
				);
				const signature = await MpcWallet.verifyWallet(payload);
				await updateUserWallet({
					payload,
					signature: signature,
				});
				try {
					await AuthClient.authenticate();
					await queryClient.invalidateQueries({
						queryKey: ['query-user-profile', TelegramCore.getUserId()],
					});
				} catch (error) {
					console.error('reauthenticate error', error);
				}
				MpcWallet.storeUnSecuredKeyShare({
					ecdsaKeyShare2: ecdsaWallet.keyShare2,
					eddsaKeyShare2: eddsaWallet.keyShare2,
				});
				MpcWallet.persistKeyShare().then(() => {
					console.log('Upload Successfully');
				});
				setWalletStatus('REQUIRE_BACKUP');
				dataDogAddAction(DATADOG_ACTIONS.CREATE_ACCOUNT_SUCCESS);
			} catch (error) {
				dataDogAddError(error, {
					tags: {
						name: DATADOG_ERROR_TAGS.CREATE_WALLET,
						function: 'createWallet',
					},
				});
				console.log('Create wallet Error:', error);
				throw error;
			}
		},
		retry: 3,
	});
	return {
		...result,
		createMpcWallet,
	};
}

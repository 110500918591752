import { ITokenSearch } from '@/app-cores/api/bff';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { getNativeToken, getTokenInfo, isSolanaChain } from '@/app-helpers/token';
import { useMemeBondingCurveInfo, useMemeBondingCurvePercent } from '@/app-hooks/api/memepad';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { Card, CardBody, Collapse, Progress, Text, VStack } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

export default function CurveProgress({ memeInfo }: { memeInfo: MemeTokenInfo | undefined; token: ITokenSearch }) {
	const { complete, chainId, poolLink, dex, symbol, assetTobiId } = memeInfo ?? {};
	const { data: progress, isFetching: isFetchingProgress } = useMemeBondingCurveInfo(memeInfo);

	const { data: percentData, isFetching } = useMemeBondingCurvePercent(memeInfo);

	const { t } = useTranslation();
	const sol = getNativeToken(chainId);
	const { data } = useSearchSingleToken({ tobiId: assetTobiId, chainId });
	const percent = complete ? 100 : Math.floor(percentData || 0);
	return (
		<Card>
			<CardBody p={4} fontSize={'12px'} display={'flex'} flexDirection={'column'} gap={3}>
				<VStack align={'flex-start'}>
					<Text>
						{t('memepad.bondingProgress')}:{' '}
						<Text as="span" fontWeight={'500'}>
							{percent}%
						</Text>
					</Text>
					<Progress
						isIndeterminate={isFetching}
						background={BASE_BORDER_COLOR}
						value={percent}
						width={'100%'}
						size="xs"
						colorScheme={'cyan'}
					/>
				</VStack>
				<Collapse in={complete || !isFetchingProgress}>
					<Text>
						{!complete ? (
							t(isSolanaChain(chainId) ? 'memepad.bondingDesc' : 'memepad.bondingDescVirtual', {
								targetMCapUsd: formatUsd(getTokenInfo(data).priceUsd * +progress?.targetMCap),
								targetMCap: formatCurrency(progress?.targetMCap),
								nativeLeft: formatCurrency(progress?.nativeLeft),
								native: progress?.symbol,
								tokenLeft: formatCurrency(progress?.tokenLeft, { compact: true }),
								symbol,
								dex,
							})
						) : (
							<Trans
								i18nKey="memepad.completedBondingDesc"
								values={{
									link: `here`,
									dex,
								}}
								components={[
									<a href={poolLink} target="_blank" style={{ textDecoration: 'underline' }} />,
								]}
							/>
						)}
					</Text>
				</Collapse>
			</CardBody>
		</Card>
	);
}

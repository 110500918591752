import { InputCustom, Toast } from '@/app-components/common';
import { useTonConnect } from '@/app-features/app-ton-connect/hooks/useConnect';
import { Box, Button, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useConnect } from '../hooks';
import { getTonConnectParams } from '../utils/HelperUtil';

interface ConnectFormProps {
	onSubmit?: () => void;
}
export const ConnectForm: React.FC<ConnectFormProps> = ({ onSubmit }) => {
	const [uri, setUri] = useState('');
	const { onConnect, isLoading } = useConnect();
	const { onConnect: onTonConnect } = useTonConnect();
	const { t } = useTranslation();

	return (
		<Box border="1px solid" borderColor="gray.100" borderRadius={16} padding={3}>
			<InputGroup>
				<InputCustom
					placeholder={t('walletConnect.connectUri')}
					fontSize="sm"
					border="none"
					_focus={{
						boxShadow: 'none',
					}}
					value={uri}
					pl={0}
					onChange={(e) => setUri(e.target.value)}
				/>

				<InputRightElement cursor="pointer" width="auto" position="relative" marginLeft="auto">
					<Button
						width="100%"
						colorScheme="cyan"
						isDisabled={!uri}
						minWidth={24}
						onClick={async () => {
							try {
								const { connectionUrl, isTonConnect } = getTonConnectParams(uri);
								if (uri.startsWith('tc://') || isTonConnect) {
									onTonConnect(connectionUrl);
								} else {
									toast(
										<Toast
											type="error"
											message="Only supports connecting to dApps via TON Connect"
										/>,
									);

									// await onConnect(uri);
								}

								onSubmit?.();
								setUri('');
							} catch (error) {}
						}}
					>
						{isLoading ? <Spinner size="sm" /> : t('walletConnect.connect')}
					</Button>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
};

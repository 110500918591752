import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getShortAddress } from '@/app-helpers/address';
import { formatUsd } from '@/app-helpers/number';
import { formatTimeRecent } from '@/app-helpers/time';
import { isSolanaChain } from '@/app-helpers/token';
import { toQueryString } from '@/app-helpers/url';
import { useMemeBondingCurvePercent, useMemeMarketCapData, useMemeMetaData } from '@/app-hooks/api/memepad';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { MemePlatformTitleWithLogo } from '@/app-views/home/containers/TobiFun';
import { Card, CardBody, Collapse, Flex, Progress, Skeleton, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

export function MemeTokenItem({ data, label }: { data: MemeTokenInfo; label?: boolean }) {
	const {
		name,
		symbol,
		logo,
		creator,
		marketCapUsd,
		createdTime,
		description,
		uri,
		complete,
		bondingCurve,
		address,
		chainId,
		platform,
	} = data;

	const tokenMissingInfo = uri;

	const { data: metadata, isFetching: isLoadingMeta } = useMemeMetaData(tokenMissingInfo ? uri : '', chainId);
	const { data: marketData, isFetching } = useMemeMarketCapData(
		isSolanaChain(chainId) ? (tokenMissingInfo ? data : null) : data,
	);
	const { data: percentData, isFetching: loadingPercent } = useMemeBondingCurvePercent(data);

	const navigate = useNavigate();

	const onClickMeme = () => {
		navigate(
			toQueryString(NAVIGATE_PATHS.TobiFun.Detail, {
				mint: address,
				chainId,
			}),
		);
	};

	const percent = complete ? 100 : Math.floor(percentData || 0);
	return (
		<Collapse in={tokenMissingInfo ? !isLoadingMeta : true}>
			{label && (
				<Flex
					fontSize={'10px'}
					py={1}
					align={'center'}
					justify={'center'}
					borderTopRightRadius={'8px'}
					borderTopLeftRadius={'8px'}
					bg={colors.white}
					width={'120px'}
				>
					<MemePlatformTitleWithLogo size={'12px'} platform={platform} />
				</Flex>
			)}
			<Card onClick={onClickMeme} borderTopLeftRadius={label ? '0' : undefined}>
				<CardBody p={3}>
					<Flex fontSize={'14px'} gap={3}>
						<TokenLogo loading={isLoadingMeta} logo={logo || metadata?.image} size={40} chainId={chainId} />
						<Flex direction={'column'} flex={1} gap={2}>
							<Flex justifyContent={'space-between'} flex={1}>
								<Text fontWeight={'600'}>
									{name} (${symbol})
								</Text>
								<Text
									whiteSpace={'nowrap'}
									fontSize={'12px'}
									textAlign={'right'}
									color={colors.gray[400]}
									minWidth={'60px'}
								>
									{formatTimeRecent(createdTime)}
								</Text>
							</Flex>
							<Text fontSize={'12px'}>
								<Text color={colors.gray[400]} as="span">
									Created by:
								</Text>{' '}
								{getShortAddress(creator)}
							</Text>
							{description && (
								<Text
									as="span"
									fontSize={'12px'}
									color={colors.gray[400]}
									sx={{
										wordBreak: 'break-all',
										display: '-webkit-box' /* Establishes the flex container for text truncation */,
										WebkitBoxOrient: 'vertical' /* Specifies the block progression for text flow */,
										overflow: 'hidden' /* Hides the overflowing text */,
										textOverflow: 'ellipsis' /* Adds ellipsis to the truncated text */,
										lineClamp: 3,
										WebkitLineClamp: 3,
										lineHeight: '16px' /* Adjust line height for consistent spacing */,
										maxHeight: 16 * 3 + 'px',
									}}
								>
									{description}
								</Text>
							)}

							<Flex justify={'space-between'} fontSize={'12px'}>
								<Flex gap={1} align={'center'}>
									<Text color={colors.gray[400]} as="span">
										Market Cap:
									</Text>{' '}
									{isFetching ? (
										<Skeleton
											opacity="0.3"
											borderRadius={'4px'}
											style={{ height: '12px', width: '60px' }}
										/>
									) : (
										formatUsd(marketCapUsd || marketData?.marketCap)
									)}
								</Flex>
								{complete ? (
									<Text className="text-gradient ">Complete!</Text>
								) : (
									!loadingPercent && <Text>{percent}%</Text>
								)}
							</Flex>
							{!complete && (
								<Progress
									isIndeterminate={loadingPercent}
									background={BASE_BORDER_COLOR}
									value={percent}
									width={'100%'}
									size="xs"
									colorScheme={'cyan'}
								/>
							)}
						</Flex>
					</Flex>
				</CardBody>
			</Card>
		</Collapse>
	);
}

import { Box, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Balance } from '../../../../app-components/common/Balance';

interface WalletBalanceProps {
	totalUsd?: number;
}

export const WalletBalance: React.FC<WalletBalanceProps> = ({ totalUsd }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Center>
				<Text fontSize={'12px'}>{t('affiliate.totalFeeEarned')}</Text>
			</Center>
			<Balance amount={totalUsd} />
		</Box>
	);
};

import { getFullScreenSpace } from '@/app-helpers/ui';
import { useContactStore } from '@/app-store';
import { Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from '.';
import { Header, Loading } from '..';
import { ContactSearch } from './ContactSearch';

interface ContactPopupProps {}
export const ContactPopup: React.FC<ContactPopupProps> = () => {
	const { isOpen, setOpen } = useContactStore();
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	return (
		<Drawer size="full" isOpen={isOpen} onClose={() => setOpen(false)} placement="bottom" trapFocus={false}>
			<DrawerContent pt={getFullScreenSpace(0, 76)}>
				{isOpen && (
					<>
						<DrawerHeader>
							<DrawerCloseButton top={getFullScreenSpace(0, 86)} />
							<ContactSearch
								titleElement={
									<Center>
										<Header mb={6}>{t('cryptos.sendTo')}</Header>
									</Center>
								}
							/>
							{/* <AlertInfo /> */}
						</DrawerHeader>

						<DrawerBody className="hide-scrollbar" position="relative" pt={0}>
							{loading && <Loading position="absolute" opacity="0.7" />}
							<Contact onSelect={() => setOpen(false)} onLoadData={(loading) => setLoading(loading)} />
						</DrawerBody>
					</>
				)}
			</DrawerContent>
		</Drawer>
	);
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import { RecoveryType } from '../../components/RecoveryType';
import { AppLogoIcon, FiGoogleDriverIcon, FiRestoreIcon } from '@/assets/icons';
import { UploadFile } from '../../components/UploadFile';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { MAPPING_BACKUP_TYPE } from '@/app-views/account-setting/AccountSetting';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useMemo, useState } from 'react';
import { AlertRecreateWallet } from './AlertRecreateWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import PageLayout from '@/app-components/layout/PageLayout';
import { CTAButton } from '@/app-components/common';

function capitalizeFirstLetter(text) {
	if (!text) return '';
	return text.charAt(0).toUpperCase() + text.slice(1);
}
const RECOVERY_TYPES = [
	{
		img: <FiGoogleDriverIcon />,
		type: RECOVERY_TYPE.DRIVE,
		title: 'recovery.methods.googleDrive.title',
		description: 'recovery.methods.googleDrive.description',
	},
];

interface RecoverySelectMethodProps {}

const RecoverySelectMethod: React.FunctionComponent<RecoverySelectMethodProps> = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [isOpenAlertRecreateWallet, setIsOpenAlertRecreateWallet] = useState(false);
	const { data: userProfile, isLoading } = useQueryUserProfile();
	const isShowGGDriveRecovery = useQueryFeatureConfig(FeatureConfig.RECOVERY_G_DRIVE);

	const onRecovery = useOnEventCallback((type: string) => {
		navigate(`${NAVIGATE_PATHS.Recovery.InputSecretKey}?recoveryType=${type}`);
	});

	const recoveryType = userProfile?.backupType
		? RECOVERY_TYPES.find((rType) => rType.type === userProfile?.backupType && isShowGGDriveRecovery)
		: null;
	const isAlreadyBackup = !!userProfile?.backupType;
	const isMissingLocalKeyshare = !MpcWallet.getLocalKeyshareStored();
	const backupIdentifier = userProfile?.backupType === 'telegram' ? '@' : '';
	const backupInfo = userProfile?.backupEmail ? `: ${backupIdentifier}${userProfile.backupEmail}` : '';
	const isAllowCreateNewWallet = !isAlreadyBackup && isMissingLocalKeyshare;

	const renderContent = useMemo(() => {
		if (isAlreadyBackup)
			return (
				<>
					<Center>
						<FiRestoreIcon />
					</Center>
					<Text as="h1" textAlign="center" fontSize="28px" fontWeight={500} my={2}>
						{t('recovery.title')}
					</Text>

					<Text mt={4} textAlign="center" fontSize="xs" fontWeight={400} color="gray.400">
						{t('recovery.alreadyBackup')}
					</Text>
					<Box mt={6}>
						{recoveryType ? (
							<RecoveryType
								email={userProfile?.backupEmail}
								recoveryType={recoveryType}
								onClick={() => onRecovery(recoveryType.type)}
							/>
						) : null}
					</Box>
					<>
						{!!recoveryType && (
							<Text my={2} fontSize="xs" fontWeight={500} textAlign="center">
								Or
							</Text>
						)}
						<UploadFile
							showIcon
							title={t('recovery.methods.uploadFile.title')}
							description={t('recovery.methods.uploadFile.description', {
								backupMethod:
									userProfile.backupType === 'drive'
										? 'Google Drive'
										: capitalizeFirstLetter(userProfile.backupType),
							})}
							email={
								userProfile?.backupEmail
									? `(${t(MAPPING_BACKUP_TYPE[userProfile?.backupType])}${backupInfo})`
									: ''
							}
						/>
					</>
				</>
			);
		return (
			<>
				<Center>
					<AppLogoIcon />
				</Center>
				<Text as="h1" fontSize="20px" lineHeight="25px" fontWeight={400} my={4}>
					{t('recovery.notBackupYet')}
				</Text>

				<Text mt={3} fontSize="xs" fontWeight={400} color="gray.400">
					{t('recovery.backupSuggestion')}
				</Text>
			</>
		);
	}, [backupInfo, isAlreadyBackup, onRecovery, recoveryType, t, userProfile?.backupEmail, userProfile.backupType]);

	return (
		<PageLayout
			footer={
				isAllowCreateNewWallet && (
					<Box px={4} my={4}>
						<CTAButton
							onClick={() => setIsOpenAlertRecreateWallet(true)}
							width="100%"
							size="lg"
							colorScheme="cyan"
						>
							{t('button.recreateWallet')}
						</CTAButton>
					</Box>
				)
			}
		>
			<Container py={8}>
				{renderContent}
				{!isLoading && isAllowCreateNewWallet && (
					<AlertRecreateWallet
						isOpen={isOpenAlertRecreateWallet}
						onClose={() => setIsOpenAlertRecreateWallet(false)}
					/>
				)}
			</Container>
		</PageLayout>
	);
};
export default RecoverySelectMethod;

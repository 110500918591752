import { STORAGE_KEYS } from '@/app-constants/storage';
import { TON_CENTER_API_KEY } from '@/app-cores/api/axios';
import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { TonTransaction } from '@/app-cores/api/toncenter/type';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getHttpV4Endpoint } from '@orbs-network/ton-access';
import { Address, Cell } from '@ton/core';
import { JettonMaster, TonClient } from '@ton/ton';
import keyShareManager from '../wallet/keyshare-manager';
import { WalletContractV4 } from './WalletContractV4';
import { TonClient4 } from './client/TonClient4';

function base64ToHex(base64String) {
	// Decode Base64 string to a binary string
	const binaryString = atob(base64String);

	// Convert binary string to hex
	let hexString = '';
	for (let i = 0; i < binaryString.length; i++) {
		const hex = binaryString.charCodeAt(i).toString(16).padStart(2, '0');
		hexString += hex;
	}
	return hexString;
}

enum NetWorkEndpoint {
	testnet = 'https://testnet-v4.tonhubapi.com',
	mainnet = 'https://mainnet-v4.tonhubapi.com',
}
export const TON_CENTER_RPC = 'https://toncenter.com/api/v2/jsonRPC';
export const TON_X_RPC = `${import.meta.env.VITE_BOT_API}/trading/mainnet-rpc.tonxapi/migration/ton-http-api`;

async function getTonClient(network: 'testnet' | 'mainnet') {
	const isDisableOrbs = localStorage.getItem(STORAGE_KEYS.TOBI_DISABLE_ORBS_RPC) === '1';
	const isDisableTonX = localStorage.getItem(STORAGE_KEYS.TOBI_DISABLE_TON_X_RPC) === '1';
	if (!isDisableTonX) return new TonClient4({ endpoint: TON_X_RPC });
	if (isDisableOrbs) {
		return new TonClient4({ endpoint: NetWorkEndpoint[network] });
	} else {
		const endpoint = await getHttpV4Endpoint(); // get the decentralized RPC endpoint
		return new TonClient4({ endpoint });
	}
}
export class TonWallet {
	static async create(network: 'testnet' | 'mainnet') {
		const client = await getTonClient(network);
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager?.keyShares?.eddsaKeyShare?.publicKey()),
		});
		const contract = await client.open(wallet);
		return { wallet, contract, client };
	}
	static async getUserJettonWalletAddress(jettonAddress: string) {
		const client = new TonClient({
			endpoint: TON_CENTER_RPC,
			apiKey: TON_CENTER_API_KEY,
		});
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager?.keyShares?.eddsaKeyShare?.publicKey()),
		});
		const userAddress = wallet.address;
		const jettonMasterAddress = Address.parse(jettonAddress); // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
		const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
		const userJettonWallet = await jettonMaster.getWalletAddress(userAddress);
		return userJettonWallet;
	}
	static async getTonSpaceJettonWalletAddress(tonSpaceWallet: Address, jettonAddress: string) {
		const client = new TonClient({
			endpoint: TON_CENTER_RPC,
			apiKey: TON_CENTER_API_KEY,
		});
		const jettonMasterAddress = Address.parse(jettonAddress); // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
		const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
		const tonSpaceJettonWallet = await jettonMaster.getWalletAddress(tonSpaceWallet);
		return tonSpaceJettonWallet;
	}

	static async getTonCenterTxs() {
		try {
			const transactions = await TonCenterServiceAPI.getTransactions({
				address: MpcWallet.getWalletAddress().tonAddress,
				limit: 10,
				archival: true,
			});
			return transactions;
		} catch (error) {
			return [];
		}
	}

	static getRealTransactionHash(transactions: TonTransaction[], bocHashBase64OrQueryID: string) {
		try {
			// find by boc hash
			let tx = transactions.find(
				(e) =>
					e.in_msg?.body_hash === bocHashBase64OrQueryID ||
					e.out_msgs?.some((msg) => msg.body_hash === bocHashBase64OrQueryID),
			)?.transaction_id?.hash;
			if (tx) return base64ToHex(tx);

			// find by query Id
			tx = transactions.find((e) =>
				e.out_msgs?.some(
					(msg) => TonWallet.getQueryIdFromBody(msg.msg_data?.body).toString() === bocHashBase64OrQueryID,
				),
			)?.transaction_id?.hash;
			if (tx) return base64ToHex(tx);
		} catch (error) {}
	}

	static getQueryIdFromBody(body: string): number {
		try {
			// Decode the Base64-encoded string into a Buffer
			const buffer = Buffer.from(body, 'base64');

			// Convert the buffer back to a Cell
			const cell = Cell.fromBoc(buffer)[0]; // The first cell in the BOC

			// Create a Slice to read the Cell
			const slice = cell.beginParse();

			// Read the fields in the same order they were stored
			const transferOp = slice.loadUint(32); // Read the operation (JettonWallet.TRANSFER)
			const queryId = slice.loadUint(64); // Read the queryId (64-bit integer)

			// Extract other fields if needed, but for queryId we stop here
			return queryId;
		} catch (error) {
			return -1;
		}
	}
}

import {
	Box,
	Button,
	Drawer,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectWithLinkPopup } from '.';
import { ConnectWithQrCode } from './ConnectWithQrCode';

interface SelectMethodPopupProps {
	isOpen: boolean;
	onClose: () => void;
}
export const SelectMethodPopup: React.FC<SelectMethodPopupProps> = ({ isOpen, onClose }) => {
	const [openConnectWithLinkPopup, setOpenConnectWithLinkPopup] = useState(false);
	const { t } = useTranslation();
	return (
		<Box>
			<Drawer isOpen={isOpen} placement="bottom" onClose={onClose} trapFocus={false}>
				<DrawerOverlay />
				{isOpen && (
					<DrawerContent>
						<DrawerHeader display="flex" justifyContent="center" alignItems="center" fontSize="md">
							{t('walletConnect.connectDApp')}
						</DrawerHeader>
						<DrawerCloseButton />
						<Box as="hr"></Box>
						<DrawerFooter display="flex" flexDirection="column" gap={2}>
							<ConnectWithQrCode onClick={onClose} />
							<Button
								size="lg"
								fontSize="sm"
								width="100%"
								colorScheme="gray"
								onClick={() => {
									setOpenConnectWithLinkPopup(true);
									onClose();
								}}
							>
								{t('walletConnect.tonConnectLink')}
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}
			</Drawer>
			{openConnectWithLinkPopup && (
				<ConnectWithLinkPopup
					isOpen={openConnectWithLinkPopup}
					onClose={() => setOpenConnectWithLinkPopup(false)}
				/>
			)}
		</Box>
	);
};

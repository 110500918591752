import { CHAIN_CONFIG, ChainId, ONE_ADDRESS, ZERO_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { EclipseWallet } from '@/app-cores/mpc-wallet/eclipse';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { compareAddress, compareChain, isNativeToken } from '@/app-helpers/address';
import { uniqueId } from '@/app-helpers/random';
import { getNativeToken, isEclipseChain, isEvmChain } from '@/app-helpers/token';
import { filterParams, getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap/index';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';
import { useSubmitEVMTransaction } from '@/app-hooks/transactions';
import { SelectedRoute } from '@/app-store/swap';
import { TGasFeeType, TransactionType } from '@/app-types';
import { VersionedTransaction } from '@solana/web3.js';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { ethers, formatUnits } from 'ethers';

const formatRoute = (routeData: RouteOwlto, params: ArgsGetRoute): SelectedRoute =>
	routeData
		? {
				...params,
				...routeData,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.OWLTO,
				routerAddress: '',
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const API_DOMAIN = 'https://owlto.finance/bridge_api';

type TokenValue = {
	raw_value: string;
	ui_value: string;
	decimals: number;
};

type Pair = {
	token_name: string;
	from_chain_name: string;
	to_chain_name: string;
	from_chain_id: string;
	to_chain_id: string;
	from_token_address: string;
	to_token_address: string;
	from_token_decimals: number;
	to_token_decimals: number;
	min_value: TokenValue;
	max_value: TokenValue;
	contract_address: string;
};

// add more chain, at to CHAIN_MAPPING and useExecuteRoute
const ECLIPSE_CHAIN_MAP = {
	69677601: ChainId.ECLIPSE,
};

class Owlto extends SwapAbstract<RouteOwlto> {
	provider = SwapProvider.OWLTO;
	#initTask: Promise<any>;
	#isReady: boolean = false;
	#pairs: Pair[] = [];
	async init() {
		try {
			if (this.#isReady) return;
			this.#initTask = axios.post(`${API_DOMAIN}/v1/get_all_pair_infos`);
			const { data } = await this.#initTask;
			const { pair_infos = [] } = data.data;
			this.#pairs = this._formatChains(pair_infos);
			this.#isReady = true;
		} catch (error) {
			throw new Error('RocketX is not ready');
		}
	}

	private _formatChains(pairs: Array<Pair>) {
		const isChainSupport = (chain) => CHAIN_CONFIG.has(chain) || CHAIN_CONFIG.has(ECLIPSE_CHAIN_MAP[chain]);
		return pairs.filter((e) => {
			return isChainSupport(e.from_chain_id) && isChainSupport(e.to_chain_id);
		});
	}

	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		await this.#initTask;
		if (!this.#isReady) throw new Error('Owlto X is not ready');
		const payload = getRouteParamsSwap(paramsSwap, this.#pairs, signal);
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		if (data?.code) throw new Error(data?.status?.message);
		if (!data?.txs) throw new Error('Empty route');
		return formatRoute(data, paramsSwap);
	}

	extractRoute(params: SelectedRoute<RouteOwlto>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const OwltoSwap = new Owlto();

const getAddress = (token: TokenInfo) =>
	isNativeToken(token?.address)
		? isEvmChain(token?.chainId)
			? ZERO_ADDRESS
			: isEclipseChain(token?.chainId)
			? ONE_ADDRESS
			: null
		: token.address;

const getRouteParamsSwap = (args: ArgsGetRoute, pairs: Pair[], signal?: AbortSignal): AxiosRequestConfig => {
	if (isRouteParamsEmpty(args)) return;
	const { tokenIn, tokenOut, amountIn } = args;

	const compareMappingChain = (eclipseChain, chainId) =>
		compareChain(chainId, eclipseChain) || compareChain(ECLIPSE_CHAIN_MAP[eclipseChain], chainId);

	const findPair = pairs.find(
		(pair) =>
			compareMappingChain(pair.from_chain_id, tokenIn.chainId) &&
			compareMappingChain(pair.to_chain_id, tokenOut.chainId) &&
			compareAddress(pair.from_token_address, getAddress(tokenIn)) &&
			compareAddress(pair.to_token_address, getAddress(tokenOut)),
	);

	if (!findPair) throw new Error('Not support pair');

	const params = {
		from_address: getMyWalletAddressByChain(tokenIn.chainId),
		to_address: getMyWalletAddressByChain(tokenOut.chainId),
		from_chain_name: findPair.from_chain_name,
		to_chain_name: findPair.to_chain_name,
		token_name: findPair.token_name,
		value_include_gas_fee: false,
		ui_value: formatUnits(amountIn, tokenIn?.decimals),
		'channel': 98675412,
		'source': 'owlto',
	};

	filterParams(params);
	return { url: `${API_DOMAIN}/v1/get_build_tx`, data: params, signal, method: 'POST' };
};

type Body = {
	data: string;
	from: string;
	to: string;
	value: string;
};
export type RouteOwlto = {
	token_name: string;
	from_chain_name: string;
	to_chain_name: string;
	input_value: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	send_value: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	receive_value: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	gas_fee: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	bridge_fee: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	bridge_fee_bps: number;
	min_value: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	max_value: {
		raw_value: string;
		ui_value: string;
		decimals: number;
	};
	network_type: number;
	txs: {
		approve_body: Body;
		transfer_body: Body;
	};
	raw_data: {
		transfer_data: {
			target_address: string;
			token_address: string;
			maker_address: string;
			value: string;
			netcode: number;
			channel: number;
		};
	};
};

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteOwlto>,
	{ usdPriceNative }: UsdRouteInfo,
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;

	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const gasNative = BigInt(routeSwap?.bridge_fee?.raw_value ?? 0n) + BigInt(routeSwap?.gas_fee?.raw_value ?? 0n);

	const native = getNativeToken(tokenIn?.chainId);

	return {
		amountOut: routeSwap?.receive_value?.raw_value,
		amountIn: routeSwap?.input_value?.raw_value,
		gasUsd:
			usdPriceNative && gasNative ? +ethers.formatUnits(gasNative, native?.decimals) * usdPriceNative : undefined,
		minAmountOut: routeSwap?.min_value?.raw_value,
		tokenIn,
		tokenOut,
		gasNative,
		dappInfo: {
			logo: '/icons/brands/owlto.png',
			domain: SwapProvider.OWLTO,
		},
	};
};

export const useExecuteRouteOwlto = (chainId: string) => {
	const { sentTransaction } = useSubmitEVMTransaction(chainId);
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['exe-route-owlto'],
		mutationFn: async (routeData: SelectedRoute<RouteOwlto>) => {
			const { tokenIn, route } = routeData;
			const { approve_body, transfer_body } = route.txs;
			if (isEvmChain(tokenIn.chainId)) {
				const props = {
					transactionType: TransactionType.Swap,
					metadata: routeData,
					gasLevel: 'low' as TGasFeeType,
					chainId,
				};
				if (approve_body) {
					return sentTransaction({ ...props, ...approve_body, from: MpcWallet.getEvmWalletAddress() });
				}
				return sentTransaction({
					...props,
					...transfer_body,
					from: MpcWallet.getEvmWalletAddress(),
				});
			}
			if (isEclipseChain(tokenIn.chainId)) {
				const { connection, fromPubKey } = await EclipseWallet.init('mainnet', {
					commitment: 'confirmed',
				});
				const tx = VersionedTransaction.deserialize(Buffer.from(transfer_body.data, 'hex'));
				tx.addSignature(fromPubKey, await MpcWallet.signEddsaMessage(Buffer.from(tx.message.serialize())));
				const hash = await connection.sendRawTransaction(tx.serialize());
				return { hash, nonce: null };
			}
			throw new Error('Unsupported chain: ' + tokenIn?.chainId);
		},
		onSuccess: ({ hash, nonce }, route) => {
			takeFee({ hash, route, nonce });
		},
	});
	return response;
};

import { InputMode } from '@/app-constants';
import { useState } from 'react';

const useInputMode = (modeDefault: InputMode) => {
	const [inputMode, setInputMode] = useState<InputMode>(modeDefault);
	const handleSwitch = (mode?: InputMode) => {
		setInputMode(mode || (inputMode === InputMode.AMOUNT ? InputMode.USD : InputMode.AMOUNT));
	};
	return { inputMode, handleSwitch, setInputMode };
};
export default useInputMode;

import { ChainId, TON_TCAT_TOBI_ID } from '@/app-constants/chains';
import { useDepositStore } from '@/app-features/deposit/store';
import {
	navigateToTokenDetail,
	useNavigateToSentPage,
	useNavigateToSwapPage,
	useRedirectToSwapConfirm,
} from '@/app-helpers/navigate';
import { getNativeTobiId } from '@/app-helpers/token';
import { MainRouter } from '@/app-router';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { NAVIGATE_PATHS } from '../../../app-constants/router';
import { useParseBotStartRedirectParams, useParseSwapStartParams } from '../../../app-cores/telegram/useStartAppParam';

export enum Actions {
	SWAP = 'botSw',
	SEND = 'send',
	SWAP_CONFIRM = 'swapConfirm',
	TOKEN_DETAIL = 'tokenDetail',
	REFERRAL = 'referral',
	REFERRAL_INVITE = 'referral_invite',
	REFERRAL_DETAIL = 'referral_detail',
	REQUEST_FUND = 'request_fund',
	SOL_TCAT = 'sol_tcat',
	ACTIVITIES = 'activity',
	DEPOSIT = 'deposit',
	PORTFOLIO = 'portfolio',
	FEE_EARN = 'feeEarn',
}

export function useBotRedirect() {
	// common params
	const { action, data, requestId, tobiId } = useParseBotStartRedirectParams();
	const swapPrams = useParseSwapStartParams();

	const navigateToSend = useNavigateToSentPage();
	const navigateToSwap = useNavigateToSwapPage();

	const navigateToConfirm = useRedirectToSwapConfirm();

	const { onOpen: onOpenDeposit } = useDepositStore();

	return async (replace: boolean) => {
		try {
			switch (action) {
				case Actions.SWAP: {
					const { toChain, fromChain, toToken, fromToken, amount, amountUsd, tobiId } = swapPrams;
					const payload = {
						amount,
						amountUsd,
					};

					if (fromToken && toToken) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwap(
							{
								...payload,
								tokenIn: fromToken,
								tokenOut: toToken,
								chainIn: fromChain,
								chainOut: toChain,
							},
							{ replace },
						);
					} else if (tobiId || toToken) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwap({ ...payload, chainOut: toChain, tokenOut: tobiId || toToken }, { replace });
					} else if (fromToken) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwap({ ...payload, chainIn: fromChain, tokenIn: fromToken }, { replace });
					}
					break;
				}
				case Actions.SWAP_CONFIRM: {
					const { toChain, fromChain, toToken, fromToken, amount, fromAddress, toAddress } = swapPrams;
					if ([toChain, fromChain, toToken || toAddress, fromToken || fromAddress].some((e) => !e)) return;
					navigateToConfirm(swapPrams);
					break;
				}
				case Actions.TOKEN_DETAIL: {
					if (!tobiId) return;
					navigateToTokenDetail({ tobiId }, { replace });
					break;
				}
				case Actions.FEE_EARN: {
					MainRouter.navigate(NAVIGATE_PATHS.Affiliate.Main);
					break;
				}
				case Actions.DEPOSIT: {
					onOpenDeposit();
					break;
				}
				case Actions.PORTFOLIO: {
					MainRouter.navigate(NAVIGATE_PATHS.Wallet.Wallet);
					break;
				}
				case Actions.REFERRAL: {
					MainRouter.navigate(NAVIGATE_PATHS.AccountSettings.InvitationCode);
					break;
				}
				case Actions.REFERRAL_DETAIL: {
					MainRouter.navigate(NAVIGATE_PATHS.AccountSettings.InvitationCodeDetail);
					break;
				}
				case Actions.REFERRAL_INVITE: {
					MainRouter.navigate(`${NAVIGATE_PATHS.AccountSettings.InvitationCode}?autoInvite=1`);
					break;
				}
				case Actions.REQUEST_FUND: {
					MainRouter.navigate(
						requestId
							? NAVIGATE_PATHS.RequestFund.requestDetail(requestId)
							: NAVIGATE_PATHS.RequestFund.Main,
					);
					break;
				}
				case Actions.SEND: {
					navigateToSend(data);
					break;
				}
				case Actions.SOL_TCAT: {
					navigateToSwap({
						tokenIn: getNativeTobiId(ChainId.SOL),
						tokenOut: TON_TCAT_TOBI_ID,
						chainIn: ChainId.SOL,
						chainOut: ChainId.TON,
					});
					break;
				}
				default:
					break;
			}
		} catch (error) {
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.BOT_REDIRECT,
					function: 'botRedirect',
				},
			});
		}
	};
}

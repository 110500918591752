import { ChainId } from '@/app-constants/chains';
import { compareChain } from '@/app-helpers/address';
import { joinSignature } from '@ethersproject/bytes';
import { serialize, UnsignedTransaction } from '@ethersproject/transactions';
import {
	ethers,
	getBigInt,
	TransactionRequest,
	TransactionResponse,
	TypedDataDomain,
	TypedDataField,
	VoidSigner,
} from 'ethers';
import MpcClient from '../mpc';
import { encodeHex } from '../mpc/lib';
import { getWalletAddress } from './helper';
import keyShareManager from './keyshare-manager';
import { MpcWalletProvider } from './provider';

export class MpcWalletSigner extends VoidSigner {
	#provider: MpcWalletProvider;
	_isSigner = true; // to passed ether.js check Signer.isSigner()
	constructor(provider: MpcWalletProvider) {
		super(keyShareManager.getAddress().ecdsaAddress, provider);
		this.#provider = provider;
	}

	public async signMessage(data: string): Promise<string> {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.sign(keyShare, ethers.hashMessage(data));
		return joinSignature(signatureBytes);
		// return ethers.hexlify(signatureBytes);
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public getAddress(): string {
		if (!keyShareManager.keyShares?.ecdsaKeyShare?.publicKey()) return '0x';
		return getWalletAddress(encodeHex(keyShareManager.keyShares?.ecdsaKeyShare.publicKey()));
	}

	public async getChainId(): Promise<number> {
		const network = await this.#provider.getNetwork();
		return Number(network.chainId);
	}

	public async signTransaction(transaction: ethers.TransactionRequest): Promise<string> {
		console.log('sign transaction:', transaction);
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const serializedTransaction = serialize(<UnsignedTransaction>transaction);
		console.log('🚀 ~ MpcWalletSigner ~ signTransaction ~ serializedTransaction:', serializedTransaction);
		const transactionHash = ethers.keccak256(serializedTransaction);
		console.log('computed txhash', transactionHash);
		const signatureBytes = await MpcClient.sign(keyShare, transactionHash);
		const signatureHash = ethers.hexlify(signatureBytes);
		return serialize(<UnsignedTransaction>transaction, signatureHash);
	}

	async sendTransaction(tx: TransactionRequest): Promise<TransactionResponse> {
		const chain = await this.getChainId();

		/**
		 *  this will fix for some sdk like lifi swap, ...: prevent error invalid gasLimit value,
		 *  we can not modify their sdk => we will handle it here
		 * */
		['gasLimit', 'value'].forEach((key) => {
			if (tx[key]?._isBigNumber) {
				tx[key] = getBigInt(tx[key]?._hex);
			}
		});

		if (compareChain(chain, ChainId.BNB)) {
			tx.nonce = await this.#provider.getTransactionCount(this.getAddress());
			tx.chainId = 56;
			const pop = await this.populateTransaction(tx);
			pop.chainId = 56;
			delete pop.from;
			const transactionRaw = await this.signTransaction(pop);
			return this.#provider.broadcastTransaction(transactionRaw);
		}
		return super.sendTransaction(tx);
	}

	public async signTypedData(
		domain: TypedDataDomain,
		types: Record<string, Array<TypedDataField>>,
		value: Record<string, any>,
	): Promise<string> {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.sign(keyShare, ethers.TypedDataEncoder.hash(domain, types, value));
		return joinSignature(signatureBytes);
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public connect(provider: MpcWalletProvider): MpcWalletSigner {
		return new MpcWalletSigner(provider);
	}
}

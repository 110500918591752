import { List } from '@chakra-ui/react';
import { CSSProperties, ReactNode, memo } from 'react';
import { FixedSizeList } from 'react-window';
import RWInfiniteLoader from 'react-window-infinite-loader';

type Props<T> = {
	items: T[];
	itemRender: (data: { data: T[]; index: number; style: CSSProperties }) => ReactNode;
	onFetchMore?: (startIndex: number, stopIndex: number) => Promise<T[]>;
	itemKey?: (item: T) => string;
	itemSize?: number; // height of each item
	height: number; // height of container list;
};
export const InfiniteLoader = memo(
	<T,>({ items, itemRender, onFetchMore, itemKey, itemSize = 30, height }: Props<T>) => {
		const isItemLoaded = (index) => true;

		return (
			<List>
				<RWInfiniteLoader isItemLoaded={isItemLoaded} itemCount={1000} loadMoreItems={onFetchMore}>
					{({ onItemsRendered, ref }) => (
						<FixedSizeList
							height={height}
							itemCount={items.length}
							itemSize={itemSize}
							onItemsRendered={onItemsRendered}
							ref={ref}
							width="100%"
							itemData={items}
							itemKey={itemKey ? (item, data) => itemKey(data[item]) : undefined}
						>
							{itemRender}
						</FixedSizeList>
					)}
				</RWInfiniteLoader>
			</List>
		);
	},
);

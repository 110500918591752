import { useCallback, useMemo, useState } from 'react';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash-es';

import { ChainType, NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { getShortAddress } from '@/app-helpers/address';
import { ChainIcons } from '../ChainIcons';
import { CopyToClipboard } from '../CopyToClipboard';
import { getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { Toast } from '../Toast';
import { FiQRCodeIcon, FiRightIcon } from '@/assets/icons';
import {
	ChainId,
	NATIVE_TOKEN_ADDRESS,
	NON_EVM_SVM_CHAINS,
	ethereum,
	solana,
} from '@/app-constants/chains';
import { QrCodeAddress } from '../QrCodePopup';
import { CardSelection } from '../CardSelection';
import useChainList from '@/app-hooks/wallet/useChainList';


interface AddressesProps {
	onClickQrCode?: (chain: NetWorkChain) => void;
	showCopyAndQRCode?: boolean;
	onItemClick?: (chain: NetWorkChain) => void;
	visibleChain?: ChainId;
}
export const Addresses: React.FC<AddressesProps> = ({
	onClickQrCode,
	showCopyAndQRCode = true,
	onItemClick,
	visibleChain,
}) => {
	const { t } = useTranslation();
	const [address, setAddress] = useState('');
	const [networkChain, setNetworkChain] = useState<NetWorkChain | null>(null);
    const {chainsSupport} = useChainList();
	const renderWalletItem = useCallback((chain: NetWorkChain, vm?: 'evm' | 'svm') => {
        const svmIds = chainsSupport.reduce((acc, chain) => {
            if (chain.type === ChainType.ECLIPSE || chain.type === ChainType.SOLANA) {
                acc.push(chain.id);
            }
            return acc;
        }, [])

        const evmIds = chainsSupport.reduce((acc, chain) => {
            if (chain.type === ChainType.EVM) {
                acc.push(chain.id);
            }
            return acc;
        }, [])
		
       
		return (
			<CardSelection
				key={chain.id}
				icon={<Image src={chain.logo} width="40px" height="40px" alt="" />}
				onClick={() => onItemClick?.(chain)}
				cursor={onItemClick ? 'pointer' : 'auto'}
				leftContent={
					<Box>
						<Text fontSize="sm" fontWeight={600}>
							{chain.name}
						</Text>
						<Text fontSize="xs" color="gray.400" mb={1}>
							{getShortAddress(getMyWalletAddressByChain(chain.id))}
						</Text>
						{vm === 'evm' && <ChainIcons chains={evmIds} translatePlacement="left" />}
						{vm === 'svm' && svmIds.length > 1 && <ChainIcons chains={svmIds} translatePlacement="left" />}
					</Box>
				}
				rightContent={
					showCopyAndQRCode ? (
						<Center gap={3}>
							<CopyToClipboard
								iconSize={24}
								text={getMyWalletAddressByChain(chain.id)}
								callback={() => {
									dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_COPY_CHAIN_ADDRESS);
									toast(
										<Toast
											type="success"
											title="Success"
											message={t('deposit.copyWalletAddressSuccess', {
												network: chain.name,
											})}
										/>,
									);
								}}
							></CopyToClipboard>
							<Box
								cursor="pointer"
								onClick={() => {
									onClickQrCode?.(chain);
									setAddress(getMyWalletAddressByChain(chain.id));
									setNetworkChain(chain);
									dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_SCAN_CHAIN_QR_CODE);
								}}
							>
								<FiQRCodeIcon />
							</Box>
						</Center>
					) : (
						<Box>
							<FiRightIcon width={20} height={20} />
						</Box>
					)
				}
			/>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

   const enableChain = useMemo(()=> {
    const svmEvmChainSupported = chainsSupport.filter(c => c.type === ChainType.SOLANA || c.type === ChainType.ECLIPSE);
    const evmChainsSupported = chainsSupport.filter(c => c.type === ChainType.EVM);
	const nonEvmSvmChains = visibleChain ? NON_EVM_SVM_CHAINS.filter((c) => c.id === visibleChain) : NON_EVM_SVM_CHAINS;
	const svmEvmChains = visibleChain ? svmEvmChainSupported.filter((c) => c.id === visibleChain) : svmEvmChainSupported;
	const evmEvmChains = visibleChain ? evmChainsSupported.filter((c) => +c.id === +visibleChain) : evmChainsSupported;
    return {nonEvmSvmChains, svmEvmChains, evmEvmChains}
   }, [chainsSupport, visibleChain])

	return (
		<>
			{enableChain.nonEvmSvmChains.map((chain) => {
				return renderWalletItem(chain);
			})}
			{!isEmpty(enableChain.svmEvmChains) && <Box>{renderWalletItem(solana, 'svm')}</Box>}
			{!isEmpty(enableChain.evmEvmChains) && <Box>{renderWalletItem(ethereum, 'evm')}</Box>}
			{!!address && (
				<QrCodeAddress
					onClose={() => {
						setAddress('');
						setNetworkChain(null);
					}}
					isOpen={!!address}
					address={address}
					token={{
						address: NATIVE_TOKEN_ADDRESS,
						chainId: networkChain?.id as any,
						logo: networkChain?.logo,
						symbol: networkChain?.nativeToken?.symbol,
					}}
				/>
			)}
		</>
	);
};

import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { suiWallet } from '@/app-cores/mpc-wallet/sui';
import { MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { isEvmChain, isSuiChain } from '@/app-helpers/token';
import { getSignerContract } from '@/app-helpers/web3';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import { Address } from '@ton/core';
import { Interface, ethers } from 'ethers';
import TonWeb from 'tonweb';
import { CHAIN_CONFIG, ChainId } from '../../app-constants/chains';
import { TokenInfo } from '../../app-cores/api/bff';
import { TonWallet } from '../../app-cores/mpc-wallet/ton/TonWallet';
import { isNativeToken } from '../../app-helpers/address';
import { erc20Abi, getErc20Contract } from './useERC20Contract';
const tonweb = new TonWeb();

const getTonBalance = async (walletAddress: string, tokenAddress: string) => {
	try {
		if (isNativeToken(tokenAddress)) {
			return await tonweb.getBalance(walletAddress);
		}

		const tonSpaceAddress = Address.parse(walletAddress);
		const jettonWalletContract = await TonWallet.getTonSpaceJettonWalletAddress(tonSpaceAddress, tokenAddress);
		const jettonWallet = new TonWeb.token.jetton.JettonWallet(tonweb.provider, {
			address: jettonWalletContract.toRawString(),
		});
		const data = await jettonWallet.getData();

		return data.balance.toString();
	} catch (error) {
		return '0';
	}
};

export const getSolBalance = async (walletAddress: string, tokenAddress: string) => {
	try {
		const { connection, fromPubKey } = await SolWallet.init('mainnet-beta', {
			commitment: 'confirmed',
		});
		if (isNativeToken(tokenAddress)) {
			const balance = await connection.getBalance(new PublicKey(walletAddress));
			return balance;
		}
		const tokenMint = new PublicKey(tokenAddress);
		const fromTokenAccount = await getAssociatedTokenAddress(tokenMint, fromPubKey);
		const tokenBalance = await connection.getTokenAccountBalance(fromTokenAccount);
		return tokenBalance.value.amount;
	} catch (error) {
		console.error('get sol balance error', error);
		return '0';
	}
};

export const getEvmBalance = async (
	walletAddress: string,
	chainId: string | ChainId,
	tokenAddress: string,
): Promise<bigint> => {
	try {
		const networkChain = CHAIN_CONFIG[chainId];
		const provider = new MpcWalletProvider(networkChain.rpcUrls.default.http);
		const blockNumber = await provider.getBlockNumber();
		if (isNativeToken(tokenAddress)) {
			const ethBalance = await provider.getBalance(walletAddress, blockNumber);
			return ethBalance;
		}
		const erc20Contract = getErc20Contract(chainId, tokenAddress);
		const tokenBalance = await erc20Contract.balanceOf(walletAddress, { blockTag: blockNumber });
		return tokenBalance;
	} catch (error) {
		console.error('get erc20 balance error', error);
		return 0n;
	}
};
export const getBalanceOnChain = async (walletAddress: string, token: TokenInfo) => {
	switch (token.chainId) {
		case ChainId.TON:
			return getTonBalance(walletAddress, token.address);
		case ChainId.SOL:
			return getSolBalance(walletAddress, token.address);
		default:
			return getEvmBalance(walletAddress, token.chainId, token.address);
	}
};

export const useWalletTokenBalance = (walletAddress: string, token: TokenInfo) => {
	return useQuery({
		queryKey: ['useWalletTokenBalance', walletAddress, token?.address, token?.chainId],
		queryFn: async () => {
			try {
				const rawBalance = await getBalanceOnChain(walletAddress, token);
				return {
					balance: rawBalance.toString(),
					balanceFormatted: +ethers.formatUnits(rawBalance, token.decimals) || 0,
				};
			} catch (error) {
				return { balanceFormatted: 0, balance: '0' };
			}
		},
		enabled: !!walletAddress && !!token,
	});
};

// ABIs
const MULTICALL_ABI = [
	'function aggregate(tuple(address target, bytes callData)[] calls) public view returns (uint256 blockNumber, bytes[] returnData)',
];

type TokenOnChain = { address: string; symbol: string; name: string; decimals: number; chainId: ChainId };

const getOnChainEvmTokenData = async (chainId: ChainId, tokenAddresses: string[]) => {
	try {
		const multicall = CHAIN_CONFIG[chainId]?.multicall;
		if (!multicall) {
			const getInfo = async (address: string) => {
				const tokenContract = getErc20Contract(chainId, address);
				const [name, symbol, decimals] = await Promise.all([
					tokenContract.name(),
					tokenContract.symbol(),
					tokenContract.decimals(),
				]);
				return {
					address,
					symbol: symbol,
					name: name,
					decimals: Number(decimals),
					chainId,
				};
			};
			return Promise.all(tokenAddresses.map(getInfo));
		}

		const { contract: multicallContract } = await getSignerContract(multicall, chainId, MULTICALL_ABI);

		// Prepare calls
		const calls = [];
		const iface = new Interface(erc20Abi);

		for (const token of tokenAddresses) {
			calls.push(
				{ target: token, callData: iface.encodeFunctionData('symbol') },
				{ target: token, callData: iface.encodeFunctionData('name') },
				{ target: token, callData: iface.encodeFunctionData('decimals') },
			);
		}

		// Aggregate calls
		const [, returnData] = await multicallContract.aggregate(calls);

		// Decode results
		const results: TokenOnChain[] = [];
		for (let i = 0; i < tokenAddresses.length; i++) {
			const symbol = iface.decodeFunctionResult('symbol', returnData[i * 3]);
			const name = iface.decodeFunctionResult('name', returnData[i * 3 + 1]);
			const decimals = iface.decodeFunctionResult('decimals', returnData[i * 3 + 2]);

			results.push({
				address: tokenAddresses[i],
				symbol: symbol[0],
				name: name[0],
				decimals: Number(decimals[0]),
				chainId,
			});
		}

		return results;
	} catch (error) {
		return [];
	}
};

async function getOnChainSuiTokenData(tokenAddress: string[]): Promise<TokenOnChain[]> {
	try {
		const data = await Promise.allSettled(
			tokenAddress.map(async (e) => {
				const data = await suiWallet.client.getCoinMetadata({ coinType: e });
				const { decimals, name, symbol } = data;
				return { symbol, decimals, name, chainId: ChainId.SUI, address: e };
			}),
		);
		const formatData = data.map((e) => (e.status === 'fulfilled' ? e.value : null)).filter(Boolean);
		return formatData;
	} catch (error) {}
	return [];
}

export const getOnChainTokenData = async (chainId: ChainId, tokenAddresses: string[]) => {
	try {
		if (isEvmChain(chainId)) return getOnChainEvmTokenData(chainId, tokenAddresses);
		if (isSuiChain(chainId)) return getOnChainSuiTokenData(tokenAddresses);
	} catch (error) {}
	return [];
};

import { ChainType, NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { EIP155Chain } from '@/app-features/app-wallet-connect/data/EIP155Data';

export const NATIVE_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const TON_NATIVE_ADDRESS = 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c';
export const TRON_NATIVE_ADDRESS = 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb';

export const SUI_NATIVE_ADDRESS = '0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI';

export const TON_TCAT_ADDRESS = 'EQBtDmYgCKk3ecQ1x-j0CLZRnPYyaV7cB33ad036QN-HE2C7';
export const TON_TCAT_TOBI_ID = 'cgk-ton-cat';

export const TON_TONKE_ADDRESS = 'EQCQlDd6eyUDlWP2NIUn2ZyGZ8ktg_HKXB288jv4Kp1Yflo6';

export const WRAP_BERA_TEST_NET_ADDRESS = '0x7507c1dc16935B82698e4C63f2746A2fCf994dF8';

export const ONE_ADDRESS = '11111111111111111111111111111111';
export const WRAP_SOL_ADDRESS = 'So11111111111111111111111111111111111111112';

export const ECLIPSE_NATIVE_ADDRESS = 'So11111111111111111111111111111111111111112';

export const VIRTUAL_TOKEN_ADDRESS = '0x0b3e328455c4059eeb9e3f84b5543f74e24e7e1b';
export const VIRTUAL_TOBI_ID = 'cgk-virtual-protocol';
export const VIRTUAL_TOKEN_DECIMALS = 18;

export enum TobiChainName {
	// support swap/send
	ETH = 'ETH',
	AVAX = 'AVAX',
	POL = 'POL',
	ARB = 'ARB',
	BSC = 'BSC',
	OP = 'OP',
	SOL = 'SOL',
	TON = 'TON',
	BASE = 'BASE',
	BERA = 'BERA',
	TRON = 'TRON',
	SUI = 'SUI',
	MNT = 'MANTLE',
	ECLIPSE = 'ECLIPSE',
	STORY_TESTNET = 'STORY_TESTNET',

	// not support
	BTC = 'BTC',
	APT = 'APT',
	MANTA = 'MANTA',
	METIS = 'METIS',
	BLAST = 'BLAST',
	LINEA = 'LINEA',
	SCROLL = 'SCROLL',
	STORY = 'STORY',

	NOT_SUPPORT = '',
}

export enum ChainId {
	ETH = 1,
	ARB = 42161,
	BASE = 8453,
	POLYGON = 137,
	OP = 10,
	AVAX = 43114,
	BNB = 56,
	MNT = 5000,

	TON = 'ton-base',
	SOL = 'solana',
	EVM = 'evm',
	TRON = 'tron',
	SUI = 'sui',
	ECLIPSE = 'eclipse',

	// testnet
	AVAX_TESTNET = 43113,
	POLYGON_TESTNET = 80001,
	OP_TESTNET = 420,
	HOLESKY_TESTNET = 17000,
	BASESEPOLIA_TESTNET = 84532,
	SEPOLIA_TESTNET = 11155111,
	BERACHAIN_TESTNET = 80084,
	STORY_TESTNET = 1516,
}

const env = import.meta.env.VITE_ENVIRONMENT;

const logoPath = `/icons/chains`;

export const RPC = {
	dev: {
		[ChainId.ETH]: {
			https: 'https://empty-light-lake.quiknode.pro/d7fa03c652a815c3ab656e7df110db19554e3025',
			wss: 'wss://empty-light-lake.quiknode.pro/d7fa03c652a815c3ab656e7df110db19554e3025',
		},
		[ChainId.ARB]: {
			https: 'https://hardworking-cool-sound.arbitrum-mainnet.quiknode.pro/e86f9206581f119ca99955fd0deee7da28434914',
			wss: 'wss://hardworking-cool-sound.arbitrum-mainnet.quiknode.pro/e86f9206581f119ca99955fd0deee7da28434914',
		},
		[ChainId.BASE]: {
			https: 'https://stylish-bold-shape.base-mainnet.quiknode.pro/95cd19dd7f96d131ef87cac6dec0438f76f32329',
			wss: 'wss://stylish-bold-shape.base-mainnet.quiknode.pro/95cd19dd7f96d131ef87cac6dec0438f76f32329',
		},
		[ChainId.POLYGON]: {
			https: 'https://hardworking-necessary-choice.matic.quiknode.pro/0c2f79fbf016fdf02749f40babc7198a1c7395a4',
			wss: 'wss://hardworking-necessary-choice.matic.quiknode.pro/0c2f79fbf016fdf02749f40babc7198a1c7395a4',
		},
		[ChainId.OP]: {
			https: 'https://white-purple-forest.optimism.quiknode.pro/d845270de31396c974ca8780705857bbdd367676',
			wss: 'wss://white-purple-forest.optimism.quiknode.pro/d845270de31396c974ca8780705857bbdd367676',
		},
		[ChainId.AVAX]: {
			https: 'https://neat-methodical-night.avalanche-mainnet.quiknode.pro/7f5d01d88c1cad57aaf5e80263679093f559624d/ext/bc/C/rpc',
			wss: 'wss://neat-methodical-night.avalanche-mainnet.quiknode.pro/7f5d01d88c1cad57aaf5e80263679093f559624d/ext/bc/C/rpc',
		},

		[ChainId.BNB]: {
			https: 'https://frequent-burned-crater.bsc.quiknode.pro/d8ab064a62d220808d4052cecdaa2b6f34db53f7',
			wss: 'wss://frequent-burned-crater.bsc.quiknode.pro/d8ab064a62d220808d4052cecdaa2b6f34db53f7',
		},
		[ChainId.SOL]: {
			https: 'https://broken-late-panorama.solana-mainnet.quiknode.pro/71a6fb542d7d3e0ae842f5804546b1ddeb0cbb70',
			wss: 'wss://broken-late-panorama.solana-mainnet.quiknode.pro/71a6fb542d7d3e0ae842f5804546b1ddeb0cbb70',
		},
		[ChainId.MNT]: {
			https: 'https://frequent-boldest-bird.mantle-mainnet.quiknode.pro/c49eea9a26cc48405c764f6705e5975410feda08',
			wss: 'wss://frequent-boldest-bird.mantle-mainnet.quiknode.pro/c49eea9a26cc48405c764f6705e5975410feda08',
		},
		[ChainId.ECLIPSE]: {
			https: 'https://mainnetbeta-rpc.eclipse.xyz',
			wss: 'wss://mainnetbeta-rpc.eclipse.xyz',
		},
		[ChainId.STORY_TESTNET]: {
			https: 'https://rpc.odyssey.storyrpc.io',
			wss: 'wss://rpc.odyssey.storyrpc.io',
		},
	},
	stg: {
		[ChainId.ETH]: {
			https: 'https://empty-light-lake.quiknode.pro/d7fa03c652a815c3ab656e7df110db19554e3025',
			wss: 'wss://empty-light-lake.quiknode.pro/d7fa03c652a815c3ab656e7df110db19554e3025',
		},
		[ChainId.ARB]: {
			https: 'https://hardworking-cool-sound.arbitrum-mainnet.quiknode.pro/e86f9206581f119ca99955fd0deee7da28434914',
			wss: 'wss://hardworking-cool-sound.arbitrum-mainnet.quiknode.pro/e86f9206581f119ca99955fd0deee7da28434914',
		},
		[ChainId.BASE]: {
			https: 'https://stylish-bold-shape.base-mainnet.quiknode.pro/95cd19dd7f96d131ef87cac6dec0438f76f32329',
			wss: 'wss://stylish-bold-shape.base-mainnet.quiknode.pro/95cd19dd7f96d131ef87cac6dec0438f76f32329',
		},
		[ChainId.POLYGON]: {
			https: 'https://hardworking-necessary-choice.matic.quiknode.pro/0c2f79fbf016fdf02749f40babc7198a1c7395a4',
			wss: 'wss://hardworking-necessary-choice.matic.quiknode.pro/0c2f79fbf016fdf02749f40babc7198a1c7395a4',
		},
		[ChainId.OP]: {
			https: 'https://white-purple-forest.optimism.quiknode.pro/d845270de31396c974ca8780705857bbdd367676',
			wss: 'wss://white-purple-forest.optimism.quiknode.pro/d845270de31396c974ca8780705857bbdd367676',
		},
		[ChainId.AVAX]: {
			https: 'https://neat-methodical-night.avalanche-mainnet.quiknode.pro/7f5d01d88c1cad57aaf5e80263679093f559624d/ext/bc/C/rpc',
			wss: 'wss://neat-methodical-night.avalanche-mainnet.quiknode.pro/7f5d01d88c1cad57aaf5e80263679093f559624d/ext/bc/C/rpc',
		},

		[ChainId.BNB]: {
			https: 'https://frequent-burned-crater.bsc.quiknode.pro/d8ab064a62d220808d4052cecdaa2b6f34db53f7',
			wss: 'wss://frequent-burned-crater.bsc.quiknode.pro/d8ab064a62d220808d4052cecdaa2b6f34db53f7',
		},
		[ChainId.SOL]: {
			https: 'https://broken-late-panorama.solana-mainnet.quiknode.pro/71a6fb542d7d3e0ae842f5804546b1ddeb0cbb70',
			wss: 'wss://broken-late-panorama.solana-mainnet.quiknode.pro/71a6fb542d7d3e0ae842f5804546b1ddeb0cbb70',
		},
		[ChainId.MNT]: {
			https: 'https://frequent-boldest-bird.mantle-mainnet.quiknode.pro/c49eea9a26cc48405c764f6705e5975410feda08',
			wss: 'wss://frequent-boldest-bird.mantle-mainnet.quiknode.pro/c49eea9a26cc48405c764f6705e5975410feda08',
		},
		[ChainId.ECLIPSE]: {
			https: 'https://mainnetbeta-rpc.eclipse.xyz',
			wss: 'wss://mainnetbeta-rpc.eclipse.xyz',
		},
		[ChainId.STORY_TESTNET]: {
			https: 'https://rpc.odyssey.storyrpc.io',
			wss: 'wss://rpc.odyssey.storyrpc.io',
		},
	},
	prod: {
		[ChainId.ETH]: {
			https: 'https://empty-light-lake.quiknode.pro/d7fa03c652a815c3ab656e7df110db19554e3025',
			wss: 'wss://empty-light-lake.quiknode.pro/d7fa03c652a815c3ab656e7df110db19554e3025',
		},
		[ChainId.ARB]: {
			https: 'https://hardworking-cool-sound.arbitrum-mainnet.quiknode.pro/e86f9206581f119ca99955fd0deee7da28434914',
			wss: 'wss://hardworking-cool-sound.arbitrum-mainnet.quiknode.pro/e86f9206581f119ca99955fd0deee7da28434914',
		},
		[ChainId.BASE]: {
			https: 'https://stylish-bold-shape.base-mainnet.quiknode.pro/95cd19dd7f96d131ef87cac6dec0438f76f32329',
			wss: 'wss://stylish-bold-shape.base-mainnet.quiknode.pro/95cd19dd7f96d131ef87cac6dec0438f76f32329',
		},
		[ChainId.POLYGON]: {
			https: 'https://hardworking-necessary-choice.matic.quiknode.pro/0c2f79fbf016fdf02749f40babc7198a1c7395a4',
			wss: 'wss://hardworking-necessary-choice.matic.quiknode.pro/0c2f79fbf016fdf02749f40babc7198a1c7395a4',
		},
		[ChainId.OP]: {
			https: 'https://white-purple-forest.optimism.quiknode.pro/d845270de31396c974ca8780705857bbdd367676',
			wss: 'wss://white-purple-forest.optimism.quiknode.pro/d845270de31396c974ca8780705857bbdd367676',
		},
		[ChainId.AVAX]: {
			https: 'https://neat-methodical-night.avalanche-mainnet.quiknode.pro/7f5d01d88c1cad57aaf5e80263679093f559624d/ext/bc/C/rpc',
			wss: 'wss://neat-methodical-night.avalanche-mainnet.quiknode.pro/7f5d01d88c1cad57aaf5e80263679093f559624d/ext/bc/C/rpc',
		},

		[ChainId.BNB]: {
			https: 'https://frequent-burned-crater.bsc.quiknode.pro/d8ab064a62d220808d4052cecdaa2b6f34db53f7',
			wss: 'wss://frequent-burned-crater.bsc.quiknode.pro/d8ab064a62d220808d4052cecdaa2b6f34db53f7',
		},
		[ChainId.SOL]: {
			https: 'https://broken-late-panorama.solana-mainnet.quiknode.pro/71a6fb542d7d3e0ae842f5804546b1ddeb0cbb70',
			wss: 'wss://broken-late-panorama.solana-mainnet.quiknode.pro/71a6fb542d7d3e0ae842f5804546b1ddeb0cbb70',
		},
		[ChainId.MNT]: {
			https: 'https://frequent-boldest-bird.mantle-mainnet.quiknode.pro/c49eea9a26cc48405c764f6705e5975410feda08',
			wss: 'wss://frequent-boldest-bird.mantle-mainnet.quiknode.pro/c49eea9a26cc48405c764f6705e5975410feda08',
		},
		[ChainId.ECLIPSE]: {
			https: 'https://mainnetbeta-rpc.eclipse.xyz',
			wss: 'wss://mainnetbeta-rpc.eclipse.xyz',
		},
		[ChainId.STORY_TESTNET]: {
			https: 'https://rpc.odyssey.storyrpc.io',
			wss: 'wss://rpc.odyssey.storyrpc.io',
		},
	},
};

export const SOLANA_URL = 'https://adrian-lcnu83-fast-mainnet.helius-rpc.com/';

export const SWAP_SOLANA_RPC = {
	http: SOLANA_URL,
	wss: SOLANA_URL,
};

export const randomSolanaRpc = () => {
	// return SOLANA_URL;
	const arr = [SOLANA_URL, 'https://solana-rpc.debridge.finance'];
	const rpc = arr[Math.floor(Math.random() * arr.length)];
	return rpc;
};

const holesky: NetWorkChain = {
	id: ChainId.HOLESKY_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'Holesky',
	kyberAggregatorSlug: '',
	logo: '',
	transakNetworkName: '',
	nativeToken: {
		tobiId: '',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.HOLESKY_TESTNET,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: 'https://ethereum-holesky.publicnode.com',
			wss: 'wss://ethereum-holesky.publicnode.com',
		},
	},
	explorerUrl: 'https://holesky.etherscan.io',
	testnet: true,
	minForGas: 10 ** 16,
};

export const baseSepolia: NetWorkChain = {
	id: ChainId.BASESEPOLIA_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'BaseSepolia',
	kyberAggregatorSlug: '',
	logo: `${logoPath}/ethereum.svg`,
	transakNetworkName: '',
	nativeToken: {
		tobiId: '',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.BASESEPOLIA_TESTNET,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: 'https://indulgent-stylish-voice.base-sepolia.quiknode.pro/453a6bb3c4ba6b88553075ce7df5ba748d8dab7d',
			wss: 'wss://indulgent-stylish-voice.base-sepolia.quiknode.pro/453a6bb3c4ba6b88553075ce7df5ba748d8dab7d',
		},
	},
	explorerUrl: 'https://sepolia.basescan.org',
	testnet: true,
	minForGas: 10 ** 16,
};

const sepolia: NetWorkChain = {
	id: ChainId.SEPOLIA_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'Sepolia',
	kyberAggregatorSlug: '',
	logo: '',
	transakNetworkName: '',
	nativeToken: {
		tobiId: '',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.SEPOLIA_TESTNET,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: 'https://gateway.tenderly.co/public/sepolia',
			wss: 'wss://gateway.tenderly.co/public/sepolia',
		},
	},
	explorerUrl: 'https://sepolia.etherscan.io',
	testnet: true,
	smartAccountEnabled: false,
	isEIP1559: true,
	minForGas: 10 ** 16,
};

const avaxTestnet: NetWorkChain = {
	id: ChainId.AVAX_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'Avalanche Fuji',
	kyberAggregatorSlug: '',
	logo: `${logoPath}/avax.png`,
	transakNetworkName: '',
	nativeToken: {
		tobiId: '',
		symbol: 'AVAX',
		decimals: 18,
		name: 'Avax',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.AVAX_TESTNET,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: 'https://api.avax-test.network/ext/bc/C/rpc',
			wss: 'wss://api.avax-test.network/ext/bc/C/rpc',
		},
	},
	explorerUrl: 'https://testnet.snowtrace.io/',
	testnet: true,
	minForGas: 10 ** 16,
};

const polygonTestnet: NetWorkChain = {
	id: ChainId.POLYGON_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'Polygon Mumbai',
	kyberAggregatorSlug: '',
	logo: `${logoPath}/polygon.svg`,
	transakNetworkName: '',
	nativeToken: {
		tobiId: '',
		symbol: 'MATIC',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.POLYGON_TESTNET,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: 'https://matic-mumbai.chainstacklabs.com',
			wss: 'wss://matic-mumbai.chainstacklabs.com',
		},
	},
	explorerUrl: 'https://mumbai.polygonscan.com',
	testnet: true,
	smartAccountEnabled: false,
	minForGas: 10 ** 17,
};

const opTestnet: NetWorkChain = {
	id: ChainId.OP_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'Optimism Goerli',
	kyberAggregatorSlug: '',
	logo: `${logoPath}/ethereum.svg`,
	transakNetworkName: '',
	nativeToken: {
		tobiId: '',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.OP_TESTNET,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: 'https://goerli.optimism.io',
			wss: 'wss://goerli.optimism.io',
		},
	},
	explorerUrl: 'https://goerli-optimism.etherscan.io',
	testnet: true,
	minForGas: 10 ** 16,
};

export const berachainTesnet: NetWorkChain = {
	id: ChainId.BERACHAIN_TESTNET,
	tobiId: TobiChainName.NOT_SUPPORT,
	type: ChainType.EVM,
	name: 'Berachain',
	kyberAggregatorSlug: '',
	logo: `${logoPath}/bera.png`,
	transakNetworkName: '',
	nativeToken: {
		tobiId: 'cgk-bera',
		symbol: 'BERA',
		decimals: 18,
		name: 'Berachain',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.BERACHAIN_TESTNET,
	},
	wrapNativeToken: { address: WRAP_BERA_TEST_NET_ADDRESS },
	rpcUrls: {
		default: {
			http: 'https://bartio.rpc.berachain.com',
			wss: 'wss://bartio.rpc.berachain.com',
		},
	},
	explorerUrl: 'https://bartio.beratrail.io',
	testnet: true,
	minForGas: 31500000000000,
};

export const storyTestnet: NetWorkChain = {
	kyberAggregatorSlug: '',
	tobiId: TobiChainName.STORY_TESTNET,
	id: ChainId.STORY_TESTNET,
	type: ChainType.EVM,
	name: 'Story',
	logo: `${logoPath}/story.svg`,
	explorerUrl: 'https://www.okx.com/web3/explorer/story-odyssey',
	testnet: true,
	nativeToken: {
		tobiId: 'cgk-ip',
		symbol: 'IP',
		decimals: 18,
		name: 'IP',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.STORY_TESTNET,
	},
	wrapNativeToken: { address: '0x1516000000000000000000000000000000000000' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.STORY_TESTNET].https,
			wss: RPC[env][ChainId.STORY_TESTNET].wss,
		},
	},
	transakNetworkName: 'story',
	isEIP1559: true,
	minForGas: 10 ** 17,
};

export const ethereum: NetWorkChain = {
	kyberAggregatorSlug: 'ethereum',
	tobiId: TobiChainName.ETH,
	id: ChainId.ETH,
	type: ChainType.EVM,
	name: 'Ethereum',
	logo: `${logoPath}/ethereum.svg`,
	explorerUrl: 'https://etherscan.io',
	nativeToken: {
		tobiId: 'cgk-ethereum',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.ETH,
	},
	wrapNativeToken: { address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2' },
	testnet: false,
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.ETH].https,
			wss: RPC[env][ChainId.ETH].wss,
		},
	},
	transakNetworkName: 'ethereum',
	isEIP1559: true,
	minForGas: 10 ** 16,
	blowFishNetworkName: 'ethereum',
	multicall: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
};

const arbitrum: NetWorkChain = {
	kyberAggregatorSlug: 'arbitrum',
	tobiId: TobiChainName.ARB,
	id: ChainId.ARB,
	type: ChainType.EVM,
	name: 'Arbitrum',
	logo: `${logoPath}/arb.svg`,
	explorerUrl: 'https://arbiscan.io',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-ethereum',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.ARB,
	},
	wrapNativeToken: { address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.ARB].https,
			wss: RPC[env][ChainId.ARB].wss,
		},
	},
	transakNetworkName: 'arbitrum',
	isEIP1559: true,
	minForGas: 10 ** 16,
	blowFishNetworkName: 'arbitrum',
	multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
};

const base: NetWorkChain = {
	kyberAggregatorSlug: 'base',
	tobiId: TobiChainName.BASE,
	id: ChainId.BASE,
	type: ChainType.EVM,
	name: 'Base',
	logo: `${logoPath}/base.svg`,
	explorerUrl: 'https://basescan.org',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-ethereum',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.BASE,
	},
	wrapNativeToken: { address: '0x4200000000000000000000000000000000000006' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.BASE].https,
			wss: RPC[env][ChainId.BASE].wss,
		},
	},
	transakNetworkName: 'base',
	isEIP1559: true,
	minForGas: 0.00005 * 10 ** 18,
	blowFishNetworkName: 'base',
	multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
};

const polygon: NetWorkChain = {
	kyberAggregatorSlug: 'polygon',
	tobiId: TobiChainName.POL,
	id: ChainId.POLYGON,
	type: ChainType.EVM,
	name: 'Polygon',
	logo: `${logoPath}/polygon.svg`,
	explorerUrl: 'https://polygonscan.com',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-matic-network',
		symbol: 'MATIC',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.POLYGON,
	},
	wrapNativeToken: { address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.POLYGON].https,
			wss: RPC[env][ChainId.POLYGON].wss,
		},
	},
	transakNetworkName: 'polygon',
	isEIP1559: true,
	minForGas: 10 ** 17,
	blowFishNetworkName: 'polygon',
	multicall: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
};

const op: NetWorkChain = {
	kyberAggregatorSlug: 'optimism',
	tobiId: TobiChainName.OP,
	id: ChainId.OP,
	type: ChainType.EVM,
	name: 'Optimism',
	logo: `${logoPath}/op.svg`,
	explorerUrl: 'https://optimistic.etherscan.io',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-ethereum',
		symbol: 'ETH',
		decimals: 18,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.OP,
	},
	wrapNativeToken: { address: '0x4200000000000000000000000000000000000006' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.OP].https,
			wss: RPC[env][ChainId.OP].wss,
		},
	},
	transakNetworkName: 'optimism',
	isEIP1559: true,
	minForGas: 10 ** 16,
	blowFishNetworkName: 'optimism',
	multicall: '0x2DC0E2aa608532Da689e89e237dF582B783E552C',
};

const avax: NetWorkChain = {
	kyberAggregatorSlug: 'avalanche',
	tobiId: TobiChainName.AVAX,
	id: ChainId.AVAX,
	type: ChainType.EVM,
	name: 'Avalanche',
	logo: `${logoPath}/avax.svg`,
	explorerUrl: 'https://snowtrace.io',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-avalanche-2',
		symbol: 'AVAX',
		decimals: 18,
		name: 'Avax',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.AVAX,
	},
	wrapNativeToken: { address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.AVAX].https,
			wss: RPC[env][ChainId.AVAX].wss,
		},
	},
	transakNetworkName: 'avaxcchain',
	isEIP1559: true,
	minForGas: 10 ** 16,
	blowFishNetworkName: 'avalanche',
	multicall: '0x0A77230d17318075983913bC2145DB16C7366156',
};

const bnb: NetWorkChain = {
	kyberAggregatorSlug: 'bsc',
	tobiId: TobiChainName.BSC,
	id: ChainId.BNB,
	type: ChainType.EVM,
	name: 'BNB',
	logo: `${logoPath}/bnb.svg`,
	explorerUrl: 'https://bscscan.com',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-binancecoin',
		symbol: 'BNB',
		decimals: 18,
		name: 'BNB',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.BNB,
	},
	wrapNativeToken: { address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.BNB].https,
			wss: RPC[env][ChainId.BNB].wss,
		},
	},
	transakNetworkName: 'bsc',
	minForGas: 10 ** 16,
	blowFishNetworkName: 'bnb',
	multicall: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
};

export const ton: NetWorkChain = {
	kyberAggregatorSlug: '',
	tobiId: TobiChainName.TON,
	id: ChainId.TON,
	type: ChainType.TON,
	name: 'TON',
	logo: `${logoPath}/ton.svg`,
	explorerUrl: 'https://tonviewer.com',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-the-open-network',
		symbol: 'TON',
		decimals: 9,
		name: 'Toncoin',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.TON,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: '',
			wss: '',
		},
	},
	minForGas: 0.01 * 10 ** 9,
	transakNetworkName: 'ton',
};

export const solana: NetWorkChain = {
	kyberAggregatorSlug: '',
	tobiId: TobiChainName.SOL,
	stableCoinTobiId: 'cgk-usd-coin',
	id: ChainId.SOL,
	type: ChainType.SOLANA,
	name: 'Solana',
	logo: `${logoPath}/solana.svg`,
	explorerUrl: 'https://solscan.io',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-solana',
		symbol: 'SOL',
		decimals: 9,
		name: 'Solana',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.SOL,
	},
	wrapNativeToken: { address: WRAP_SOL_ADDRESS },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.SOL].https,
			wss: RPC[env][ChainId.SOL].wss,
		},
	},
	minForGas: 105000,
	transakNetworkName: 'solana',
	blowFishNetworkName: 'solana',
};

export const tron: NetWorkChain = {
	kyberAggregatorSlug: '',
	tobiId: TobiChainName.TRON,
	id: ChainId.TRON,
	type: ChainType.TRON,
	name: 'TRON',
	logo: `${logoPath}/tron.svg`,
	explorerUrl: 'https://tronscan.org',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-tron',
		symbol: 'TRX',
		decimals: 6,
		name: 'Tron',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.TRON,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: '',
			wss: '',
		},
	},
	minForGas: 3.5 * 10 ** 8,
	transakNetworkName: 'tron',
};

export const sui: NetWorkChain = {
	kyberAggregatorSlug: '',
	id: ChainId.SUI,
	tobiId: TobiChainName.SUI,
	type: ChainType.SUI,
	name: 'SUI',
	logo: `${logoPath}/sui.svg`,
	explorerUrl: 'https://suivision.xyz/',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-sui',
		symbol: 'SUI',
		decimals: 9,
		name: 'SUI',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.SUI,
	},
	wrapNativeToken: { address: '' },
	rpcUrls: {
		default: {
			http: '',
			wss: '',
		},
	},
	minForGas: 50000000, // 0.05
	transakNetworkName: 'sui',
};

export const mantle: NetWorkChain = {
	kyberAggregatorSlug: 'mantle',
	tobiId: TobiChainName.MNT,
	id: ChainId.MNT,
	type: ChainType.EVM,
	name: 'Mantle',
	logo: `${logoPath}/mantle.svg`,
	explorerUrl: 'https://explorer.mantle.xyz',
	testnet: false,
	nativeToken: {
		tobiId: 'cgk-mantle',
		symbol: 'MNT',
		decimals: 18,
		name: 'Mantle',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.MNT,
	},
	wrapNativeToken: { address: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8' },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.MNT].https,
			wss: RPC[env][ChainId.MNT].wss,
		},
	},
	transakNetworkName: 'mantle',
	isEIP1559: true,
	minForGas: 10 ** 17,
};

export const eclipse: NetWorkChain = {
	kyberAggregatorSlug: '',
	id: ChainId.ECLIPSE,
	tobiId: TobiChainName.ECLIPSE,
	type: ChainType.ECLIPSE,
	name: 'Eclipse',
	logo: `${logoPath}/eclipse.svg`,
	explorerUrl: 'https://explorer.eclipse.xyz/',
	testnet: false,
	nativeToken: {
		tobiId: 'eclipse-So11111111111111111111111111111111111111112',
		symbol: 'ETH',
		decimals: 9,
		name: 'Ethereum',
		address: NATIVE_TOKEN_ADDRESS,
		chainId: ChainId.ECLIPSE,
	},
	wrapNativeToken: { address: WRAP_SOL_ADDRESS },
	rpcUrls: {
		default: {
			http: RPC[env][ChainId.ECLIPSE].https,
			wss: RPC[env][ChainId.ECLIPSE].wss,
		},
	},
	minForGas: 105000,
	transakNetworkName: 'eclipse',
};

/**
 * CHAIN_CONFIG = { 1: {...} , ethereum: {...}, 42161 : {...}, ... }
 * access CHAIN_CONFIG[1] or CHAIN_CONFIG["ethereum"] will both work
 * if we want to add any chain, only need to add info MAINNET_CHAINS
 */

export const NON_EVM_SVM_CHAINS: NetWorkChain[] = [ton, sui, tron];

export const EVM_CHAINS: NetWorkChain[] = [ethereum, arbitrum, base, polygon, op, bnb, avax, mantle];

export const SVM_CHAINS: NetWorkChain[] = [solana, eclipse];

export const MAINNET_CHAINS: NetWorkChain[] = [...NON_EVM_SVM_CHAINS, ...SVM_CHAINS, ...EVM_CHAINS];

export const TEST_NETS: NetWorkChain[] = [
	berachainTesnet,
	storyTestnet,
	// baseSepolia,
	// sepolia,
	// holesky,
	// avaxTestnet,
	// polygonTestnet,
	// opTestnet,
];

// not support swap/send but still can show token info
export const NOT_SUPPORT_CHAINS = {
	[TobiChainName.BTC]: { 'name': 'Bitcoin', 'logo': 'https://cryptologos.cc/logos/bitcoin-btc-logo.svg' },
	[TobiChainName.APT]: { 'name': 'Aptos', 'logo': 'https://cryptologos.cc/logos/aptos-apt-logo.svg' },
	[TobiChainName.MANTA]: {
		'name': 'Manta',
		'logo': 'https://www.polkassets.com/wp-content/uploads/2023/03/Manta_network_colorful_logo_icon_svg.svg',
	},
	// [TobiChainName.MNT]: { 'name': 'Mantle', 'logo': 'https://cryptologos.cc/logos/mantle-mnt-logo.png?v=035' },
	[TobiChainName.METIS]: { 'name': 'Metis', 'logo': 'https://cryptologos.cc/logos/metis-metis-logo.svg' },
	[TobiChainName.BLAST]: { 'name': 'Blast', 'logo': 'https://icons.llamao.fi/icons/chains/rsz_blast.jpg' },
	[TobiChainName.LINEA]: { 'name': 'Linea', 'logo': 'https://icons.llamao.fi/icons/chains/rsz_linea.jpg' },
	[TobiChainName.SCROLL]: { 'name': 'Scroll', 'logo': 'https://cryptologos.cc/logos/scroll-logo.svg' },
};

export const EIP155_CHAINS = MAINNET_CHAINS.concat(TEST_NETS)
	.filter((e) => e.type === ChainType.EVM)
	.map((chain) => ({
		chainId: chain.id,
		name: chain.name,
		logo: chain.logo,
		rpc: chain.rpcUrls.default.http,
		namespace: 'eip155',
		rgb: '',
		smartAccountEnabled: chain.smartAccountEnabled,
	}))
	.reduce<{ [key: string]: EIP155Chain }>((rs, chain) => {
		rs[`eip155:${chain.chainId}`] = chain;
		return rs;
	}, {});

type ChainConfigMap = { [chain: string]: NetWorkChain };
export const CHAIN_CONFIG_MAP = MAINNET_CHAINS.concat(TEST_NETS).reduce<ChainConfigMap>((map, chain) => {
	map[chain.id] = chain;
	return map;
}, {});

export const CHAIN_CONFIG: ChainConfigMap & { has: (key: string | number) => boolean } = new Proxy(
	CHAIN_CONFIG_MAP as any,
	{
		get(_, key) {
			if (key === 'has') {
				// Define the new method
				return function (key: string | number) {
					return !!CHAIN_CONFIG_MAP[key];
				};
			}
			return CHAIN_CONFIG_MAP[key as any as ChainId] || {}; // prevent crash
		},
	},
);

export const STABLE_COINS = {
	[holesky.id]: {
		USDT: '0x7d773af3d29743442dc991c168c434d99836a723',
	},
	[baseSepolia.id]: {
		USDC: '0x4088b2a93f981De86F809399F98955D364518f80',
	},
	[ethereum.id]: {
		USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
		USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
	},
	[arbitrum.id]: {
		USDT: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
		USDC: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
	},
	[polygon.id]: {
		USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
		USDC: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
	},
	[op.id]: {
		USDT: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
		USDC: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
	},
	[avax.id]: {
		USDT: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
		USDC: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
	},
	[base.id]: {
		USDC: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
	},
	[bnb.id]: {
		USDT: '0x55d398326f99059ff775485246999027b3197955',
		USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
	},
	[ton.id]: {
		USDT: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
		JUSDT: 'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA',
	},
	[solana.id]: {
		USDT: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
		USDC: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
	},
	[berachainTesnet.id]: {
		USDC: '0xd6D83aF58a19Cd14eF3CF6fe848C9A4d21e5727c',
	},
};

import { berachainTesnet, ChainId, MAINNET_CHAINS, storyTestnet } from '@/app-constants/chains';
import { NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useMemo } from 'react';

export const useSupportedTestnetChainList = () => {
	const isSupportStoryTestnetNetwork = useQueryFeatureConfig(FeatureConfig.ENABLE_STORY_TESTNET_NETWORK);
	const isSupportBeraTestnetNetwork = useQueryFeatureConfig(FeatureConfig.BERA_TESTNET_SUPPORT);
	return useMemo(() => {
		const testnetsSupport: NetWorkChain[] = [];
		if (isSupportStoryTestnetNetwork) {
			testnetsSupport.push(storyTestnet);
		}
		if (isSupportBeraTestnetNetwork) {
			testnetsSupport.push(berachainTesnet);
		}
		return { testnets: testnetsSupport, isSupportTestnet: testnetsSupport.length > 0 };
	}, [isSupportStoryTestnetNetwork, isSupportBeraTestnetNetwork]);
};

const useChainList = () => {
	const isDisableSuiNetwork = useQueryFeatureConfig(FeatureConfig.DISABLE_SUI_NETWORK);
	const isDisableEclipseNetwork = useQueryFeatureConfig(FeatureConfig.DISABLE_ECLIPSE_NETWORK);
	const isDisableMantleNetwork = useQueryFeatureConfig(FeatureConfig.DISABLE_MANTLE_NETWORK);
	const { testnets: testnetsSupport } = useSupportedTestnetChainList();

	return useMemo(() => {
		let chainsSupport = [...MAINNET_CHAINS];
		if (isDisableSuiNetwork) chainsSupport = chainsSupport.filter((e) => e.id !== ChainId.SUI);
		if (isDisableEclipseNetwork) chainsSupport = chainsSupport.filter((e) => e.id !== ChainId.ECLIPSE);
		if (isDisableMantleNetwork) chainsSupport = chainsSupport.filter((e) => e.id !== ChainId.MNT);
		chainsSupport.push(...testnetsSupport);
		const chainsOptions = chainsSupport.map((el) => ({ value: el.id.toString(), label: el.name }));
		return { chainsSupport, chainsOptions };
	}, [isDisableSuiNetwork, isDisableEclipseNetwork, isDisableMantleNetwork, testnetsSupport]);
};
export default useChainList;

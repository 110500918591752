import { getEnvironment } from '@/app-helpers';
import { Base64, SessionCrypto, hexToByteArray } from '@tonconnect/protocol';
import axios from 'axios';
import axiosRateLimit from 'axios-rate-limit';
import Eventsource from 'eventsource';
import {
	ConnectEvent,
	DisconnectEvent,
	KeyPair,
	RpcMethod,
	TAppConnection,
	TonConnectRequest,
	TonConnectTAppRequest,
	WalletResponse,
} from '../types';
import { decryptTonConnectMessage, lastEventIdStored } from './connectService';
const BRIDGE_URL = 'https://ton-bridge.tobiwallet.app/bridge';

const DEFAULT_TLL = 300;
const AxiosBridge = axiosRateLimit(
	axios.create({
		baseURL: BRIDGE_URL,
		timeout: 60000,
	}),
	{ maxRequests: 5, perMilliseconds: 1000 },
);
class HTTPBridgeService {
	async sendEventToBridge<T extends RpcMethod>({
		messages,
		sessionKeyPair,
		clientSessionId,
		ttl = DEFAULT_TLL,
	}: {
		messages: WalletResponse<T> | ConnectEvent | DisconnectEvent;
		sessionKeyPair: KeyPair;
		clientSessionId: string;
		ttl?: number;
	}) {
		const session = new SessionCrypto(sessionKeyPair);
		const messageEncrypt = session.encrypt(JSON.stringify(messages), hexToByteArray(clientSessionId));
		if (getEnvironment('dev')) {
			return await AxiosBridge.post(
				`/message?client_id=${sessionKeyPair.publicKey}&to=${clientSessionId}&ttl=${ttl}`,
				Base64.encode(messageEncrypt),
				{
					headers: {
						'Content-Type': 'text/plain',
					},
				},
			);
		} else {
			return await AxiosBridge.post(
				`/message?client_id=${sessionKeyPair.publicKey}&to=${clientSessionId}&ttl=${ttl}`,
				Base64.encode(messageEncrypt),
			);
		}
	}
	subscribeEventTonConnect({
		connections,
		handleMessage,
	}: {
		connections: Record<string, TAppConnection>;
		handleMessage: (params: TonConnectTAppRequest) => void;
	}) {
		const sessionIds = Object.values(connections)
			.map((c) => new SessionCrypto(c.sessionKeyPair).sessionId)
			.join(',');

		let subscriberUrl = `${BRIDGE_URL}/events?client_id=${sessionIds}`;
		const lastEventId = lastEventIdStored.getLastEventId();
		if (lastEventId) {
			subscriberUrl += `&last_event_id=${lastEventId}`;
		}
		const eventSource = new Eventsource(subscriberUrl);
		const onMessage = (params: MessageEvent<string>) => {
			console.log('ton connect message data', params);
			lastEventIdStored.setLastEventId(params.lastEventId);
			const { from, message }: TonConnectRequest = JSON.parse(params.data);
			const connection = connections[from];
			if (!connection) return;
			handleMessage(decryptTonConnectMessage({ message, from, connection }));
		};

		const onOpen = () => {
			console.log('SSE connect: opened');
		};

		const onError = (event: Event) => {
			console.log('SSE connect: error', event);
		};

		eventSource.addEventListener('message', onMessage);
		eventSource.addEventListener('open', onOpen);
		eventSource.addEventListener('error', onError);
		return () => {
			eventSource.removeEventListener('message', onMessage);
			eventSource.removeEventListener('open', onOpen);
			eventSource.removeEventListener('error', onError);
			eventSource.close();
		};
	}
}
export const httpBridgeService = new HTTPBridgeService();

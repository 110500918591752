import { QUERY_KEYS } from '@/app-constants';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { isChainNeedApprove, isEvmChain, isTronChain } from '@/app-helpers/token';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TApproveParams, getErc20Contract, getTrc20Contract } from '.';

export const getTokenAllowance = async ({ tokenContract, spenderContract, chainId }): Promise<bigint | null> => {
	if (!spenderContract || isNativeToken(tokenContract)) return null;

	if (isEvmChain(chainId)) {
		const erc20Contract = getErc20Contract(chainId, tokenContract);
		return erc20Contract.allowance(MpcWallet.getEvmWalletAddress(), spenderContract);
	}

	if (isTronChain(chainId)) {
		const trc20Contract = getTrc20Contract(tokenContract);
		const data = await trc20Contract.methods.allowance(MpcWallet.getTronWalletAddress(), spenderContract).call();
		return data?.[0];
	}

	return null;
};

export function useAllowance(
	{ spenderContract, tokenContract, chainId }: TApproveParams,
	options?: UseQueryOptions<bigint>,
) {
	const allowance = useQuery({
		queryKey: [QUERY_KEYS.GET_TOKEN_ALLOWANCE, chainId, tokenContract, spenderContract],
		queryFn: async () => {
			return getTokenAllowance({ tokenContract, spenderContract, chainId });
		},
		enabled: isChainNeedApprove(chainId) && !!tokenContract && !!spenderContract,
		...options,
	});
	return allowance;
}

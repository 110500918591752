import {
	ArbIcon,
	AvaxIcon,
	BaseIcon,
	BnbIcon,
	EclipseIcon,
	EthereumIcon,
	FiMoreIcon,
	MantleIcon,
	OpIcon,
	PolygonIcon,
	SolanaIcon,
	SuiIcon,
	TonIcon,
	TronIcon,
    StoryIcon
} from '@/assets/icons';
import { Center, Flex } from '@chakra-ui/react';
import { ChainId } from '@/app-constants/chains';

interface ChainIconsProps {
	chains: string[] | number[] | ChainId[];
	translatePlacement?: 'left' | 'right';
	showMore?: boolean;
}

const CHAINS_LOGO = {
	[ChainId.TON]: (size: number) => <TonIcon width={size} height={size} />,
	[ChainId.SOL]: (size: number) => <SolanaIcon width={size} height={size} />,
	[ChainId.ETH]: (size: number) => <EthereumIcon width={size} height={size} />,
	[ChainId.ARB]: (size: number) => <ArbIcon width={size} height={size} />,
	[ChainId.BASE]: (size: number) => <BaseIcon width={size} height={size} />,
	[ChainId.POLYGON]: (size: number) => <PolygonIcon width={size} height={size} />,
	[ChainId.OP]: (size: number) => <OpIcon width={size} height={size} />,
	[ChainId.BNB]: (size: number) => <BnbIcon width={size} height={size} />,
	[ChainId.AVAX]: (size: number) => <AvaxIcon width={size} height={size} />,
	[ChainId.TRON]: (size: number) => <TronIcon width={size} height={size} />,
	[ChainId.SUI]: (size: number) => <SuiIcon width={size} height={size} />,
	[ChainId.MNT]: (size: number) => <MantleIcon width={size} height={size} />,
	[ChainId.ECLIPSE]: (size: number) => <EclipseIcon width={size} height={size} />,
	[ChainId.STORY_TESTNET]: (size: number) => <StoryIcon width={size} height={size} />,
};
const ChainIconComponent = ({ chainId, size }: { chainId: ChainId; size: number }) => {
	return CHAINS_LOGO[chainId](size);
};
export const ChainIcons: React.FC<ChainIconsProps> = ({ chains, translatePlacement = 'right', showMore }) => {
	const total = chains.length;
	return (
		<Flex>
			{chains.map((chain, index) => {
				const transform =
					translatePlacement === 'right'
						? index !== chains.length
							? `translateX(${(total - index) * 6}px)`
							: ''
						: `translateX(-${index * 6}px)`;
				return (
					<Center
						position="relative"
						key={index}
						padding="1px"
						backgroundColor="white"
						zIndex={index}
						transform={transform}
						borderRadius="100%"
					>
						<ChainIconComponent chainId={chain} size={18} />
					</Center>
				);
			})}
			{showMore && (
				<Center
					position="relative"
					zIndex={chains.length + 1}
					borderRadius="100%"
					width={5}
					height={5}
					backgroundColor="cyan"
				>
					<FiMoreIcon />
				</Center>
			)}
		</Flex>
	);
};

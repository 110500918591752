import { CHAIN_CONFIG, ChainId, WRAP_SOL_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { isNativeToken } from '@/app-helpers/address';

import { uniqueId } from '@/app-helpers/random';
import { displayMaxFee, isRouteParamsEmpty } from '@/app-hooks/swap';
import { filterParams, getMyWalletAddressByChain, getSwapFeeType } from '@/app-hooks/swap/helper';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	MAX_FEE_SOL_SWAP,
	SwapAbstract,
	SwapProvider,
	UpdateStatusFn,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';
import { useSubmitSolTransaction } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { SolanaFeeType, TransactionType } from '@/app-types';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

const API_DOMAIN = 'https://transaction-v1.raydium.io';

const getTokenAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? WRAP_SOL_ADDRESS : token.address);

const getBuildRouteParams = (args): AxiosRequestConfig => {
	return { url: `${API_DOMAIN}/transaction/swap-base-in`, method: 'POST', data: args };
};

class Raydium extends SwapAbstract<RouteRaydium> {
	provider = SwapProvider.RAYDIUM;
	#swapData: { transaction: string }[];
	get swapData() {
		return this.#swapData;
	}
	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 50 : (+slippage * 100) | 0;
	}

	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwap(paramsSwap, signal, this.formatSlippage(paramsSwap.slippage));
		if (!payload) return;
		const { data } = await axios(payload);
		if (!data?.success) throw new Error('Empty route');
		return formatRoute(data, paramsSwap);
	}

	async buildRoute({ route }: { route: SelectedRoute<RouteRaydium> }): Promise<SelectedRoute<GenericRoute>> {
		const { route: routeData, tokenIn, tokenOut } = route;

		const { data: respFee } = await axios.get<{ data: { default: { m: number; h: number; vh: number } } }>(
			`https://api-v3.raydium.io/main/auto-fee`,
		);
		const feeInfo = respFee.data.default;

		const isInputSol = isNativeToken(tokenIn?.address);
		const isOutputSol = isNativeToken(tokenOut?.address);

		const { fromPubKey } = await SolWallet.init('mainnet-beta', {
			commitment: 'confirmed',
		});

		const { solanaGasFeeType } = getSwapFeeType();
		const computeUnitPriceMicroLamports =
			{
				[SolanaFeeType.FAST]: feeInfo.m,
				[SolanaFeeType.TURBO]: feeInfo.h,
				[SolanaFeeType.ULTRA]: feeInfo.vh,
			}[solanaGasFeeType] || '';

		const params = {
			computeUnitPriceMicroLamports: String(computeUnitPriceMicroLamports),
			swapResponse: routeData,
			txVersion: 'V0',
			wallet: getMyWalletAddressByChain(ChainId.SOL),
			wrapSol: isInputSol,
			unwrapSol: isOutputSol, // true means output mint receive sol, false means output mint received wsol
			inputAccount: isInputSol
				? undefined
				: (await getAssociatedTokenAddress(new PublicKey(tokenIn.address), fromPubKey)).toBase58(),
			outputAccount: isOutputSol
				? undefined
				: (await getAssociatedTokenAddress(new PublicKey(tokenOut.address), fromPubKey)).toBase58(),
		};
		const { data: response } = await axios(getBuildRouteParams(params));
		if (!response?.success) throw response;
		const data = response.data;
		this.#swapData = data;
		return route;
	}

	extractRoute(params: SelectedRoute<RouteRaydium>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const RaydiumSwap = new Raydium();

const getRouteParamsSwap = (
	args: ArgsGetRoute,
	signal: AbortSignal,
	slippageBps: string | number,
): AxiosRequestConfig => {
	const { tokenIn, tokenOut, amountIn } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		inputMint: getTokenAddress(tokenIn),
		outputMint: getTokenAddress(tokenOut),
		amount: amountIn,
		slippageBps,
		txVersion: 'V0',
	};

	filterParams(params);
	return { url: `${API_DOMAIN}/compute/swap-base-in`, params, signal };
};

export type RouteRaydium = {
	id: string;
	success: boolean;
	version: string;
	data: {
		swapType: string; // 'BaseIn' or other types depending on your context
		inputMint: string;
		inputAmount: string;
		outputMint: string;
		outputAmount: string;
		otherAmountThreshold: string;
		slippageBps: number;
		priceImpactPct: number;
		referrerAmount: string;
		routePlan: Array<{
			poolId: string;
			inputMint: string;
			outputMint: string;
			feeMint: string;
			feeRate: number;
			feeAmount: string;
			remainingAccounts: string[];
			lastPoolPriceX64: string;
		}>;
	};
};

const formatRoute = (routeData: RouteRaydium, params: ArgsGetRoute): SelectedRoute<RouteRaydium> =>
	routeData
		? {
				route: routeData,
				routerAddress: '',
				id: uniqueId(),
				provider: SwapProvider.RAYDIUM,
				timestamp: Date.now(),
				params,
				tokenIn: params.tokenIn,
				tokenOut: params.tokenOut,
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteRaydium>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route?.data;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	return {
		amountOut: routeSwap?.outputAmount,
		amountIn: routeSwap?.inputAmount,
		minAmountOut: routeSwap?.otherAmountThreshold,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative: BigInt(CHAIN_CONFIG[ChainId.SOL].minForGas),
		gasDisplay: displayMaxFee({
			usdPriceNative,
			chainId: ChainId.SOL,
			gasNative: MAX_FEE_SOL_SWAP,
		}),
		dappInfo: {
			logo: '/icons/brands/raydium.webp',
			domain: SwapProvider.RAYDIUM,
		},
	};
};

export const useExecuteRouteRaydium = () => {
	const { sendTransaction: submitSolTxs } = useSubmitSolTransaction();
	const takeFee = useTakeFeeSwap();

	const response = useMutation({
		mutationKey: ['exe-route-raydium'],

		mutationFn: async ({
			route: routeData,
			onUpdateStatus,
		}: {
			route: SelectedRoute<RouteRaydium>;
			onUpdateStatus: UpdateStatusFn;
		}) => {
			const allTxBuf = RaydiumSwap.swapData.map((tx) => Buffer.from(tx.transaction, 'base64'));
			let hash;
			for (const swapTransactionBuf of allTxBuf) {
				const txId = await submitSolTxs({
					data: swapTransactionBuf,
					metadata: routeData,
					transactionType: TransactionType.Swap,
					callback: onUpdateStatus,
				});
				if (!hash) hash = txId;
			}
			return { hash };
		},
		onSuccess: ({ hash }, { route }) => {
			takeFee({ hash, route });
		},
	});
	return response;
};

import { InputSearch } from '@/app-components/common';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { useGetAffiliateGroupFilter, useGetAffiliateUserFilter } from '@/app-hooks/api/user/affiliate';
import { useDebounce } from '@/app-hooks/common';
import { AffiliateGroupFilterItem, AffiliateTokenFilerParams, AffiliateUserFilterItem } from '@/app-services/fee';
import { colors } from '@/app-theme/theme';
import { GroupInfo } from '@/app-views/affiliate/containers/Group/ListGroup';
import { SettingItem } from '@/app-views/portfolio/pages/token/components/SettingToken';
import { FiFilterIcon } from '@/assets/icons/fi-filter-icon';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
import {
	Collapse,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	List,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const isEqual = (value: string, keyword: string) => value?.toLowerCase().includes(keyword);
const extractInfo = (data: AffiliateUserFilterItem | AffiliateGroupFilterItem) => {
	const { chatId, title, photoUrl } = data as AffiliateGroupFilterItem;
	const { id, name, username } = data as AffiliateUserFilterItem;
	return { id: chatId || id, name: title || name, photoUrl, username };
};

const SearchPopup = ({
	isOpen,
	onClose,
	title,
	onSelectGroup,
	onSelectUser,
}: {
	title: SearchMode;
	isOpen: boolean;
	onClose: () => void;
	onSelectGroup: (id: AffiliateGroupFilterItem) => void;
	onSelectUser: (id: AffiliateUserFilterItem) => void;
}) => {
	const [keyword, setKeyword] = useState('');
	const keywordDebounce = useDebounce(keyword, 500);
	const isGroup = title === SearchMode.GROUP;

	const { data: dataGroup } = useGetAffiliateGroupFilter(isGroup);
	const { data: dataUser } = useGetAffiliateUserFilter(title === SearchMode.USER);

	const options = useMemo(() => {
		const list = isGroup ? dataGroup?.groups : dataUser?.users;
		return keywordDebounce
			? list?.filter((e) => {
					const { name, username } = extractInfo(e);
					return isEqual(name, keywordDebounce) || isEqual(username, keywordDebounce);
			  })
			: list;
	}, [dataGroup, isGroup, keywordDebounce, dataUser]);

	isGroup ? dataGroup?.groups : dataUser?.users;

	const { t } = useTranslation();
	return (
		<Drawer isOpen={isOpen} placement="bottom" isFullHeight onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent pt={getFullScreenSpace(0, 76)}>
				<DrawerCloseButton top={getFullScreenSpace(0, 86)} />
				<DrawerHeader>{title}</DrawerHeader>

				<DrawerBody>
					<Flex gap={4} direction={'column'}>
						<InputSearch
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
							sx={{ border: 'none', background: colors.gray[100], borderRadius: '8px' }}
							placeholder={t('affiliate.searchPlaceholder')}
						/>

						<VStack spacing={4} w={'100%'}>
							{options?.map((el, i) => {
								const { name, username, photoUrl, id } = extractInfo(el);
								return (
									<GroupInfo
										onClick={() => {
											isGroup
												? onSelectGroup(el as AffiliateGroupFilterItem)
												: onSelectUser(el as AffiliateUserFilterItem);
										}}
										name={name}
										telegramId={username}
										logo={photoUrl}
										key={i}
										isGroup={isGroup}
									/>
								);
							})}
						</VStack>
					</Flex>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

const Select = ({ label, onClick }: { label: string; onClick: () => void }) => {
	return (
		<Flex
			onClick={onClick}
			w={'100%'}
			bg={colors.gray[100]}
			borderRadius={'8px'}
			p={4}
			justifyContent={'space-between'}
		>
			<Text>{label}</Text>
			<ChevronDownIcon />
		</Flex>
	);
};

enum SearchMode {
	USER = 'Search User',
	GROUP = 'Search Community/Group',
}
export function FilterTokens({ onRefreshTokens }: { onRefreshTokens: (data: AffiliateTokenFilerParams) => void }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const searchDisclosure = useDisclosure();
	const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.USER);

	const [group, setGroup] = useState<AffiliateGroupFilterItem>();
	const [user, setUser] = useState<AffiliateUserFilterItem>();

	const [feeGroup, setFeeGroup] = useState(true);
	const [feeUser, setFeeUser] = useState(true);

	const onChange = async ({
		feeUser,
		feeGroup,
		group,
		user,
	}: {
		feeUser: boolean;
		feeGroup: boolean;
		group: AffiliateGroupFilterItem;
		user: AffiliateUserFilterItem;
	}) => {
		const payload: AffiliateTokenFilerParams = {};
		if (group && feeGroup) payload.communityIds = [group.chatId];
		if (user && feeUser) payload.userIds = [user.id];
		onRefreshTokens(payload);
	};

	return (
		<>
			<Flex
				background={colors.gray[100]}
				minW={'40px'}
				height={'40px'}
				borderRadius={'8px'}
				alignItems={'center'}
				justifyContent={'center'}
				border={`1px solid ${(group && feeGroup) || (user && feeUser) ? colors.cyan[400] : 'transparent'}`}
			>
				<FiFilterIcon onClick={onOpen} />
			</Flex>
			<Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Filter</DrawerHeader>
					<DrawerBody py={5}>
						<List spacing={3}>
							<SettingItem
								text="Include Fees from Groups"
								selected={feeGroup}
								onChange={(val) => {
									setFeeGroup(val);
									onChange({ feeUser, feeGroup: val, group, user });
								}}
							/>
							<Collapse in={feeGroup}>
								<Select
									label={group ? group.title : 'All Groups'}
									onClick={() => {
										setSearchMode(SearchMode.GROUP);
										searchDisclosure.onOpen();
									}}
								/>
							</Collapse>
							<Divider borderColor={colors.gray[200]} variant={'dashed'} />
							<SettingItem
								text="Include Fees from Users"
								selected={feeUser}
								onChange={(val) => {
									setFeeUser(val);
									onChange({ feeUser: val, feeGroup, group, user });
								}}
							/>

							<Collapse in={feeUser}>
								<Select
									label={user ? user?.name : 'All Users'}
									onClick={() => {
										setSearchMode(SearchMode.USER);
										searchDisclosure.onOpen();
									}}
								/>
							</Collapse>
						</List>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<SearchPopup
				{...searchDisclosure}
				title={searchMode}
				onSelectGroup={(id) => {
					setGroup(id);
					onChange({ feeUser, feeGroup, group: id, user });
					searchDisclosure.onClose();
					onClose();
				}}
				onSelectUser={(id) => {
					setUser(id);
					onChange({ feeUser, feeGroup, group, user: id });
					searchDisclosure.onClose();
					onClose();
				}}
			/>
		</>
	);
}

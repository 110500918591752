import { ChainId } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { getTobiChainName } from '@/app-helpers/token';

export type AddRemoveTokenPayload = {
	tokens: { tobiId?: string; chainId: ChainId | string; address?: string }[];
	action: 'unhide' | 'hide' | 'show' | 'unshow';
};

export const ServiceToken = {
	hideUnHideTokens: async (payload: AddRemoveTokenPayload) => {
		payload.tokens = payload.tokens
			.map((e) => {
				e.chainId = e.chainId ? getTobiChainName(e.chainId) : '';
				return e;
			})
			.filter((e) => e.chainId);
		const response = await axiosBotAPI.post('/v2/wallet/portfolio/visibility-tokens', payload);
		return response.data;
	},
};

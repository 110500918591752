import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { ELEMENT_IDS } from '@/app-constants';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { UserContent } from '@/app-views/affiliate/containers/Group/UserContent';
import { Container } from '@chakra-ui/react';

const User = () => {
	return (
		<PageNavigation>
			<Container
				id={ELEMENT_IDS.HOME_CONTAINER}
				className="overflow-y-auto hide-scrollbar"
				sx={{ gap: 6, display: 'flex', flexDirection: 'column' }}
				pt={4}
				pb={getFullScreenSpace(80, 86)}
			>
				<UserContent />
			</Container>
		</PageNavigation>
	);
};
export default User;

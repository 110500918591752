import { CHAIN_CONFIG } from '@/app-constants/chains';
import { getTransactionFee } from '@/app-features/app-wallet-connect/utils/HelperUtil';
import { getFullScreenSpace } from '@/app-helpers/ui';
import {
	Avatar,
	Button,
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import { useGasFeeData } from '../../../app-hooks/wallet';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { useProposalAction } from '../hooks';
import { useModalStore } from '../store/ModalStore';
import { botConnectorHandler } from '../utils/bot-connector.handler';

export default function SessionSendTransactionModal() {
	const { data, open, onClose } = useModalStore();
	const { data: gasFeeData } = useGasFeeData(data.payload['chainId']);
	const { onApprove, isPendingApprove, isPendingReject, onReject } = useProposalAction(data);

	if (data.payload.method !== 'eth_sendTransaction') {
		return <></>;
	}

	const metadata = botConnectorHandler.getClient(data.clientId)?.metadata;
	const transaction = data.payload.data;

	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} size="full" trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent pt={getFullScreenSpace(0, 76)}>
				<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Confirm Transaction
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody>
					<Flex mt={2} flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Center border="1px solid rgba(0, 0, 0, 0.08)" gap={2} borderRadius={52} px={2} py={1}>
							<Avatar src={metadata.icons[0]} name={metadata.name} width={8} height={8} />
							<Text fontSize="sm">{metadata.name}</Text>
						</Center>
						<Card padding={'16px'} width={'100%'} background={'gray.100'} gap={'8px'}>
							<Flex justifyContent={'space-between'}>
								<Text fontSize="sm" fontWeight={500} mb={2}>
									Transaction Information
								</Text>
							</Flex>
							<TransactionItemInfo title="From" value={transaction?.from} />
							<Divider />
							<TransactionItemInfo title="To" value={transaction?.to} />
							<Divider />
							{transaction?.value && (
								<TransactionItemInfo
									title="Value"
									value={ethers.formatEther(BigInt(transaction?.value || 0)?.toString())}
								/>
							)}
							{/* <Divider /> */}
							<TransactionItemInfo title="Network" value={CHAIN_CONFIG[data.payload.chainId]?.name} />
							{/* <Divider /> */}

							<TransactionItemInfo
								title="Gas"
								value={getTransactionFee(transaction?.gas, gasFeeData?.gasPrice, data.payload.chainId)}
							/>

							{/* {transaction?.data && <TransactionItemInfo title="Data" value={transaction?.data} />} */}
						</Card>
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={onReject}
					>
						{isPendingReject ? <Spinner size="sm" /> : 'Deny'}
					</Button>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={onApprove}
					>
						{isPendingApprove ? <Spinner size="sm" color="white" /> : 'Approve'}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}

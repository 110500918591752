import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { useAppState } from '@/app-store/app';
import { useRequestFundStore } from '@/app-store/request-fund';
import { useSwapStore } from '@/app-store/swap';
import { useLimitStore } from '@/app-store/swap/limit';
import { useEffect } from 'react';

let init = false;
export const useResetStorageData = () => {
	const { action } = useParseBotStartRedirectParams();

	const { reset: resetSentStore } = useSentTokenStore();
	const { reset: resetContactStore } = useContactStore();
	const { reset: resetSwapStore } = useSwapStore(false);
	const { reset: resetLimitStore } = useLimitStore();
	const { reset: resetRequestFund } = useRequestFundStore();
	const { reset: resetAppStore } = useAppState();

	useEffect(() => {
		if (init ? true : !action) {
			// skip first for bot redirect
			resetSwapStore();
			resetLimitStore();
			resetSentStore();
			resetContactStore();
			resetRequestFund();
			resetAppStore();
		}
		init = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action]);
};

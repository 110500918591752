import { Collapse, Divider, Flex, FlexProps } from '@chakra-ui/react';

import ApproveModal from '@/app-components/ApproveModal';

import { SelectedRoute } from '@/app-store/swap';
import { TransactionType } from '@/app-types';

import { TokenAmountInput } from '@/app-components/common/SelectToken/TokenAmount';
import Warning from '@/app-components/common/Warning';
import BuyToken from '@/app-views/swap/components/BuyToken';
import ListRoute from '@/app-views/swap/components/ListRoute';

import { InputMode } from '@/app-constants';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { SwapErrorInfo, SwapService } from '@/app-hooks/swap';
import { SwapErrorType } from '@/app-hooks/swap/type';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { GasWarning } from '@/app-views/wallet/components/Portfolio/GasWarning';
import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';
import { OnValueChange } from 'react-number-format';

export type SwapFormProps = {
	onMax: () => void;
	usdPriceTokenIn: string | number | undefined;
	errorMsg: SwapErrorInfo;
	onChangeAmount: OnValueChange;
	openApprove: boolean;
	gettingRoute: boolean;
	fetchingSuggestRoute: boolean;
	onChangeRoute: (v: SelectedRoute) => void;
	onClickRoute: (v: SelectedRoute) => void;
	suggestRoutes: SelectedRoute[];
	selectedRoute: SelectedRoute | undefined;
	hideApproveModal: () => void;
	parsedAmount: bigint | undefined;
	toggleInputMode: () => void;
	tokenAmountInfo: {
		amountIn: string;
		usdAmount: string | number;
	};
	quickSwap: boolean;
	tokenIn: ITokenSearch | undefined;
	tokenOut: ITokenSearch | undefined;
	tokenInfoIn: TokenInfo | undefined;
	tokenInfoOut: TokenInfo | undefined;
	inputMode: InputMode;
	isNonWallet: boolean;
	containerProps: FlexProps;
	refetch: () => Promise<any>;
};

export default function SwapForm(props: SwapFormProps) {
	const {
		onMax,
		errorMsg,
		onChangeAmount,
		openApprove,
		gettingRoute,
		fetchingSuggestRoute,
		onChangeRoute,
		suggestRoutes,
		selectedRoute,
		onClickRoute,
		hideApproveModal,
		parsedAmount,
		usdPriceTokenIn,
		toggleInputMode,
		tokenAmountInfo,
		quickSwap,
		tokenIn,
		tokenInfoIn,
		tokenInfoOut,
		inputMode,
		isNonWallet,
		containerProps,
		refetch,
	} = props;
	const { dappInfo } = SwapService.extractRouteInfo({ route: selectedRoute });
	const routerAddress = selectedRoute?.routerAddress;

	const routeError =
		errorMsg?.messages?.filter(
			(e) => ![SwapErrorType.ROUTE, SwapErrorType.VALIDATE_AMOUNT].includes(e.errorType),
		) ?? [];

	return (
		<>
			<Flex flexDirection={'column'} flex={1} gap={'16px'} {...containerProps}>
				<Flex direction={'column'}>
					<TokenAmountInput
						token={tokenIn}
						value={tokenAmountInfo.amountIn}
						usdValue={tokenAmountInfo.usdAmount}
						onValueChange={onChangeAmount}
						onMax={() => {
							onMax?.();
							dataDogAddAction(DATADOG_ACTIONS.TRADE_USE_MAX);
						}}
						usdPrice={usdPriceTokenIn}
						inputMode={inputMode}
						toggleInputMode={() => {
							toggleInputMode?.();
							dataDogAddAction(DATADOG_ACTIONS.TRADE_SWITCH_INPUT_MODE);
						}}
						inputStyle={{
							color: errorMsg?.messages?.some((e) => e.errorType === SwapErrorType.VALIDATE_AMOUNT)
								? 'red'
								: undefined,
						}}
						wrapperId={TOUR_GUIDE_STEPS_TARGET.TRADE.AMOUNT}
					/>

					<Collapse
						in={!!routeError.length}
						style={quickSwap ? { overflow: 'unset' } : undefined}
						unmountOnExit
					>
						<Flex flexDirection="column" gap="14px" mt={'16px'}>
							{routeError?.map((e) => {
								if (e.errorType === SwapErrorType.GAS && quickSwap) {
									return <GasWarning key={e.msg} chainId={tokenInfoIn?.chainId} msg={e?.msg} />;
								}
								return [SwapErrorType.FUND, SwapErrorType.GAS].includes(e.errorType) && !quickSwap ? (
									<BuyToken
										errorMsg={e}
										key={e.msg}
										tokenInfoOut={tokenInfoOut}
										tokenInfoIn={tokenInfoIn}
										tokenIn={tokenIn}
										buyNative={e.errorType === SwapErrorType.GAS}
										isNonWallet={isNonWallet}
									/>
								) : (
									<Warning key={e.msg} msg={e.msg} title={e?.title} status={e?.type} />
								);
							})}
						</Flex>
					</Collapse>
				</Flex>

				<Divider variant={'dashed'} />
				<ListRoute
					quickSwap={quickSwap}
					errorMsg={errorMsg}
					tokenIn={tokenInfoIn}
					tokenOut={tokenInfoOut}
					loadingRouteByUserInput={gettingRoute}
					loadingSuggestedRoutes={fetchingSuggestRoute}
					suggestedRoutes={suggestRoutes}
					onClickRoute={onClickRoute}
					onChangeRoute={onChangeRoute}
					selectedRoute={selectedRoute}
					amountIn={parsedAmount}
					refetch={refetch}
				/>
			</Flex>
			<ApproveModal
				isOpen={openApprove}
				approveInfo={{
					chainId: tokenInfoIn?.chainId,
					contract: routerAddress,
					amount: parsedAmount?.toString() ?? '',
					token: tokenInfoIn,
					type: TransactionType.Swap,
				}}
				dappInfo={dappInfo}
				onClose={hideApproveModal}
			/>
		</>
	);
}

import { getTokenId } from '@/app-helpers/token';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { AffiliateServiceAPI, AffiliateTokenFilerParams, AffiliateTokenInfo } from '@/app-services/fee';
import { useQuery } from '@tanstack/react-query';

export const useGetAffiliateInfo = (enabled = true) => {
	const response = useQuery({
		queryKey: ['get-affiliate-info'],
		queryFn: async () => {
			return AffiliateServiceAPI.getFeeInfo();
		},
		enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateGroupInfo = ({ page, limit }: { page: number; limit: number }) => {
	const response = useQuery({
		queryKey: ['get-group-info', page, limit],
		queryFn: async () => {
			return AffiliateServiceAPI.getGroupInfo({ page, limit });
		},
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateGroupFilter = (enabled: boolean) => {
	const response = useQuery({
		queryKey: ['get-group-filter'],
		queryFn: async () => {
			return AffiliateServiceAPI.getGroupFilter({});
		},
		enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateUserFilter = (enabled: boolean) => {
	const response = useQuery({
		queryKey: ['get-user-filter'],
		queryFn: async () => {
			return AffiliateServiceAPI.getUserFilter({});
		},
		enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateUserInfo = ({ page, limit }: { page: number; limit: number }) => {
	const response = useQuery({
		queryKey: ['get-user-info', page, limit],
		queryFn: async () => {
			return AffiliateServiceAPI.getUserInfo({ page, limit });
		},
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateGroupStat = () => {
	const response = useQuery({
		queryKey: ['get-group-stat-info'],
		queryFn: async () => {
			return AffiliateServiceAPI.getGroupStat();
		},
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateUserStat = () => {
	const response = useQuery({
		queryKey: ['get-user-stat-info'],
		queryFn: async () => {
			return AffiliateServiceAPI.getUserStat();
		},
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetAffiliateTokensStat = (data: AffiliateTokenFilerParams) => {
	const response = useQuery({
		queryKey: ['get-token-stat-info', JSON.stringify(data)],
		queryFn: async () => {
			return AffiliateServiceAPI.getTokensInfo(data);
		},
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return useCalculateAffiliateTokenInfo(response.data?.tokens ?? []);
};

export const useCalculateAffiliateTokenInfo = (payload: AffiliateTokenInfo[]) => {
	const response = useQuery({
		queryKey: ['calculate-fee', payload.map((e) => getTokenId(e)).join(',')],
		queryFn: async () => {
			return AffiliateServiceAPI.calculateAffiliateTokenInfo(payload);
		},
		enabled: !!payload.length,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

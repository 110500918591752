import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { uniqueId } from '@/app-helpers/random';
import { getWrapNativeToken } from '@/app-helpers/token';
import { getSigner } from '@/app-helpers/web3';
import { isRouteParamsEmpty } from '@/app-hooks/swap';
import { formatListRoutesBestReturn } from '@/app-hooks/swap/helper';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { approveErc20, getTokenAllowance } from '@/app-hooks/wallet';
import { getEvmBalance } from '@/app-hooks/wallet/useWalletBalance';
import useWrapUnWrapToken from '@/app-hooks/wallet/useWrapUnWrapToken';
import { SelectedRoute } from '@/app-store/swap';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { ADDRESSES, initClient, swapRouterV3, swapV3 } from '@storyhunt/wrapper-sdk';
import { useMutation } from '@tanstack/react-query';
import { MaxUint256 } from 'ethers';

const getTokenAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? ADDRESSES.TOKENS.WIP.id : token.address);
type ElementOf<T> = T extends (infer U)[] ? U : never;

export type RouteStoryHunt = ElementOf<Exclude<Awaited<ReturnType<typeof swapRouterV3>>, Error>>;

class StoryHunt extends SwapAbstract<RouteStoryHunt> {
	provider = SwapProvider.STORY_HUNT;
	#initTask: Promise<any>;
	#isReady: boolean = false;
	async init() {
		try {
			if (this.#isReady) return;
			const { signer } = await getSigner(ChainId.STORY_TESTNET);
			this.#initTask = initClient({ ethersSigner: signer as any });
			this.#isReady = true;
		} catch (error) {
			throw new Error('StoryHunt is not ready');
		}
	}
	formatSlippage(slippage: string | number): number {
		return +slippage === AUTO_SLIPPAGE ? 1 : +slippage;
	}

	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		try {
			await this.init();
			await this.#initTask;
			if (!this.#isReady) throw new Error('StoryHunt X is not ready');
			console.log(paramsSwap, ADDRESSES);
			if (isRouteParamsEmpty(paramsSwap)) return;

			const { tokenIn, tokenOut, amountIn } = paramsSwap;
			const routes = await swapRouterV3(
				getTokenAddress(tokenIn),
				getTokenAddress(tokenOut),
				BigInt(amountIn),
				true,
			);
			console.log({ routes });
			if (routes instanceof Error) {
				throw routes;
			}
			if (signal.aborted) throw new Error('Cancelled');
			return formatListRoute(routes, paramsSwap);
		} catch (error) {
			console.log('getRoute error', error);

			throw error;
		}
	}

	extractRoute(params: SelectedRoute<RouteStoryHunt>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const StoryHuntSwap = new StoryHunt();

const formatListRoute = (routeResponse: RouteStoryHunt[], params: ArgsGetRoute): SelectedRoute | undefined => {
	const allRoutes = formatListRoutesBestReturn(routeResponse?.map((el) => formatRoute(el, params)));
	const bestRoute = allRoutes?.[0];
	if (!bestRoute) return;
	return { ...bestRoute, allRoutes };
};

const formatRoute = (routeData: RouteStoryHunt, paramsSwap: ArgsGetRoute): SelectedRoute<RouteStoryHunt> =>
	routeData
		? {
				routerAddress: ADDRESSES.V3_SWAP_ROUTER_CONTRACT_ADDRESS,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.STORY_HUNT,
				timestamp: Date.now(),
				params: paramsSwap,
				tokenIn: paramsSwap.tokenIn,
				tokenOut: paramsSwap.tokenOut,
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteStoryHunt>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const amountIn = routeSwap?.inputAmount?.numerator?.toString();
	const amountOut = routeSwap?.outputAmount?.numerator?.toString();
	return {
		amountOut,
		amountIn,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative: BigInt(CHAIN_CONFIG[ChainId.STORY_TESTNET].minForGas),
		gasDisplay: '< 0.01$',
		dappInfo: {
			logo: '/icons/brands/storyhunt.png',
			domain: SwapProvider.STORY_HUNT,
		},
	};
};

export const useExecuteRouteStoryHunt = () => {
	const { mutateAsync } = useWrapUnWrapToken();
	const response = useMutation({
		mutationKey: ['exe-route-storyhunt'],
		mutationFn: async (routeData: SelectedRoute<RouteStoryHunt>) => {
			const { route, tokenIn, params, tokenOut } = routeData;
			if (isNativeToken(tokenIn.address)) {
				const [allowance, wrapBalance = 0n] = await Promise.all([
					getTokenAllowance({
						tokenContract: ADDRESSES.TOKENS.WIP.id,
						spenderContract: ADDRESSES.V3_SWAP_ROUTER_CONTRACT_ADDRESS,
						chainId: tokenIn.chainId,
					}),
					getEvmBalance(
						MpcWallet.getEvmWalletAddress(),
						tokenIn.chainId,
						getWrapNativeToken(tokenIn.chainId).address,
					),
				]);

				const amountIn = BigInt(params.amountIn);

				if (wrapBalance < amountIn) {
					console.log('start wrap token');
					const data = await mutateAsync({
						tokenIn,
						tokenOut: { ...tokenIn, address: ADDRESSES.TOKENS.WIP.id },
						amount: (amountIn - wrapBalance).toString(),
					});
					await data.wait();
				}

				if ((allowance || 0n) < amountIn) {
					console.log('start approve wip');
					const data = await approveErc20({
						amount: MaxUint256.toString(),
						spenderContract: ADDRESSES.V3_SWAP_ROUTER_CONTRACT_ADDRESS,
						tokenContract: ADDRESSES.TOKENS.WIP.id,
						chainId: tokenIn.chainId,
					});
				}
			}

			const response = await swapV3(route);
			if (response instanceof Error) {
				throw response;
			}
			return { hash: typeof response === 'string' ? response : response.hash };
		},
	});
	return response;
};

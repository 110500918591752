import CursorPagination, { usePagination } from '@/app-components/common/Pagination';
import { isSolanaChain } from '@/app-helpers/token';
import { useGetMemeTrades } from '@/app-hooks/api/memepad';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { colors } from '@/app-theme/theme';
import TradeItem from '@/app-views/tobi-fun/containers/TradeItem';
import { Box, Card, CardBody, Center, Divider, Text } from '@chakra-ui/react';
import { Fragment, useState } from 'react';

const pageSize = 4;
export default function Trades({ memeInfo: token }: { memeInfo: MemeTokenInfo | undefined }) {
	const addressQuery = isSolanaChain(token?.chainId) ? token?.address : '';
	const { data: tokens = [] } = useGetMemeTrades(addressQuery);
	const [page, setPage] = useState(1);
	const { onBack, onNext } = usePagination({ page, setPage, data: tokens });
	const start = (page - 1) * pageSize;

	if (!addressQuery) return null;
	return (
		<Box>
			<Box display="flex" marginY={3} justifyContent="space-between">
				<Text fontWeight={500}>Recent Trades</Text>
			</Box>
			<Card>
				<CardBody display={'flex'} flexDirection={'column'} gap={2} p={4}>
					{tokens?.slice(start, start + pageSize).map((el, i) => (
						<Fragment key={el.signature}>
							<TradeItem data={el} token={token} />
							{i !== tokens.length - 1 && (
								<Divider borderStyle={'dotted'} borderColor={colors.gray[300]} />
							)}
						</Fragment>
					))}
					{!tokens.length && <Center fontSize={'12px'}>No recent trades</Center>}
				</CardBody>
				<CursorPagination
					style={{ marginBottom: 3 }}
					totalCount={tokens?.length}
					pageSize={pageSize}
					currentPage={page}
					onNext={onNext}
					onBack={onBack}
				/>
			</Card>
		</Box>
	);
}

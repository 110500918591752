import { ServiceUser } from '@/app-cores/api/bot';
import { queryClient } from '@/app-cores/query-client';
import { TelegramCore } from '@/app-cores/telegram';
import { useWalletStore } from '@/app-store';
import { useMutation } from '@tanstack/react-query';

export const useMutationSetBackupType = () => {
	const { setWalletStatus } = useWalletStore();
	return useMutation({
		mutationKey: ['mutation-set-backup-type'],
		mutationFn: async (type: Parameters<typeof ServiceUser.setBackupType>[0]) => {
			const data = await ServiceUser.setBackupType(type);
			setWalletStatus('VALIDATED');
			try {
				await queryClient.invalidateQueries({
					queryKey: ['query-user-profile', TelegramCore.getUserId()],
				});
			} catch (error) {
				console.error('clear user profile error', error);
			}
			return data;
		},
	});
};

export const useMutationDeleteBackupType = () => {
	return useMutation({
		mutationKey: ['mutation-delete-backup-type'],
		mutationFn: async () => {
			return ServiceUser.deleteBackupType();
		},
	});
};

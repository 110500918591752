import { LocalLoader } from '@/app-components/common';
import BaseSelect from '@/app-components/common/BaseSelect';
import CursorPagination, { usePagination } from '@/app-components/common/Pagination';
import PageLayout from '@/app-components/layout/PageLayout';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import i18n from '@/app-cores/i18n';
import { TelegramCore } from '@/app-cores/telegram';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { toQueryString } from '@/app-helpers/url';
import { useGetListMemeByType } from '@/app-hooks/api/memepad';
import { ListMemeType } from '@/app-services/memepad/const';
import { MemePlatform } from '@/app-services/virtual/type';
import { useAppState } from '@/app-store/app';
import Header from '@/app-views/tobi-fun/containers/Header';
import { MemeTokenItem } from '@/app-views/tobi-fun/containers/MemeItem';
import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const pageSize = 12;
export default function TobiFun() {
	const [params] = useSearchParams();
	const { memePlatform } = useAppState();
	const isVirtual = memePlatform === MemePlatform.VIRTUALS_IO;
	const listType = params.get('filter') || ListMemeType.TRENDING;

	const [page, setPage] = useState(1);
	const { data, isFetching } = useGetListMemeByType(listType as ListMemeType, pageSize, page);
	const { onBack, onNext, reset } = usePagination({ page, setPage, data });

	const LIST_LABEL = {
		[ListMemeType.TRENDING]: i18n.t('memepad.trendingTokens'),
		[ListMemeType.NEW_CREATED]: i18n.t('memepad.justCreatedTokens'),
		[ListMemeType.LAST_TRADE]: i18n.t('memepad.lastTradeTokens'),
		[ListMemeType.TOP_MARKET_CAP]: i18n.t('memepad.topMarketCapTokens'),
		[ListMemeType.LAST_REPLY]: i18n.t('memepad.lastReplyTokens'),
	};

	const listOptions = (
		isVirtual
			? [ListMemeType.TRENDING, ListMemeType.TOP_MARKET_CAP, ListMemeType.NEW_CREATED]
			: Object.values(ListMemeType)
	).map((v) => ({ label: LIST_LABEL[v], value: v }));

	const tokensDebounce = data ?? [];

	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<PageLayout
			header={<Header platform={memePlatform} />}
			wrapperStyle={{
				marginTop: !TelegramCore.WebApp.isFullscreen ? '12px' : '0px',
			}}
		>
			<Flex direction={'column'} gap={3} pb={getFullScreenSpace(24)}>
				<Flex gap={2} justifyContent={'space-between'}>
					{/* {memePlatform === MemePlatform.PUMP_FUN && (
						<GradientButton onClick={() => navigate(NAVIGATE_PATHS.TobiFun.Create)}>
							<FiCreateTokenIcon style={{ marginRight: '4px' }} />
							<span className={'text-gradient'}> {t('memepad.launchToken')}</span>
						</GradientButton>
					)} */}
					<BaseSelect
						fontSize={'14px'}
						height={'36px'}
						value={listType}
						width={'fit-content'}
						background={'white'}
						borderRadius={'8px'}
						onChange={(e) => {
							reset();
							navigate(toQueryString(NAVIGATE_PATHS.TobiFun.Main, { filter: e.target.value }), {
								replace: true,
							});
						}}
					>
						{listOptions.map((el) => (
							<option value={el.value} key={el.value}>
								{el.label}
							</option>
						))}
					</BaseSelect>
				</Flex>

				{isFetching ? (
					<LocalLoader height="200px" />
				) : (
					tokensDebounce?.map((e) => <MemeTokenItem key={e.address} data={e} />)
				)}
				{!isFetching && (
					<CursorPagination
						style={{ marginBottom: 3 }}
						totalCount={pageSize * 10}
						pageSize={pageSize}
						currentPage={page}
						onNext={onNext}
						onBack={onBack}
					/>
				)}
			</Flex>
		</PageLayout>
	);
}

import { HomeIcon } from '@/app-components/layout/AppLayout/Header/HomeIcon';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getBlockChainNameByChainId } from '@/app-helpers/address';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { getNativeToken, getTokenInfo } from '@/app-helpers/token';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { Avatar, Box, Center, Container, Divider, Flex, List, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Block, Breadcrumb, CTAButton, TextSmall, TokenCard, TooltipInfo, UserAddress } from '..';
import { ContactListItem } from '../Contact/ContactListItem';

interface InfoItemProps {
	title: string;
	info1: React.ReactNode;
	info2?: React.ReactNode;
}
const InfoItem: React.FC<InfoItemProps> = ({ title, info1, info2 }) => {
	return (
		<Flex justifyContent="space-between" mt={4} gap={4}>
			<TextSmall m={0} color="gray.400">
				{title}
			</TextSmall>
			<Box>
				{info1}
				{info2}
			</Box>
		</Flex>
	);
};

export type Source = 'bot' | 'tobi';
interface ConfirmSendTransactionProps {
	feeUsd: string;
	feeToken: string;
	onConfirm: () => void;
	isLoading: boolean;
	isFetchingGasFee?: boolean;
	source?: Source;
}

export const ConfirmSendTransaction: React.FC<ConfirmSendTransactionProps> = ({
	feeToken,
	feeUsd,
	onConfirm,
	isLoading,
	isFetchingGasFee,
	source,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { contact, address } = useContactStore();
	const { token, amount, messages, usdAmount } = useSentTokenStore();
	const { priceUsd: tokenPriceUsd = 0, chainId, imageUrl, symbol } = getTokenInfo(token);

	const blockChainName = getBlockChainNameByChainId(chainId);

	return (
		<PageTransition>
			<PageNavigation
				onBack={() => {
					if (source === 'bot') {
						history.replaceState(null, null, '/');
						navigate(NAVIGATE_PATHS.Wallet.Sent.Sent);
					} else {
						navigate(-1);
					}
				}}
			>
				<Container className="pageContent">
					<Box className="pageFixed" pt={4} backgroundColor="#F9F9F9" pb={getFullScreenSpace(16, 100)}>
						<Center gap={1} justifyContent="start" px={4} mb={2}>
							<HomeIcon />
							<Breadcrumb flex={1} justifyContent="center" transform="translateX(-24px)">
								{t('cryptos.confirmation')}
							</Breadcrumb>
						</Center>

						<Box flexDirection="column" overflow="hidden" flexGrow={1} pb={5}>
							<Box overflow="auto" height="100%" px={4} className="hide-scrollbar">
								<Box backgroundColor="white" p={4} borderRadius={16}>
									<Block>
										<TextSmall mb={2}>{t('cryptos.token')}</TextSmall>
										<List>
											<TokenCard token={token} tokenPriceUsd={tokenPriceUsd} />
											<Divider my={3} />
											<TextSmall mb={0}>{t('cryptos.to')}</TextSmall>
											{contact ? (
												<ContactListItem contact={contact} blockChainName={blockChainName} />
											) : (
												<UserAddress
													address={address}
													_hover={{
														backgroundColor: 'transparent',
													}}
													px={0}
													cursor="default"
												/>
											)}
										</List>
									</Block>
									<Box as="hr" mt={4} />
									<InfoItem
										title={t('cryptos.total')}
										info1={
											<Text fontSize="sm" textAlign="right">
												{formatUsd(usdAmount)}
											</Text>
										}
										info2={
											<Flex alignItems="center" mt={1} gap={1}>
												<Avatar src={imageUrl} name={symbol} size="2xs" />
												<TextSmall as="span" color="gray.400" m={0}>
													{formatCurrency(amount)} {symbol}
												</TextSmall>
											</Flex>
										}
									/>
									<Box as="hr" my={4} />
									<InfoItem
										title={t('cryptos.network')}
										info1={
											<Center gap={1}>
												<Avatar
													src={CHAIN_CONFIG[chainId].logo}
													name={CHAIN_CONFIG[chainId].name}
													size="2xs"
												/>
												<Text fontSize="sm">{CHAIN_CONFIG[chainId].name}</Text>
											</Center>
										}
									/>
									<Box as="hr" my={4} />
									<InfoItem
										title={t('cryptos.estimatedGasFee')}
										info1={
											isFetchingGasFee ? (
												<Skeleton opacity="0.1" height={'21px'} width="160px" />
											) : (
												<Center gap={1}>
													<Text fontSize="sm">
														{feeUsd} ({feeToken} {getNativeToken(chainId)?.symbol})
													</Text>
													<TooltipInfo
														label={t('cryptos.estimatedTooltipInfo')}
													></TooltipInfo>
												</Center>
											)
										}
									/>
									<Box as="hr" my={4} />
									<InfoItem
										title={t('cryptos.note')}
										info1={
											<Text textAlign="right" fontSize="xs">
												{messages}
											</Text>
										}
									/>
								</Box>
							</Box>
						</Box>
						<Box px={4}>
							<CTAButton
								onClick={onConfirm}
								colorScheme="cyan"
								size="lg"
								fontWeight="medium"
								width="100%"
								isDisabled={!amount || isFetchingGasFee}
								isLoading={isLoading}
							>
								{t('button.confirm')}
							</CTAButton>
						</Box>
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};

import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { useGetAffiliateInfo } from '@/app-hooks/api/user/affiliate';
import { UserContent } from '@/app-views/affiliate/containers/Group/UserContent';
import { Container, Flex } from '@chakra-ui/react';
import { GroupsContent } from './containers/Group/GroupsContent';
import { TotalFee } from './containers/TotalFee';
import { Transactions } from './containers/Transaction';

const Affilate = () => {
	const { isFetching, data } = useGetAffiliateInfo();
	const props = {
		isFetching,
		data,
	};
	return (
		<PageNavigation>
			<Container
				className="overflow-y-auto hide-scrollbar"
				sx={{ gap: 6, display: 'flex', flexDirection: 'column' }}
				pb={getFullScreenSpace(80, 100)}
			>
				<Flex flexDirection="column" gap={3}>
					<Flex gap={3}>
						<TotalFee {...props} />
						<Transactions {...props} />
					</Flex>
				</Flex>
				<GroupsContent compact />
				<UserContent compact />
			</Container>
		</PageNavigation>
	);
};
export default Affilate;

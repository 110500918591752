import { ButtonPrimary, CopyToClipboard, TooltipInfo } from '@/app-components/common';
import { ChainId } from '@/app-constants/chains';
import { TxStatus, WalletActivity } from '@/app-cores/api/activities';
import { getShortAddress } from '@/app-helpers/address';
import { useRedirectToSwapConfirm } from '@/app-helpers/navigate';
import { getNativeTobiId } from '@/app-helpers/token';
import Sol2TcatSwap from '@/app-hooks/swap/sol_2_tcat';
import { useTransactionWatcherStore } from '@/app-store';
import { FiClockIcon, FiErrorIcon, FiSuccessIcon } from '@/assets/icons';
import { Box, Center, Spinner, Tag, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

interface ActivityItemHeaderProps {
	listData: WalletActivity[];
	data: WalletActivity;
}

export const MAP_COLOR_BY_TXS_STATUS = {
	[TxStatus.Pending]: {
		background: 'orange.8',
		color: 'orange.100',
		icon: Spinner,
	},
	[TxStatus.Success]: {
		background: 'green.100',
		color: 'green',
		icon: FiSuccessIcon,
		title: 'Success',
	},
	[TxStatus.Failed]: {
		background: 'red.100',
		color: 'red.200',
		icon: FiErrorIcon,
		title: 'Fail',
	},
	waiting: { background: 'gray.100', color: 'gray.300', icon: FiClockIcon },
};

export const Status = ({ status, title }: { status: TxStatus; title?: string }) => {
	const { background, color, title: defaultTitle } = MAP_COLOR_BY_TXS_STATUS[status] || {};
	return (
		<Tag
			{...{
				borderRadius: 'full',
				size: 'sm',
				textTransform: 'capitalize',
				background,
				color,
			}}
		>
			{defaultTitle || status}
		</Tag>
	);
};

export const ActivityItemHeader: React.FC<ActivityItemHeaderProps> = ({ listData, data }) => {
	const { metadata } = useTransactionWatcherStore();
	const { time, transactionHash: txHash, transactionStatus: status } = data;

	const showContinue = useMemo(() => {
		const metadataTxs = Object.values(metadata).find((e) => e.desHash === txHash);
		return metadataTxs && Sol2TcatSwap.isReceiveTon(data) && !Sol2TcatSwap.isCompletedTonToTcat(data, listData);
	}, [data, listData, metadata, txHash]);

	const metadataTxs = Object.values(metadata).find((e) => e.desHash === txHash);
	const [loading, setLoading] = useState(false);
	const navigate = useRedirectToSwapConfirm();
	const onClick = async () => {
		try {
			if (!showContinue || loading) return;
			setLoading(true);
			const amount = metadataTxs.amountOut?.toString();
			if (!amount) throw new Error('Invalid amount');
			await navigate({
				amount,
				fromToken: getNativeTobiId(ChainId.TON),
				toToken: metadataTxs?.tobiIdOut,
				fromChain: ChainId.TON,
				toChain: ChainId.TON,
			});
		} catch (error) {
			console.log('fetch route error', error);
		}
		setLoading(false);
	};
	return (
		<Center justifyContent="space-between">
			<Center gap={2}>
				{!!time && <Text fontSize="xs"> {dayjs(+time * 1000).format('MM-DD-YYYY HH:mm')}</Text>}
				{showContinue ? (
					<>
						<ButtonPrimary
							onClick={onClick}
							minW={'70px'}
							px={2}
							height={'20px'}
							size={'sm'}
							fontSize={'12px'}
						>
							{loading ? <Spinner boxSize={'12px'} /> : 'Continue'}
						</ButtonPrimary>
						<TooltipInfo
							label={`Leg 1 of the SOL to ${
								metadataTxs?.symbolOut ?? 'TCAT'
							} trade is complete. Please Confirm to continue Leg 2`}
						/>
					</>
				) : (
					<Status status={status as any} />
				)}
			</Center>
			<CopyToClipboard text={txHash}>
				<Box as="span" fontSize="smaller">
					{getShortAddress(txHash, {
						start: 4,
						end: 2,
					})}
				</Box>
			</CopyToClipboard>
		</Center>
	);
};

import { NAVIGATE_PATHS } from '@/app-constants/router';
import { RequestPayment } from '@/app-cores/api';
import { TelegramCore } from '@/app-cores/telegram';
import { useNavigateToSentPage } from '@/app-helpers/navigate';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { useTokenMaxBalancePortfolio } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { BASE_BACKGROUND_COLOR, BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { ActivityRenderer } from '@/app-views/wallet/components/Activities/ActivitiesRender';
import { FiClockIcon, FiDownLeftIcon, FiErrorIcon, FiSuccessIcon } from '@/assets/icons';
import { FiUserIcon } from '@/assets/icons/fi-user-icon';
import {
	Avatar,
	Box,
	Card,
	CardBody,
	CardFooter,
	Divider,
	Flex,
	HStack,
	Image,
	Spinner,
	Stack,
	Tag,
	Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import truncate from 'lodash/truncate';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
	ButtonPrimary,
	ButtonSecondary,
	CopyToClipboard,
	SpeedUpTransaction,
	TooltipInfo,
} from '../../../../app-components/common';
import { CHAIN_CONFIG } from '../../../../app-constants/chains';
import { IActivity, TxStatus } from '../../../../app-cores/api/activities';
import { getNativeToken, isEvmChain, isTonChain } from '../../../../app-helpers/token';
import { getExplorerLink } from '../../../../app-helpers/url';
import { getShortTxHash } from '../../../../app-helpers/web3';
import { useActivitiesState } from './state';

export const CardPanel = ({ msg, children }: { msg?: ReactNode; children?: ReactNode }) => {
	if (!msg && !children) return null;
	return (
		<Box
			px={4}
			py={3}
			background={BASE_BACKGROUND_COLOR}
			borderRadius={'8px'}
			display={'flex'}
			alignItems={'center'}
			justifyContent={'center'}
			lineHeight={'18px'}
			fontSize={'12px'}
		>
			{msg || children}
		</Box>
	);
};

export const MAP_COLOR_BY_TXS_STATUS = {
	[TxStatus.Pending]: {
		background: 'orange.8',
		color: 'orange.100',
		icon: Spinner,
	},
	[TxStatus.Success]: {
		background: 'green.100',
		color: 'green',
		icon: FiSuccessIcon,
	},
	[TxStatus.Failed]: {
		background: 'red.100',
		color: 'red.200',
		icon: FiErrorIcon,
	},
	waiting: { background: 'gray.100', color: 'gray.300', icon: FiClockIcon },
};

export const Status = ({ status, title }: { status: TxStatus; title?: string }) => {
	const { background, color } = MAP_COLOR_BY_TXS_STATUS[status] || {};
	return (
		<Tag
			{...{
				borderRadius: 'full',
				size: 'sm',
				textTransform: 'capitalize',
				background,
				color,
			}}
		>
			{title || status}
		</Tag>
	);
};

export const Activity: React.FC<{ data: IActivity; isPending?: boolean; listData: IActivity[] }> = ({
	data,
	isPending,
	listData,
}) => {
	const [openSpeedUp, setOpenSpeedUp] = useState(false);
	const activitiesState = useActivitiesState();
	const { t } = useTranslation();

	const showGasInformation = !isPending;
	const native = getNativeToken(data.chainId);

	const isSpecialRoute = false;
	const showContinue = false;

	// only for hardcode route of swap, will remove later
	const [loading, setLoading] = useState(false);

	const onClick = async () => {
		try {
		} catch (error) {
			console.log('fetch route error', error);
		}
		setLoading(false);
	};

	return (
		<Card my={3}>
			<CardBody p={'16px'}>
				<Flex gap={'12px'} flexDirection={'column'}>
					<Stack direction={'row'} justify={'space-between'} alignItems={'center'}>
						<Flex gap={2} fontSize={'xs'} align={'center'}>
							{dayjs(data.time * 1000).format('MM-DD-YYYY HH:mm')}
							{showContinue ? (
								<>
									<ButtonPrimary
										onClick={onClick}
										minW={'70px'}
										px={2}
										height={'20px'}
										size={'sm'}
										fontSize={'12px'}
									>
										{loading ? <Spinner boxSize={'12px'} /> : 'Continue'}
									</ButtonPrimary>
									<TooltipInfo label="Leg 1 of the SOL to TCAT trade is complete. Please Confirm to continue Leg 2" />
								</>
							) : (
								<>
									<Status status={data.status} />
									{isSpecialRoute && isPending && (
										<TooltipInfo label={t('tokenTrading.specialDirectRouteDesc')} />
									)}
								</>
							)}
						</Flex>
						{isPending && isTonChain(data.chainId) ? null : (
							<CopyToClipboard
								text={getExplorerLink(data.chainId, data.hash, 'transaction')}
								copyText="Copy transaction hash"
							>
								<Text display={'inline'} fontSize={'14px'}>
									{getShortTxHash(data.hash)}
								</Text>
							</CopyToClipboard>
						)}
					</Stack>

					<ActivityRenderer data={data} />
					{!!data.failReason && <CardPanel msg={data.failReason} />}
				</Flex>

				<Divider my={3} borderColor={BASE_BORDER_COLOR} />

				<Stack direction={'row'} gap={1} alignItems={'center'} fontSize={'sm'}>
					<Image width={'16px'} src={CHAIN_CONFIG[data.chainId]?.logo} />
					<Text flex={1} color={'black'} lineHeight={'18px'} fontSize={'xs'}>
						{CHAIN_CONFIG[data.chainId].name}
					</Text>
					{showGasInformation && data.gas && (
						<Text color={'gray.400'} fontSize={'xs'} textAlign={'right'}>
							Gas: {formatCurrency(data.gas)} {native?.symbol}{' '}
							{data.gasUsd ? `(${formatUsd(data.gasUsd)})` : null}
						</Text>
					)}
				</Stack>
			</CardBody>

			{isPending && isEvmChain(data.chainId) && (
				<Flex direction={'column'} px={'16px'} gap={3}>
					<CardPanel msg={t('activity.attemptInfo')} />
					<CardFooter pt={0} px={0} gap={2}>
						<ButtonSecondary
							width="100%"
							size="sm"
							colorScheme={'gray'}
							onClick={() => setOpenSpeedUp(true)}
						>
							Speed Up
						</ButtonSecondary>
						<ButtonSecondary
							width={'100%'}
							size={'sm'}
							onClick={() => activitiesState.cancelActivity(data.rawTransaction, data.transactionType)}
						>
							Cancel
						</ButtonSecondary>
					</CardFooter>
				</Flex>
			)}
			{openSpeedUp && (
				<SpeedUpTransaction
					isOpen={openSpeedUp}
					onClose={() => setOpenSpeedUp(false)}
					txHash={data.hash}
					transactionType={data.transactionType}
				/>
			)}
		</Card>
	);
};

export const RequestFundActivity: React.FC<{ data: RequestPayment }> = ({ data }) => {
	const { t } = useTranslation();
	const { message, payers = [], id, creatorId, creator } = data;
	const totalUnpaid = payers.filter((e) => !e.isPaid).length;
	const total = payers.reduce((rs, item) => rs + +item.amount, 0);
	const myInfo = payers.find((e) => e.telegramId === TelegramCore.getUserId().toString());
	const navigate = useNavigate();

	const isMyCreatedRequest = creatorId === TelegramCore.getUserId().toString();
	const showPayNow = !isMyCreatedRequest && myInfo && !myInfo?.isPaid;
	const isSuccess = isMyCreatedRequest ? totalUnpaid === 0 : !showPayNow;

	const authorHasName = creator.firstName || creator.lastName;
	const displayTotalAmount = isMyCreatedRequest ? total : myInfo?.amount;

	const navigateToSentPage = useNavigateToSentPage();
	const tokenMaxBalance = useTokenMaxBalancePortfolio();
	const onPay = () => {
		navigateToSentPage({ amountUsd: myInfo?.amount, token: tokenMaxBalance, teleId: creatorId });
	};

	return (
		<Card
			my={3}
			onClick={() => {
				navigate(NAVIGATE_PATHS.RequestFund.requestDetail(id));
			}}
		>
			<CardBody p={'16px'}>
				<Flex gap={'12px'} flexDirection={'column'}>
					<Stack direction={'row'} justify={'space-between'} alignItems={'center'}>
						<Flex gap={2} fontSize={'xs'}>
							{dayjs(data.createdAt).format('MM-DD-YYYY HH:mm')}
							<Status
								status={!isSuccess ? TxStatus.Pending : TxStatus.Success}
								title={
									!isSuccess
										? isMyCreatedRequest
											? `${totalUnpaid}/${payers.length} pending`
											: 'To Pay'
										: 'Completed'
								}
							/>
						</Flex>
					</Stack>

					<Flex justify={'space-between'}>
						<Flex align={'center'} gap={'10px'}>
							<Avatar
								bg={colors.cyan[400]}
								width={'32px'}
								height={'32px'}
								icon={<FiDownLeftIcon />}
								src={''}
							/>
							<Flex
								fontWeight={'600'}
								lineHeight={'22px'}
								fontSize={'sm'}
								as={'span'}
								flexWrap={'wrap'}
								whiteSpace={'nowrap'}
							>
								{t('requestPayment.title')}
							</Flex>
						</Flex>

						<Flex
							direction={'column'}
							justify={'center'}
							align={'flex-end'}
							onClick={(e) => e.stopPropagation()}
						>
							{!isMyCreatedRequest ? (
								<Flex
									justifyContent={'flex-end'}
									align={'center'}
									gap={1}
									fontSize={'14px'}
									color={colors.gray[400]}
									flexWrap={'wrap'}
								>
									from
									<Avatar src={creator.avatar} boxSize={'16px'} />
									<Text whiteSpace={'nowrap'}>
										{authorHasName &&
											truncate(`${creator.firstName} ${creator.lastName}`, {
												length: 14,
											})}{' '}
									</Text>
									{creator.username && (
										<CopyToClipboard
											text={creator.username || `${creator.firstName} ${creator.lastName}`}
											copyText="Copy telegram handle"
										>
											{`@${creator.username}`}
										</CopyToClipboard>
									)}
								</Flex>
							) : (
								<HStack fontSize={'12px'} color={colors.gray[400]} align={'flex-end'} spacing={1}>
									<FiUserIcon width={'16px'} />
									<Text fontWeight={'500'}>{payers.length}</Text>
								</HStack>
							)}
						</Flex>
					</Flex>

					<CardPanel
						msg={
							<Text fontWeight={'500'} color={colors.gray[400]}>
								{!isMyCreatedRequest ? 'Requested' : 'Total'}:{' '}
								<Text color={'black'} as="span">
									{formatUsd(displayTotalAmount)}
								</Text>
							</Text>
						}
					/>

					{message && <CardPanel msg={message} />}
					{showPayNow && (
						<ButtonPrimary
							size={'sm'}
							onClick={(e: any) => {
								e.stopPropagation?.();
								onPay();
							}}
						>
							{t('requestPayment.payNow')}
						</ButtonPrimary>
					)}
				</Flex>
			</CardBody>
		</Card>
	);
};

import { PumpComment, PumpFunSDK, PumpToken } from '@/app-services/pump.fun';
import VirtualService from '@/app-services/virtual';
import { MemeChat, MemePlatform, VirtualMessage, VirtualToken } from '@/app-services/virtual/type';

class MemePad {
	formatToken(token: PumpToken | VirtualToken, platform: MemePlatform) {
		const isVirtual = platform === MemePlatform.VIRTUALS_IO;
		if (isVirtual) return VirtualService.formatMemeToken((token ?? {}) as VirtualToken);
		return PumpFunSDK.formatToken((token ?? {}) as PumpToken);
	}
	formatChat(chat: PumpComment | VirtualMessage, platform: MemePlatform): MemeChat {
		const isVirtual = platform === MemePlatform.VIRTUALS_IO;
		if (!isVirtual) {
			const msg = chat as PumpComment;
			return {
				avatar: msg.profile_image,
				text: msg.text,
				timestamp: msg.timestamp,
				address: msg.user,
				id: msg?.id?.toString(),
			};
		} else {
			const msg = chat as VirtualMessage;
			return {
				avatar: '',
				text: msg.text,
				timestamp: new Date(msg.createdAt).getTime(),
				address: msg?.user?.walletAddress,
				id: msg?.id?.toString(),
			};
		}
	}
}

const MemePadService = new MemePad();
export default MemePadService;

import { ErrorViewCreateWallet, Loading } from '@/app-components/common';
import i18n from '@/app-cores/i18n';
import { TelegramCore } from '@/app-cores/telegram';
import { getDisplayedTobiIntro } from '@/app-helpers/display';
import { useQueryAppConfigs } from '@/app-hooks/api/configuration';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useMutationInitApp } from '@/app-hooks/app';
import { Box, Container } from '@chakra-ui/react';
import React, { Suspense, useEffect, useState } from 'react';
import { Maintenance } from '../wallet/components/Maintenance';
import { MainApp } from './MainApp';
import { WalletCreation } from './WalletCreation';

export const InitializeApp = React.memo(() => {
	const [initError, setInitError] = useState(false);
	const [initiated, setInitiated] = useState(false);
	const { isEnableMaintenance, isLoading: isLoadingMaintenance } = useQueryAppConfigs();
	const { data: userProfile, isLoading: isLoadingProfile, isError: isGetUserProfileError } = useQueryUserProfile();
	console.log('InitializeApp...');

	const { mutateAsync: initApp } = useMutationInitApp({
		onCompleted: () => {
			setInitiated(true);
		},
	});

	const whiteListMaintenance = i18n.t('appConfigs.whiteListMaintenance') || '';
	const isWhiteListMaintenance =
		whiteListMaintenance.includes(TelegramCore.getUserId().toString()) ||
		whiteListMaintenance.includes(TelegramCore.getUser()?.username);
	const isAppLoading = !initiated || isLoadingMaintenance || isLoadingProfile;

	useEffect(() => {
		async function getInitAppResult() {
			const success = await initApp();
			setInitError(!success);
		}
		getInitAppResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isAppLoading) {
		if (!getDisplayedTobiIntro())
			return (
				<Container overflow="hidden" p={0} width="100%" height="100vh" position="relative">
					<Box className="tobi-intro tobi-intro_item1"></Box>;
				</Container>
			);
		return <Loading />;
	}

	if (initError || isGetUserProfileError) return <ErrorViewCreateWallet />;

	if (isEnableMaintenance && !isLoadingMaintenance && !isWhiteListMaintenance) return <Maintenance />;
	return (
		// <AnimatePresence mode="wait">
		<Suspense fallback={<Loading />}>
			<MainApp />
			<WalletCreation userProfile={userProfile} />
		</Suspense>
		// </AnimatePresence>
	);
});

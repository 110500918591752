import { CHAIN_CONFIG } from '@/app-constants/chains';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { FiRightIcon } from '@/assets/icons';
import {
	Avatar,
	Button,
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CodeBlock } from 'react-code-blocks';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { useProposalAction } from '../hooks';
import { useModalStore } from '../store/ModalStore';
import { botConnectorHandler } from '../utils/bot-connector.handler';
import { getChainId, getSignTypedDataParamsData } from '../utils/helper.util';

export default function SessionSignTypedDataModal() {
	const { data, open, onClose } = useModalStore();
	const { onApprove, isPendingApprove, isPendingReject, onReject } = useProposalAction(data);
	const metadata = botConnectorHandler.getClient(data.clientId)?.metadata;
	const [showDetail, setShowDetail] = useState(false);

	if (data.payload.method !== 'eth_signTypedData_v4') {
		// Just for typing
		return <></>;
	}

	const transaction = getSignTypedDataParamsData(data.payload.data);
	const chainID = getChainId(data.payload.chainId);

	return (
		<Drawer
			isOpen={true || open}
			placement="bottom"
			onClose={onClose}
			size="full"
			trapFocus={false}
			autoFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent pt={getFullScreenSpace(0, 76)}>
				<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Signature request
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody className="hide-scrollbar">
					<Flex flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Center border="1px solid rgba(0, 0, 0, 0.08)" gap={2} borderRadius={52} px={2} py={1}>
							<Avatar src={metadata.icons[0]} name={metadata.name} width={8} height={8} />
							<Text fontSize="sm">{metadata.name}</Text>
						</Center>
						<Text textAlign={'center'} fontSize={'24px'} lineHeight={'30px'}>
							You are signing
						</Text>
						<Card padding={'16px'} width={'100%'} background={'gray.100'} gap={'8px'}>
							<Flex justifyContent={'space-between'} flexDirection="column">
								<TransactionItemInfo title="Network" value={CHAIN_CONFIG[chainID]?.name} />
								<Divider mb={4} />
								<Button
									onClick={() => setShowDetail(!showDetail)}
									variant="outline"
									size="xs"
									border="none"
									_hover={{
										backgroundColor: 'transparent',
									}}
								>
									View full transaction details <FiRightIcon />
								</Button>
								{showDetail && (
									<CodeBlock
										customStyle={{
											fontSize: '12px',
										}}
										showLineNumbers={false}
										text={JSON.stringify(transaction, null, 2)}
										language="json"
									/>
								)}
							</Flex>
						</Card>
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={onReject}
					>
						{isPendingReject ? <Spinner size="sm" /> : 'Deny'}
					</Button>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={onApprove}
					>
						{isPendingApprove ? <Spinner size="sm" color="white" /> : 'Approve'}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}

import { ChainId, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { compareAddress, isNativeToken } from '@/app-helpers/address';
import { getChainIdByTobiChainName, getSketetonToken, getTobiChainName, getTokenInfo } from '@/app-helpers/token';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { getOnChainTokenData } from '@/app-hooks/wallet/useWalletBalance';
import { HiddenToken } from '@/app-store/balance';
import { BlockIPInfo, ITokenSearch } from './types';

// chainId is real chain id, not tobi chain id
export type QueryTokenParam = { tobiId?: string; chainId?: string | ChainId; address?: string };
export type BalanceResponse = {
	balances: ITokenSearch[];
	notFoundTokens: { tobiId: any; chainId: string; amount: string; idTobi: string }[];
};

class BffService {
	cachedToken: Record<string, ITokenSearch> = {};
	lastUpdate = Date.now();

	async getPortfolioBalance(forceRefresh?: boolean): Promise<BalanceResponse> {
		const response = await axiosBotAPI.post('v2/wallet/portfolio/balance', {
			isRefresh: forceRefresh,
		});

		if (forceRefresh || Date.now() - this.lastUpdate > ONE_MINUTE) {
			this.cachedToken = {};
			this.lastUpdate = Date.now();
		}

		type TokenBalance = { amount: string; chainId: string; idTobi: string; address?: string };
		const balances: TokenBalance[] = response.data.data?.filter((e) => e.amount && e.amount !== '0') ?? [];

		const formattedBalances = balances.map((e) => ({
			...e,
			tobiId: e.idTobi,
		}));

		const getKey = (e) => e.tobiId || e.idTobi;

		const query = formattedBalances.filter((e) => !this.cachedToken[getKey(e)]).map((e) => ({ tobiId: e.tobiId }));

		const findTokens = await this.searchExactListTokens({ query });
		findTokens.forEach((e) => {
			this.cachedToken[getKey(getTokenInfo(e))] = e;
		});

		const notFoundTokens: TokenBalance[] = [];
		const result = formattedBalances
			.map((e) => {
				const tokenInfo = this.cachedToken[getKey(e)];
				if (tokenInfo) return { ...e, ...tokenInfo, balance: e?.amount || '0' };
				notFoundTokens.push(e);
			})
			.filter(Boolean);

		const mapByChain = notFoundTokens.reduce((acc, e) => {
			if (!e.address) return acc;
			const chainId = getChainIdByTobiChainName(e.chainId);
			acc[chainId] = acc[chainId] || [];
			acc[chainId].push(e);
			return acc;
		}, {} as Record<ChainId, TokenBalance[]>);

		const tokensOnChain = await Promise.allSettled(
			Object.entries(mapByChain).map(([chainId, tokens]) => {
				return getOnChainTokenData(chainId as ChainId, tokens.map((e) => e.address).filter(Boolean));
			}),
		);

		const onChainInfo = tokensOnChain.map((e) => (e.status === 'fulfilled' ? e.value : [])).flat();

		return {
			balances: result,
			notFoundTokens: notFoundTokens.map((e) => {
				const info: any = onChainInfo.find((t) => compareAddress(t.address, e.address));
				return info ? { ...e, ...info, isScam: true } : e;
			}),
		};
	}

	async getPortfolioBalanceByType(type: 'hidden' | 'show'): Promise<Array<HiddenToken>> {
		let response;
		if (type === 'show') response = await axiosBotAPI.get('/v2/wallet/portfolio/preference-tokens?action=show');
		else response = await axiosBotAPI.post('/v2/wallet/portfolio/filtered-balance', { type });
		return (
			response.data?.data?.map((e) => ({
				...e,
				tobiId: e.tobiId || e.idTobi,
				chainId: getChainIdByTobiChainName(e.chainId),
				tobiChainId: e.chainId,
			})) ?? []
		);
	}

	async getTokenDetail(payload: QueryTokenParam): Promise<ITokenSearch> {
		const { address, chainId } = payload;
		if (payload.address) payload.address = isNativeToken(payload.address) ? NATIVE_TOKEN_ADDRESS : payload.address;
		const response = await axiosBotAPI.get(`v2/token/detail`, {
			params: payload,
		});
		const result = response.data?.data ?? null;
		if (address && chainId && !result) {
			const data = await getOnChainTokenData(chainId as ChainId, [address]);
			if (data?.length) return getSketetonToken({ ...data[0], isScam: true });
		}
		return result;
	}
	async searchTokens({
		chainId,
		chainIds,
		...payload
	}: {
		query: string;
		limit: number;
		chainId?: ChainId | string;
		chainIds?: ChainId[];
	}): Promise<ITokenSearch[]> {
		const params: Record<string, any> = { ...payload, chainNameOnTobi: getTobiChainName(chainId) };
		if (!chainId && chainIds?.length) {
			params.chainNameOnTobi = chainIds.map((e) => getTobiChainName(e));
		}
		const response = await axiosBotAPI.get(`v2/token/search`, { params });
		return response.data?.data ?? [];
	}

	async searchExactListTokens({ query }: { query: QueryTokenParam[] }): Promise<Array<ITokenSearch>> {
		if (!query.length) return [];
		const formatQuery = query
			.map((e) => ({ ...e, chainId: getTobiChainName(e.chainId) || e.chainId }))
			.filter((e) => e.tobiId || e.address);

		if (query.length === 1 && query?.[0]?.address) {
			// only 1 usecase for now
			const data = await getOnChainTokenData(query?.[0]?.chainId as ChainId, [query?.[0]?.address]);
			return [getSketetonToken(data[0])];
		}
		const response = await axiosBotAPI.post(`v2/token/details`, { tokens: formatQuery });
		const findTokens = response.data?.data?.filter(Boolean) ?? [];
		return findTokens;
	}
	async searchListTokensByAddress({ query }: { query: QueryTokenParam[] }): Promise<Array<ITokenSearch>> {
		if (!query.length) return [];
		const formatQuery = query.map((e, i) => ({ ...e, chainId: getTobiChainName(e.chainId) || e.chainId }));
		const { data } = await axiosBotAPI.post(`v2/token/search-from-gecko-terminal`, { tokens: formatQuery });
		return data;
	}

	async searchExactSingleToken(payload: QueryTokenParam): Promise<ITokenSearch> {
		const { chainId, address } = payload;
		payload.chainId = getTobiChainName(chainId) || chainId;
		const tokens = await this.searchExactListTokens({ query: [payload] });
		const result = tokens?.[0] ?? null;
		if (address && chainId && !result) {
			const data = await getOnChainTokenData(chainId as ChainId, [address]);
			if (data?.length) return getSketetonToken(data[0]);
		}
		return result;
	}

	async getTrendingTokens(): Promise<ITokenSearch[]> {
		const response = await axiosBotAPI.get(`/v2/token/trending`);
		return response.data.data;
	}

	async getPortfolioChart(): Promise<Array<{ timestamp: number; totalUsd: number }>> {
		const response = await axiosBotAPI.get(`/api/v1/balances/me/histories`);
		return response.data?.data?.balances?.reverse();
	}

	async getBlockIPInfo(): Promise<BlockIPInfo> {
		const response = await axiosBotAPI.get(`/trading/ips/me`);
		return response.data;
	}

	async getJupiterAta(tokenAddress: string): Promise<string> {
		const response = await axiosBotAPI.post(`/trading/solana/ata`, { tokenAddress }, { timeout: 5000 });
		return response.data?.data?.ataAddress;
	}
}

export const BffServiceAPI = new BffService();

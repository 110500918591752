import { ButtonPrimary, Tooltip, TooltipInfo } from '@/app-components/common';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import TobiTabs from '@/app-components/common/TobiTabs';
import { ChainId } from '@/app-constants/chains';
import { isSolanaChain } from '@/app-helpers/token';
import { useUserSettingsStore } from '@/app-store/settings';
import { colors } from '@/app-theme/theme';
import { SolanaFeeType } from '@/app-types';
import SlippageSetting from '@/app-views/swap/components/SlippageSetting';
import { FiInfoIcon, FiSettingIcon, FiShareIcon } from '@/assets/icons';
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Text,
	VStack,
} from '@chakra-ui/react';
import { useState, CSSProperties } from 'react';

import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const tabsOption = Object.values(SolanaFeeType).map((item) => ({ label: item, value: item }));
const GasSettingSolana = ({ chainId }: { chainId?: ChainId | string }) => {
	const { t } = useTranslation();
	const { solanaGasFeeType, setSolanaGasFeeType } = useUserSettingsStore();

	if (!isSolanaChain(chainId)) return null;
	return (
		<VStack w={'100%'} spacing={'12px'} align={'flex-start'}>
			<Tooltip label={t('cryptos.gasOptionsDesc')}>
				<Flex fontSize={'12px'} alignItems={'center'} gap={'4px'}>
					{t('cryptos.gasOptions')} <FiInfoIcon />
				</Flex>
			</Tooltip>
			<TobiTabs<SolanaFeeType>
				tabs={tabsOption}
				onChange={setSolanaGasFeeType}
				value={solanaGasFeeType}
				containerStyle={{ width: '100%' }}
				activeStyle={{
					bg: colors.cyan[400],
				}}
			/>
			;
		</VStack>
	);
};

export const SwapSetting = ({ style, chainId }: { style?: CSSProperties; chainId?: ChainId | string }) => {
	const [open, setOpen] = useState(false);

	const { slippage: slippageGlobal, setSlippage: saveSlippageGlobal } = useUserSettingsStore();
	const [slippage, setSlippage] = useState<string | number>(slippageGlobal);

	return (
		<ActionBlock style={style}>
			<FiSettingIcon
				fill={style?.color}
				style={{ minWidth: 20, height: 20, cursor: 'pointer' }}
				onClick={() => {
					setSlippage(slippageGlobal);
					setOpen(true);
				}}
			/>
			<Drawer isOpen={open} placement="bottom" onClose={() => setOpen(false)} trapFocus={false}>
				<DrawerOverlay />
				{open && (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth="1px">Settings</DrawerHeader>

						<DrawerBody px={4}>
							<Flex flexDirection={'column'} gap={'14px'} pt={2}>
								<SlippageSetting onChange={setSlippage} value={slippage} />

								<GasSettingSolana chainId={chainId} />

								<ButtonPrimary
									mt={2}
									mb={isIOS ? 5 : 0}
									disabled={slippage === ''}
									width={'100%'}
									onClick={() => {
										setOpen(false);
										saveSlippageGlobal(slippage as number);
									}}
								>
									Save
								</ButtonPrimary>
							</Flex>
						</DrawerBody>
					</DrawerContent>
				)}
			</Drawer>
		</ActionBlock>
	);
};

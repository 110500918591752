import { fetchMemeChart, useGetMemeChart } from '@/app-hooks/api/memepad';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { CHART_CONFIG_DEFAULT, ChartInterval, ChartIntervalNumber, useUserSettingsStore } from '@/app-store/settings';
import { PriceChartWithSettings, formatDataChart } from '@/app-views/portfolio/pages/token/components/PriceChart';
import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const PriceChart: React.FunctionComponent<{ enabled: boolean; memeInfo: MemeTokenInfo | undefined }> = ({
	memeInfo,
	enabled,
}) => {
	const { chartConfig: { mode } = CHART_CONFIG_DEFAULT } = useUserSettingsStore();

	const [chartInterval, setChartInterval] = useState(ChartInterval.ONE_MIN);
	const { address, id, platform } = memeInfo ?? {};

	const payload = useMemo(() => {
		return { address, timeframe: ChartIntervalNumber[chartInterval], offset: 0, limit: 1000, id, platform };
	}, [chartInterval, address, id, platform]);

	const { data, isLoading } = useGetMemeChart(payload, enabled);

	const ref = useRef(payload);
	useEffect(() => {
		ref.current = payload;
	}, [payload]);

	const fetching = useRef(false);
	const onFetchMore = useCallback(async () => {
		if (fetching.current) return;
		try {
			fetching.current = true;
			const newPayload = { ...ref.current, offset: ref.current.offset + ref.current.limit };
			const data = await fetchMemeChart(newPayload);
			ref.current = newPayload;
			return formatDataChart(mode, data);
		} catch (error) {
			return [];
		} finally {
			fetching.current = false;
		}
	}, [mode]);

	return (
		<PriceChartWithSettings
			{...{
				onFetchMore,
				isLoading,
				data,
				onChangeInterval: setChartInterval,
				chartInterval,
				defaultInterval: ChartInterval.ONE_MIN,
				sortInterval: true,
			}}
		/>
	);
};

export default function ChartPopup({
	children,
	memeInfo,
}: {
	memeInfo: MemeTokenInfo | undefined;
	children: React.ReactNode;
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	// todo completed tokens
	return (
		<>
			<span onClick={onOpen}>{children}</span>
			<Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Token Chart</DrawerHeader>

					<DrawerBody px={0}>
						<PriceChart enabled={isOpen} memeInfo={memeInfo} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Box,
	Button,
	Center,
	Checkbox,
	Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { FiRestoreOutlineIcon } from '@/assets/icons';

interface AlertRecreateWalletProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AlertRecreateWallet: React.FC<AlertRecreateWalletProps> = ({ isOpen, onClose }) => {
	const navigate = useNavigate();
	const cancelRef = React.useRef();
	const { t } = useTranslation();
	const [isConfirm, setIsConfirm] = useState(false);
	return (
		<AlertDialog
			size="xs"
			isCentered
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => onClose()}
			autoFocus={false}
			trapFocus={false}
			closeOnEsc={true}
			closeOnOverlayClick={true}
		>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius="1.5rem">
					<AlertDialogBody py={10} px={6}>
						<Center flexDirection="column" textAlign="center">
							<FiRestoreOutlineIcon />
							<Text mt={4} fontSize="1.25rem">
								{t('resetWallet.title')}
							</Text>

							<Text mt={3} fontSize="xs">
								{t('resetWallet.descriptionLine1')}
								<br />
								{t('resetWallet.descriptionLine2')}
							</Text>
						</Center>
						<Box mt={4}>
							<Checkbox
								colorScheme="cyan"
								value="1"
								onChange={(e) => {
									setIsConfirm(e.target.checked);
								}}
								display="flex"
								alignItems="start"
							>
								<Text transform="translateY(-2px)" fontSize="12px" fontWeight={500}>
									{t('resetWallet.confirm')}
								</Text>
							</Checkbox>

							<Button
								isDisabled={!isConfirm}
								mt={2}
								size="lg"
								fontWeight="medium"
								colorScheme="cyan"
								width="100%"
								onClick={() => {
									if (!isConfirm) return;
									navigate(`${NAVIGATE_PATHS.CreateWallet.Onboarding}?initView=Keygen`);
								}}
							>
								{t('button.updateNow')}
							</Button>
						</Box>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

import { InputMode } from '@/app-constants';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { getTokenInfo } from '@/app-helpers/token';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const initializeData = {
	amount: '',
	usdAmount: '',
	messages: '',
	token: null,
	tokenInfo: null,
	isInsufficient: false,
	isInsufficientGas: false,
	gasFee: '0',
	inputMode: InputMode.USD,
};
export interface ISentCryptoStore {
	amount: string;
	inputMode: InputMode;
	messages: string;
	token: ITokenSearch;
	tokenInfo: TokenInfo;
	isInsufficient: boolean;
	isInsufficientGas: boolean;
	gasFee: string;
	usdAmount: string;
	setInputMode: (inputMode: InputMode) => void;
	setUsdAmount: (amount: string) => void;
	setAmount: (amount: string) => void;
	setMessage: (message: string) => void;
	setTokenSent: (token: ITokenSearch) => void;
	setIsInsufficient: (isInsufficient: boolean) => void;
	setIsInsufficientGas: (isInsufficientGas: boolean) => void;
	setGasFee: (gas: string) => void;
	reset: () => void;
}

export const useSentTokenStore = create<ISentCryptoStore>()(
	persist(
		(set) => ({
			...initializeData,
			setAmount: (amount) => set({ amount }),
			setInputMode: (inputMode) => set({ inputMode }),
			setUsdAmount: (usdAmount) => set({ usdAmount }),
			setMessage: (mes) => set({ messages: mes }),
			setTokenSent: (tk) =>
				set((state) => {
					const tokenInfo = getTokenInfo(tk);
					const newState: Partial<ISentCryptoStore> = { token: tk, tokenInfo };
					if (!tokenInfo.priceUsd) {
						newState.inputMode = InputMode.AMOUNT;
					}
					return newState;
				}),
			setIsInsufficient: (isInsufficient) => set({ isInsufficient: isInsufficient }),
			setIsInsufficientGas: (isInsufficientGas) => set({ isInsufficientGas: isInsufficientGas }),
			setGasFee: (gas) => set({ gasFee: gas }),
			reset: () =>
				set((state) => ({
					...state,
					...initializeData,
				})),
		}),
		{
			name: 'sent-token-storage',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);

import {
	BaseInput,
	Breadcrumb,
	CTAButton,
	EstimateGasFee,
	GasSetting,
	SelectContact,
	useEstimateFeeSentToken,
} from '@/app-components/common';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import { EstimateEvmGasFee } from '@/app-components/common/EstimateGasFee/EstimateEvmGasFee';
import SelectTokenWithLabel from '@/app-components/common/SelectToken';
import { TokenAmountInput } from '@/app-components/common/SelectToken/TokenAmount';
import { SearchMode } from '@/app-components/common/crypto-search';
import { HomeIcon } from '@/app-components/layout/AppLayout/Header/HomeIcon';
import PageLayout from '@/app-components/layout/PageLayout';
import { InputMode } from '@/app-constants';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ITokenSearch } from '@/app-cores/api/bff';
import { TelegramCore } from '@/app-cores/telegram';
import { compareTobiToken, getBlockChainNameByAddress, getBlockChainNameByChainId } from '@/app-helpers/address';
import { countDecimals } from '@/app-helpers/number';
import {
	getAmountCanSend,
	getTokenInfo,
	isEclipseChain,
	isEvmChain,
	isSolanaChain,
	isSuiChain,
	isTonChain,
	isTronChain,
	tokenHasBalance,
} from '@/app-helpers/token';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useDebounce } from '@/app-hooks/common';
import { AmountPayload } from '@/app-hooks/swap';
import { calcAmount } from '@/app-hooks/swap/helper';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { GasWarning } from '@/app-views/wallet/components/Portfolio/GasWarning';
import { FiSettingIcon } from '@/assets/icons';
import { Box, Center, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { formatUnits } from 'ethers';
import { isEmpty } from 'lodash-es';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useInitSendData } from './hooks/useInitSendData';

const InputGroup = ({ children }: { children: ReactNode }) => {
	return (
		<Flex flexDirection={'column'} gap={'10px'}>
			{children}
		</Flex>
	);
};
export const DEFAULT_TON_FEE = 0.0036;
export const DEFAULT_SOL_FEE = 0.000005;
export const DEFAULT_SUI_FEE = 0.0025;
export const DEFAULT_ECLIPSE_FEE = 0.00000006;

export const SentToken = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const {
		setTokenSent,
		setAmount,
		setMessage,
		messages,
		token,
		amount,
		usdAmount,
		setUsdAmount,
		isInsufficient,
		setIsInsufficient,
		isInsufficientGas,
		gasFee,
		setGasFee,
		tokenInfo,
		setInputMode,
		inputMode,
	} = useSentTokenStore();

	useInitSendData();
	const { priceUsd: tokenPriceUsd = 0, chainId, address: tokenAddress, decimals } = tokenInfo ?? {};

	const { contact, address, setContact, setAddress, setSearchKey } = useContactStore();
	const isValidAddress = !!contact?.defaultWallet?.address || !!address;

	const isValid = +amount > 0 && !isInsufficient && isValidAddress && !isInsufficientGas;
	const showEstimateGasFee = !!tokenAddress;
	const isEvm = isEvmChain(chainId);
	const isTon = isTonChain(chainId);
	const isSol = isSolanaChain(chainId);
	const isTron = isTronChain(chainId);
	const isSui = isSuiChain(chainId);
	const isEclipse = isEclipseChain(chainId);

	const { data: nativePriceUsd = 0 } = usePriceNativeToken({
		chainId,
	});

	useEffect(() => {
		if (isEmpty(token)) return;
		const isInsufficient =
			!!amount && (!tokenHasBalance(token) || +amount > +formatUnits(token.balance ?? '0', decimals));
		setIsInsufficient(isInsufficient);
	}, [token, amount, setIsInsufficient, decimals]);

	const onSetAmount = (payload: AmountPayload) => {
		const { amount, amountUsd } = calcAmount(payload);
		setAmount(amount);
		setUsdAmount(amountUsd);
	};

	const onChangeAmountInput = (value: string) => {
		onSetAmount(inputMode === InputMode.AMOUNT ? { token, amount: value } : { token, amountUsd: value });
	};

	const handleSwitch = () => {
		setInputMode(inputMode === InputMode.AMOUNT ? InputMode.USD : InputMode.AMOUNT);
	};

	const onSelectToken = (tk: ITokenSearch) => {
		const { chainId: newChain, priceUsd } = getTokenInfo(tk);
		if (token && (!compareTobiToken(token, tk) || chainId || newChain)) {
			onSetAmount({ token: tk, amount: '' });
		}
		setGasFee('0');
		setTokenSent(tk);
		const blockChainName = getBlockChainNameByChainId(newChain);
		if (getBlockChainNameByAddress(address) !== blockChainName) {
			setSearchKey('');
		}
		if (address && getBlockChainNameByAddress(address) !== blockChainName) {
			setAddress('');
		}
		if (!contact?.wallets?.[blockChainName]?.address) {
			setContact(null);
		}
	};

	const getDefaultFee = () => {
		if (isTon) return DEFAULT_TON_FEE;
		if (isSol) return DEFAULT_SOL_FEE;
		if (isSui) return DEFAULT_SUI_FEE;
		if (isEclipse) return DEFAULT_ECLIPSE_FEE;
		return +gasFee;
	};
	const onMax = () => {
		if (tokenHasBalance(token)) {
			const defaultFee = getDefaultFee();
			onSetAmount({
				amount: getAmountCanSend(tokenInfo, defaultFee),
				token,
				formatUsd: true,
			});
		}
	};

	const isTokenAmountMode = inputMode === InputMode.AMOUNT;
	const amountInfo = {
		amount: isTokenAmountMode ? amount : usdAmount,
		usdValue: isTokenAmountMode ? usdAmount : amount,
	};

	const amountDebounced = useDebounce(amount, 400);

	const allowEdit = !isEmpty(tokenAddress) && !!amount && isEvm;
	const { estimateGasFee, showGasWarning, feeWithNativeToken, isGasSmallest } = useEstimateFeeSentToken();
	const { isOpen, onClose, onOpen } = useDisclosure();
	return (
		<PageLayout
			header={
				<Center gap={1} justifyContent="start" px={4} mt={!TelegramCore.WebApp.isFullscreen ? '12px' : '0px'}>
					<HomeIcon />
					<Breadcrumb flex={1} px={4}>
						<Text>{t('cryptos.sendToken')}</Text>
					</Breadcrumb>
					<ActionBlock
						onClick={() => {
							allowEdit && onOpen();
						}}
						cursor={allowEdit ? 'pointer' : 'not-allowed'}
						opacity={allowEdit ? 1 : 0.5}
					>
						<Center width={8} height={8}>
							<FiSettingIcon width={24} height={24} />
						</Center>
					</ActionBlock>
				</Center>
			}
			footer={
				<Box px={4} my={4}>
					<CTAButton
						onClick={() => {
							navigate(`${NAVIGATE_PATHS.Wallet.Sent.ConfirmTransaction}`);
						}}
						width="100%"
						size="lg"
						colorScheme={isValid ? 'cyan' : 'gray'}
						isDisabled={!isValid || isInsufficientGas || (gasFee === '0' && !isTron)}
					>
						{t('button.continue')}
					</CTAButton>
				</Box>
			}
		>
			<Flex flexDirection={'column'} gap={'20px'} mt={2}>
				<SelectTokenWithLabel
					label={'Select Token'}
					searchMode={SearchMode.MY_BALANCE}
					token={token}
					onSelectToken={onSelectToken}
					chainStyle={{
						width: '12px',
						height: '12px',
					}}
					autoFocus={false}
				/>
				<Flex flexDirection={'column'}>
					<TokenAmountInput
						token={token}
						value={amountInfo.amount?.toString() ?? ''}
						usdValue={amountInfo.usdValue}
						usdPrice={tokenPriceUsd}
						onValueChange={({ value }, source) => {
							if (source?.source === 'prop') return;
							onChangeAmountInput(value);
						}}
						onMax={onMax}
						inputMode={inputMode}
						toggleInputMode={handleSwitch}
						inputStyle={{
							color:
								amountDebounced && countDecimals(amountDebounced) > tokenInfo?.decimals
									? 'red'
									: undefined,
						}}
					/>
					<Flex fontSize="xs" color="red" height={4}>
						{isInsufficient ? t('errors.insufficientToken') : ''}
					</Flex>
				</Flex>

				<InputGroup>
					<Text fontSize="xs" fontWeight={500}>
						{t('cryptos.sendTo')}
					</Text>
					<SelectContact />
				</InputGroup>

				<InputGroup>
					<Text fontSize="xs" fontWeight={500}>
						{t('cryptos.note')}
					</Text>
					<BaseInput
						padding={'12px 16px'}
						height={'56px'}
						backgroundColor="gray.100"
						borderRadius={12}
						fontSize="sm"
						fontWeight={500}
						color="gray.400"
						size="sm"
						onChange={(e) => setMessage(e.target.value)}
						placeholder={`${t('cryptos.note')}...`}
						value={messages}
						spellCheck={true}
						border="none"
						_focus={{ boxShadow: 'none' }}
						onBlur={() => window.scrollTo(0, 0)}
					/>
					{isTon && (
						<Box fontSize="xs" fontWeight={500} color="rgb(245, 167, 59)">
							{t('cryptos.warningMemoNote')}
						</Box>
					)}
				</InputGroup>
				{showEstimateGasFee && isEvm && (
					<>
						<Divider variant={'dashed'} />
						<EstimateEvmGasFee
							onClick={onOpen}
							feePriceUsd={nativePriceUsd}
							feeWithNativeToken={feeWithNativeToken}
							estimateGasFee={estimateGasFee}
							isGasSmallest={isGasSmallest}
						/>

						<GasSetting
							open={isOpen}
							estimateGasFee={estimateGasFee}
							token={token}
							onClose={onClose}
							feePriceUsd={nativePriceUsd}
							isGasSmallest={isGasSmallest}
						/>
					</>
				)}
				{showGasWarning && <GasWarning chainId={chainId} callbackUrl={NAVIGATE_PATHS.Wallet.Sent.Sent} />}
				<EstimateGasFee />
			</Flex>
		</PageLayout>
	);
};

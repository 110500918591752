import { ChainId, VIRTUAL_TOKEN_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { compareAddress, compareChain } from '@/app-helpers/address';
import { uniqueId } from '@/app-helpers/random';
import { getNativeToken } from '@/app-helpers/token';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';
import VirtualService from '@/app-services/virtual';
import { useTransactionWatcherStore } from '@/app-store';
import { SelectedRoute } from '@/app-store/swap';
import { TransactionType } from '@/app-types';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { useMutation } from '@tanstack/react-query';
import { ethers } from 'ethers';

export type RouteVirtual = {
	fee: bigint;
	amountOut: string;
	amountIn: string;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const formatRoute = (routeData: RouteVirtual, params: ArgsGetRoute): SelectedRoute =>
	routeData
		? {
				routerAddress: VirtualService.readContractAddress,
				tokenIn: params.tokenIn,
				tokenOut: params.tokenOut,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.VIRTUAL,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteVirtual>,
	{ usdPriceIn, usdPriceNative }: UsdRouteInfo,
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const gasNative = routeSwap ? routeSwap?.fee : 0n;
	const native = getNativeToken(tokenIn?.chainId);
	const gasUsd = usdPriceNative ? +ethers.formatUnits(gasNative, native?.decimals) * usdPriceNative : undefined;
	return {
		amountOut: routeSwap?.amountOut,
		amountIn: routeSwap?.amountIn,
		tokenIn,
		tokenOut,
		gasNative,
		gasUsd,
		dappInfo: {
			logo: '/icons/brands/virtual.svg',
			domain: SwapProvider.VIRTUAL,
		},
	};
};

class Virtual extends SwapAbstract<RouteVirtual> {
	provider = SwapProvider.VIRTUAL;
	extractRoute(params: SelectedRoute<RouteVirtual>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}

	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 500 : (+slippage * 100) | 0;
	}

	async getRoute(paramsSwap: ArgsGetRoute) {
		const { tokenIn, amountIn, tokenOut } = paramsSwap;
		if (
			!compareChain(tokenIn?.chainId, ChainId.BASE) ||
			!compareChain(tokenOut?.chainId, ChainId.BASE) ||
			(!compareAddress(tokenIn?.address, VIRTUAL_TOKEN_ADDRESS) &&
				!compareAddress(tokenOut?.address, VIRTUAL_TOKEN_ADDRESS))
		) {
			return;
		}
		const { amountOut, fee } = await VirtualService.estimateSwap({
			amountIn,
			tokenIn: tokenIn.address,
			tokenOut: tokenOut.address,
		});
		const route: RouteVirtual = {
			amountOut,
			fee,
			amountIn,
			tokenIn,
			tokenOut,
		};
		return formatRoute(route, paramsSwap);
	}
}

export const VirtualSwap = new Virtual();

export const useExecuteVirtual = () => {
	const takeFee = useTakeFeeSwap();
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const response = useMutation({
		mutationKey: ['exe-route-virtual'],

		mutationFn: async ({ route }: { route: SelectedRoute<RouteVirtual> }) => {
			return VirtualService.swap(route);
		},
		onSuccess: (tx, { route }) => {
			const { hash, nonce } = tx;
			takeFee({ hash, route, nonce });
			addPendingEvmTransaction({
				transaction: tx,
				metadata: {
					transactionType: TransactionType.Swap,
				},
				trackingData: route,
			});
		},
	});
	return response;
};

import { ChainId } from '@/app-constants/chains';
import { MemePlatform } from '@/app-services/virtual/type';
import { create } from 'zustand';

interface State {
	selectedChain: ChainId | '';
	setSelectedChain: (chainId: ChainId | '') => void;
	refreshingBalance: boolean;
	isPullToRefresh: boolean;
	setRefreshingBalance: (v: boolean, isPullToRefresh?: boolean) => void;

	memePlatform: MemePlatform;
	setMemePlatform: (v: MemePlatform) => void;
	reset: () => void;
}

export const useAppState = create<State>((set) => ({
	selectedChain: '',
	refreshingBalance: false,
	isPullToRefresh: false,
	memePlatform: MemePlatform.VIRTUALS_IO,
	setMemePlatform: (memePlatform: MemePlatform) => {
		return set(() => ({ memePlatform }));
	},
	setSelectedChain: (chainId: ChainId | '') => {
		return set(() => ({ selectedChain: chainId }));
	},
	setRefreshingBalance: (refreshingBalance: boolean, isPullToRefresh: boolean = false) => {
		return set(() => ({ refreshingBalance, isPullToRefresh: isPullToRefresh }));
	},
	reset: () => {
		return set(() => ({ selectedChain: '' }));
	},
}));

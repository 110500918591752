import { useTonConnect } from '@/app-features/app-ton-connect/hooks/useConnect';
import { useGlobalToast, useQrCodeReaderStore } from '@/app-store';
import { getTonConnectParams } from '../utils/HelperUtil';

export const useScanWC = () => {
	const { setOpen, setOnScan } = useQrCodeReaderStore();
	// const { isWaiting } = useModalStore();
	// const { onConnect } = useConnect();
	const { onConnect: onTonConnect } = useTonConnect();
	const { addToast } = useGlobalToast();

	async function onScan(data: string | null) {
		try {
			if (!data) return;
			const { connectionUrl, isTonConnect } = getTonConnectParams(data);
			if ((data as string).startsWith('tc://') || isTonConnect) {
				onTonConnect(connectionUrl);
				return setOpen(false);
			}
			addToast({
				type: 'error',
				message: 'Only supports connecting to dApps via TON Connect',
			});
			// if (!isWaiting) {
			// 	await onConnect(data);
			// 	setOpen(false);
			// }
		} catch (error) {
			console.error('scan error', error);
		}
	}

	return {
		onScan,
		setOpen,
		setOnScan,
	};
};

import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import {
	compareChain,
	compareTobiToken,
	compareToken,
	isNativeTobiToken,
	isNativeToken,
	isStableCoin,
} from '@/app-helpers/address';
import { countDecimals, formatCurrency, formatNumber, formatUsd, truncateToFixed } from '@/app-helpers/number';
import { BuildRouteDataKyber, KyberSwap, useExecuteRouteKyberSwap } from '@/app-hooks/swap/kyberswap';

import { QUERY_KEYS } from '@/app-constants';
import { ObserverTransaction } from '@/app-cores/api/activities';
import { SwapFeeResponse, SwapServiceAPI } from '@/app-cores/api/swap';
import i18n from '@/app-cores/i18n';
import { getEnvironment } from '@/app-helpers';
import { getNativeToken, getTokenId, getTokenInfo, isChainHasSwapFee, tokenHasBalance } from '@/app-helpers/token';
import { isSupportSignTxs } from '@/app-helpers/web3';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useDebounce } from '@/app-hooks/common';
import { Route7K, SevenKSwap, useExecuteRoute7K } from '@/app-hooks/swap/7kswap';
import { BeraSwap, RouteBera, useExecuteRouteBera } from '@/app-hooks/swap/bera';
import { ContractSwap } from '@/app-hooks/swap/contract';
import { DebridgeSwap, RouteDebridge, useExecuteRouteDebridge } from '@/app-hooks/swap/debridge';
import { DedustSwap, RouteDedust, useExecuteRouteDedust } from '@/app-hooks/swap/dedust';
import {
	calcAmount,
	calcRateSwap,
	combineRouteMultiProvider,
	filterRouteSolTon,
	getMinAmount,
	getSwapType,
	promiseWithTimeout,
	tryParseAmount,
} from '@/app-hooks/swap/helper';
import { JupiterSwap, RouteJupiter, useExecuteRouteJupiter } from '@/app-hooks/swap/jupiter';
import { LifiSwap, RouteLifi, useExecuteRouteLifi } from '@/app-hooks/swap/lifi';
import { OrcaSwap, RouteOrca, useExecuteRouteOrca } from '@/app-hooks/swap/orca';
import { OwltoSwap, RouteOwlto, useExecuteRouteOwlto } from '@/app-hooks/swap/owlto';
import { PiperxSwap, RoutePiperX, useExecuteRoutePiperX } from '@/app-hooks/swap/piperx';
import { PumpFunSwap, RoutePumpFun, useExecuteRoutePumpFun } from '@/app-hooks/swap/pump_fun';
import { RaydiumSwap, RouteRaydium, useExecuteRouteRaydium } from '@/app-hooks/swap/raydium';
import { RetroBridgeSwap, RouteRetroBridge, useExecuteRouteRetroBridge } from '@/app-hooks/swap/retrobridge';
import { RocketXSwap, RouteRocketX, useExecuteRouteRocketX } from '@/app-hooks/swap/rocketx';
import Sol2TcatSwap, { useExecuteRouteSol2Tcat } from '@/app-hooks/swap/sol_2_tcat';
import { RouteSton, StonSwap, useExecuteRouteSton } from '@/app-hooks/swap/ston';
import { RouteStoryHunt, StoryHuntSwap, useExecuteRouteStoryHunt } from '@/app-hooks/swap/storyhunt';
import { RouteSunSwap, SunSwap, useExecuteRouteSunSwap } from '@/app-hooks/swap/sunswap';
import { RouteSwing, SwingSwap, useExecuteRouteSwing } from '@/app-hooks/swap/swing';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	MinAmountError,
	PayloadExtractRoute,
	SwapAbstract,
	SwapErrorType,
	SwapProvider,
	SwapProviderTracking,
	SwapStepInfo,
	SwapType,
	UpdateStatusFn,
} from '@/app-hooks/swap/type';
import { RouteVirtual, VirtualSwap, useExecuteVirtual } from '@/app-hooks/swap/virtual';
import useWrapUnWrapToken from '@/app-hooks/wallet/useWrapUnWrapToken';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { useUserSettingsStore } from '@/app-store/settings';
import { SelectedRoute, SwapDisableType, useSwapStore } from '@/app-store/swap';
import { TransactionType } from '@/app-types';
import { AlertStatus } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { fromNano } from '@ton/core';
import axios, { AxiosRequestConfig } from 'axios';
import { ethers, formatUnits, parseUnits } from 'ethers';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const displayMaxFee = ({
	gasNative,
	chainId,
	usdPriceNative,
}: {
	gasNative: bigint;
	usdPriceNative: number | undefined;
	chainId: ChainId;
}) => {
	const { symbol, decimals } = getNativeToken(chainId);
	const amountNative = formatUnits(gasNative, decimals);
	return !usdPriceNative
		? `max ${amountNative} ${symbol}`
		: `max ${formatUsd(usdPriceNative * +amountNative)} (${amountNative} ${symbol})`;
};
export const isShowGasWarning = ({
	compareUsd,
	parseNativeBalance,
	gasNative,
	gasUsd,
	balanceNativeUsd,
	tokenOut,
}: {
	compareUsd: boolean;
	parseNativeBalance: bigint;
	gasNative: bigint;
	gasUsd: string | number;
	balanceNativeUsd: number;
	tokenOut: ITokenSearch | undefined;
}) => {
	try {
		if (!tokenOut) return false;
		if (compareUsd) {
			return !parseNativeBalance || (parseNativeBalance && Number(balanceNativeUsd) < Number(gasUsd));
		}
		return !parseNativeBalance || (parseNativeBalance && parseNativeBalance < gasNative);
	} catch (error) {
		return false;
	}
};

export const calculatePriceImpact = (
	amountInUsd: number | string | undefined,
	amountOutUsd: number | string | undefined,
) => {
	if (!amountInUsd || !amountOutUsd) return;
	amountInUsd = Number(amountInUsd || 0);
	amountOutUsd = Number(amountOutUsd || 0);
	const priceImpact = !amountOutUsd ? NaN : ((amountInUsd - amountOutUsd) * 100) / amountInUsd;
	return priceImpact;
};

// priceImpact is invalid if it's not finite (NaN, Infinity)
// priceImpact is undefined or null means it's not provided
const checkPriceImpact = (
	priceImpact: number | undefined | null,
): {
	isInvalid: boolean;
	isHigh: boolean;
} => {
	return {
		// priceImpact < 0 is still VALID. That's when you swap $10, but receive back $12
		isInvalid: typeof priceImpact === 'number' && !Number.isFinite(priceImpact),
		isHigh: !!priceImpact && priceImpact > 10,
	};
};

export const getPriceImpactWarningMsg = ({
	priceImpact,
	amountInUsd,
	swapType,
}: {
	priceImpact: number;
	amountInUsd: number | string;
	swapType: SwapType;
}): ErrorMsg => {
	const { isHigh, isInvalid } = checkPriceImpact(priceImpact);
	if (
		+amountInUsd >= 10 &&
		![SwapType.WRAP_EVM, SwapType.UNWRAP_EVM, SwapType.NOT_SUPPORT].includes(swapType) &&
		(isHigh || isInvalid)
	) {
		const format = formatNumber(priceImpact, { decimals: 2 });
		return {
			type: 'warning',
			msg: i18n.t('tokenTrading.priceImpactWarningDesc', { percent: format }),
			title: i18n.t('tokenTrading.priceImpactWarningTitle', { percent: format }),
		};
	}
};

export type ErrorMsg =
	| {
			type: 'warning' | 'error'; // to disabled button
			uiType?: AlertStatus; // to display by color
			msg: string;
			errorType?: SwapErrorType;
			title?: string;
	  }
	| undefined;
export type SwapErrorInfo = { hasError: boolean; messages: ErrorMsg[] };
export const useValidateInput = ({
	tokenIn,
	parsedAmount,
	parseNativeBalance,
	priceImpact,
	swapType,
	parseTokenInBalance,
	amountInUsd,
	showGasWarning,
	gettingRoute,
	getRouteError,
	usdPriceTokenIn,
	amountInDebounced,
	hasAlternativeRoutes,
	route,
}: {
	amountInDebounced: string;
	tokenIn: TokenInfo | undefined;
	priceImpact: number | undefined;
	parsedAmount: bigint;
	parseNativeBalance: bigint | undefined;
	parseTokenInBalance: bigint;
	swapType: SwapType;
	amountInUsd: number | string | undefined;
	showGasWarning: boolean;
	gettingRoute: boolean;
	getRouteError: Error;
	usdPriceTokenIn: number | undefined;
	hasAlternativeRoutes: boolean;
	route?: SelectedRoute;
}): SwapErrorInfo => {
	const { t } = useTranslation();
	return useMemo(() => {
		if (!tokenIn) return { hasError: false, messages: [] };

		const chainId = tokenIn?.chainId;

		const errors: ErrorMsg[] = [];
		const needCheckBalance = !!parsedAmount;

		if (amountInDebounced && countDecimals(amountInDebounced) > tokenIn?.decimals) {
			errors.push({ type: 'error', msg: '', errorType: SwapErrorType.VALIDATE_AMOUNT });
		}

		const minAmountErr = (route?.disableReason || getRouteError) as MinAmountError;
		const isGenericMinAmountError = minAmountErr?.isGeneral;
		const minAmountValue =
			parsedAmount && minAmountErr?.minAmountError
				? `${usdPriceTokenIn ? formatUsd(minAmountErr?.minAmount * +usdPriceTokenIn) : ''} ≈ ${formatCurrency(
						minAmountErr?.minAmount,
				  )} ${tokenIn?.symbol}`
				: null;

		const hasMinAmountError = minAmountValue || isGenericMinAmountError;

		if (tokenIn?.idTobi && (hasMinAmountError || !route)) {
			errors.push({
				type: 'error',
				msg: isGenericMinAmountError
					? t(`tokenTrading.minAmountGeneral`)
					: hasAlternativeRoutes
					? minAmountValue
						? t(`tokenTrading.minAmountTryAlternative`, {
								amount: minAmountValue,
						  })
						: t('tokenTrading.routeNotFoundTryAlternative')
					: minAmountValue
					? t(`tokenTrading.minAmount`, {
							amount: minAmountValue,
					  })
					: t('tokenTrading.routeNotFound'),
				errorType: SwapErrorType.ROUTE,
			});
		}

		if (
			!hasMinAmountError &&
			(parseTokenInBalance < parsedAmount || (tokenIn && !parseTokenInBalance && needCheckBalance))
		) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('tokenTrading.notEnoughToken', {
					token: tokenIn?.symbol,
					chainName: CHAIN_CONFIG[tokenIn?.chainId].name,
				}),
				errorType: SwapErrorType.FUND,
			};
			errors.push(error);
		}

		const chainIdOut = route?.tokenOut?.chainId;
		if ((chainId && !isSupportSignTxs(chainId)) || (chainIdOut && !isSupportSignTxs(chainIdOut))) {
			errors.push({
				type: 'error',
				msg: 'This chain is not supported.',
				errorType: SwapErrorType.ROUTE,
			});
		} else if (!hasMinAmountError && showGasWarning && !!parsedAmount && !gettingRoute) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('tokenTrading.notEnoughGas', {
					token: getNativeToken(tokenIn?.chainId)?.symbol,
					chainName: CHAIN_CONFIG[tokenIn?.chainId].name,
				}),
				errorType: SwapErrorType.GAS,
			};
			errors.push(error);
		}

		if (parsedAmount === 0n)
			errors.push({
				type: 'error',
				msg: '',
				errorType: SwapErrorType.VALIDATE_AMOUNT,
			});

		if (needCheckBalance) {
			const error = getPriceImpactWarningMsg({ priceImpact, swapType, amountInUsd });
			if (error) errors.push(error);
		}

		if (route?.subRoutes) {
			errors.push({
				type: 'warning',
				uiType: 'success',
				title: t('tokenTrading.specialDirectRoute'),
				msg: t('tokenTrading.specialDirectRouteDesc'),
				errorType: SwapErrorType.ROUTE,
			});
		}

		const minValue = getMinAmount(route?.provider, isNativeToken(tokenIn?.address));
		if (parsedAmount && minValue && parseNativeBalance < minValue) {
			const msgSton = t(`tokenTrading.minBalance`, {
				amount: `${fromNano(minValue)} ${getNativeToken(tokenIn?.chainId)?.symbol}`,
			});
			errors.push({ type: 'error', msg: msgSton, errorType: SwapErrorType.ROUTE });
		}

		return { hasError: errors.some((e) => e.type === 'error'), messages: errors };
	}, [
		priceImpact,
		parsedAmount,
		tokenIn,
		swapType,
		parseTokenInBalance,
		amountInUsd,
		showGasWarning,
		gettingRoute,
		getRouteError,
		usdPriceTokenIn,
		t,
		parseNativeBalance,
		amountInDebounced,
		hasAlternativeRoutes,
		route,
	]);
};

export const isRouteParamsEmpty = ({
	tokenIn,
	tokenOut,
	amountIn,
}: {
	tokenIn: TokenInfo;
	amountIn: string;
	tokenOut: TokenInfo;
}) => {
	return !tokenIn || !tokenOut || compareToken(tokenIn, tokenOut) || !amountIn || amountIn === '0';
};

export const getSwapParams = (data: ArgsGetRoute): ArgsGetRoute => {
	if (isRouteParamsEmpty(data)) return;
	return data;
};

export const useGetSwapParam = ({
	amountIn,
	tokenIn,
	tokenOut,
	slippage,
	amountInDefault,
	providers,
}: ArgsGetRoute) => {
	const params = useMemo(
		() => getSwapParams({ amountIn, tokenIn, tokenOut, slippage, amountInDefault, providers }),
		[tokenIn, tokenOut, amountIn, slippage, amountInDefault, providers],
	);
	return useDebounce(params, 400);
};

export const getRouteKey = (getRouteParams: ArgsGetRoute) => {
	const { amountIn, tokenIn, tokenOut, slippage } = getRouteParams || {};
	return [amountIn, getTokenId(tokenIn), getTokenId(tokenOut), slippage].join(',');
};

export const useFetchRoute = ({
	getRouteParams,
	refetchInterval,
	lastRoute,
}: {
	getRouteParams: ArgsGetRoute;
	refetchInterval: number;
	lastRoute: SelectedRoute | undefined;
}) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_ROUTE_SWAP, getRouteKey(getRouteParams)],
		queryFn: async (option) => {
			try {
				const data = await SwapService.fetchRouteBySwapType({
					paramsSwap: getRouteParams,
					signal: option.signal,
					withRetry: true,
				});
				if (lastRoute && data) {
					const { tokenIn, tokenOut, provider } = lastRoute;
					if (
						compareToken(tokenIn, data.tokenIn) &&
						compareToken(tokenOut, data.tokenOut) &&
						data.provider !== provider &&
						data.allRoutes?.length > 1
					) {
						const route = data.allRoutes.find((e) => e.provider === provider);
						if (route) return { ...route, allRoutes: data.allRoutes };
					}
				}
				return data || null;
			} catch (error) {
				if (error?.code === 'ERR_CANCELED') return null;
				!getEnvironment('prod') && console.log('get route err', error);
				dataDogAddError(error, {
					tags: {
						name: DATADOG_ERROR_TAGS.TRADE,
						function: 'getRoute',
					},
				});
				throw error;
			}
		},
		gcTime: refetchInterval,
		staleTime: refetchInterval,
		refetchInterval,
		enabled: !!getRouteParams,
		refetchOnMount: true,
	});
	return response;
};

// get route and popup late token info to consistent for all provider
export async function commonGetRoute(
	payload: AxiosRequestConfig,
	{ tokenIn, tokenOut }: { tokenIn: TokenInfo; tokenOut: TokenInfo },
) {
	const response = await axios(payload);
	const resp = response?.data?.data || response?.data;
	resp.tokenIn = { ...tokenIn, ...resp.tokenIn };
	resp.tokenOut = { ...tokenOut, ...resp.tokenOut };
	return resp;
}

const mapSwapInstance: { [key in SwapProvider]: any } = {
	[SwapProvider.LIFI]: LifiSwap,
	[SwapProvider.ROCKET]: RocketXSwap,
	[SwapProvider.DEBRIDGE]: DebridgeSwap,
	[SwapProvider.KYBER]: KyberSwap,
	[SwapProvider.STON]: StonSwap,
	[SwapProvider.JUPITER]: JupiterSwap,
	[SwapProvider.SWING]: SwingSwap,
	[SwapProvider.BERA]: BeraSwap,
	[SwapProvider.CONTRACT]: ContractSwap,
	[SwapProvider.DEDUST]: DedustSwap,
	[SwapProvider.RETROBRIDGE]: RetroBridgeSwap,
	[SwapProvider.Sol2Tcat]: Sol2TcatSwap,
	[SwapProvider.SUNSWAP]: SunSwap,
	[SwapProvider.SEVEN_K_SWAP]: SevenKSwap,
	[SwapProvider.RAYDIUM]: RaydiumSwap,
	[SwapProvider.ORCA]: OrcaSwap,
	[SwapProvider.OWLTO]: OwltoSwap,
	[SwapProvider.PUMPFUN]: PumpFunSwap,
	[SwapProvider.VIRTUAL]: VirtualSwap,
	[SwapProvider.PIPERX]: PiperxSwap,
	[SwapProvider.STORY_HUNT]: StoryHuntSwap,
};

const getRouteWithRetry = async ({
	swapFns: _swapFns,
	paramsSwap,
	signal,
	timeout = 10_000,
	filterFn,
	withRetry,
	forceSortAmount = false,
}: {
	swapFns: Array<SwapAbstract<any>>;
	paramsSwap: ArgsGetRoute;
	signal;
	withRetry: boolean;
	timeout?: number;
	filterFn?: (v: SelectedRoute[]) => SelectedRoute[];
	forceSortAmount?: boolean;
}) => {
	const swapFns = paramsSwap.providers ? _swapFns.filter((e) => paramsSwap.providers.includes(e.provider)) : _swapFns;
	let routes: SelectedRoute;
	let originError;
	try {
		routes = await combineRouteMultiProvider(
			swapFns.map((el) => el.getRoute(paramsSwap, signal)),
			timeout,
			filterFn,
			forceSortAmount,
		);
	} catch (error) {
		if (!withRetry) throw error;
		originError = error;
	}
	if (routes || !withRetry) return routes;
	try {
		const { amountInDefault, amountIn, ...rest } = paramsSwap;
		const newParams = { ...rest, amountIn: amountInDefault };
		routes = await combineRouteMultiProvider(
			swapFns.map((el) => el.getRoute(newParams, signal)),
			timeout,
			filterFn,
			forceSortAmount,
		);
		if (routes) {
			const props = {
				disabled: SwapDisableType.MIN_AMOUNT,
				disableReason: new MinAmountError({ err: originError?.toString?.() }),
			};
			routes = { ...routes, ...props, allRoutes: routes?.allRoutes?.map((e) => ({ ...e, ...props })) };
		}
		return routes;
	} catch (error) {
		throw originError || error;
	}
};

export class SwapService {
	static initRoute() {
		// todo init ondemand?
		RocketXSwap.init();
		DebridgeSwap.init();
		SwingSwap.init();
		RetroBridgeSwap.init();
		OwltoSwap.init();
	}
	static async _fetchRouteBySwapType(
		paramsSwap: ArgsGetRoute,
		signal?: AbortSignal,
		withRetry?: boolean,
	): Promise<SelectedRoute | null> {
		if (!paramsSwap) return null;
		const { tokenIn, tokenOut } = paramsSwap;
		const swapType = getSwapType(tokenIn, tokenOut);

		switch (swapType) {
			case SwapType.CROSSCHAIN_EVM: {
				return getRouteWithRetry({
					swapFns: [LifiSwap, DebridgeSwap, SwingSwap, RetroBridgeSwap, RocketXSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}

			case SwapType.CROSSCHAIN_EVM_SOL:
			case SwapType.CROSSCHAIN_SOL_EVM: {
				return getRouteWithRetry({
					swapFns: [DebridgeSwap, SwingSwap, RetroBridgeSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}

			case SwapType.CROSSCHAIN_SOL_SUI:
			case SwapType.CROSSCHAIN_EVM_SUI:
			case SwapType.CROSSCHAIN_TON_SUI:
			case SwapType.CROSSCHAIN_TRON_SUI:
			case SwapType.CROSSCHAIN_SUI_EVM:
			case SwapType.CROSSCHAIN_SUI_SOL:
			case SwapType.CROSSCHAIN_SUI_TON:
			case SwapType.CROSSCHAIN_SUI_TRON:
				return getRouteWithRetry({
					swapFns: [RocketXSwap],
					paramsSwap,
					signal,
					withRetry,
				});

			case SwapType.CROSSCHAIN_EVM_ECLIPSE:
			case SwapType.CROSSCHAIN_ECLIPSE_EVM:
				return getRouteWithRetry({
					swapFns: [OwltoSwap],
					paramsSwap,
					signal,
					withRetry,
				});

			case SwapType.CROSSCHAIN_SOL_TRON:
			case SwapType.CROSSCHAIN_EVM_TRON:
			case SwapType.CROSSCHAIN_TON_TRON:
			case SwapType.CROSSCHAIN_TRON_EVM:
			case SwapType.CROSSCHAIN_TRON_SOL:
			case SwapType.CROSSCHAIN_TRON_TON:
				return getRouteWithRetry({
					swapFns: [RocketXSwap, RetroBridgeSwap],
					paramsSwap,
					signal,
					withRetry,
					timeout: 40_000,
					forceSortAmount: true,
				});

			case SwapType.CROSSCHAIN_TON_SOL:
			case SwapType.CROSSCHAIN_SOL_TON:
			case SwapType.CROSSCHAIN_EVM_TON:
			case SwapType.CROSSCHAIN_TON_EVM: {
				if (Sol2TcatSwap.isMyRoute(paramsSwap)) {
					return Sol2TcatSwap.getRoute(paramsSwap, signal);
				}
				let routeSolTon;
				try {
					routeSolTon = await combineRouteMultiProvider(
						[
							RetroBridgeSwap.getRoute(paramsSwap, signal, true),
							RocketXSwap.getRoute(paramsSwap, signal, false),
						],
						40_000,
						filterRouteSolTon,
					);
				} catch (error) {
					routeSolTon = await RocketXSwap.getRoute(paramsSwap, signal, true);
				}
				return routeSolTon;
			}

			case SwapType.SWAP_EVM: {
				switch (+tokenIn?.chainId) {
					case ChainId.BERACHAIN_TESTNET:
						return BeraSwap.getRoute(paramsSwap, signal);
					case ChainId.STORY_TESTNET:
						return getRouteWithRetry({
							swapFns: [PiperxSwap, StoryHuntSwap],
							paramsSwap,
							signal,
							withRetry,
						});
					default:
						return getRouteWithRetry({
							swapFns: [KyberSwap, LifiSwap, DebridgeSwap, SwingSwap, VirtualSwap],
							paramsSwap,
							signal,
							withRetry,
						});
				}
			}
			case SwapType.SWAP_SOL: {
				return getRouteWithRetry({
					swapFns: [JupiterSwap, DebridgeSwap, RaydiumSwap, PumpFunSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}

			case SwapType.SWAP_TON: {
				return getRouteWithRetry({
					swapFns: [StonSwap, DedustSwap],
					paramsSwap,
					signal,
					timeout: 40_000,
					withRetry,
				});
			}
			case SwapType.WRAP_EVM:
			case SwapType.UNWRAP_EVM: {
				return ContractSwap.getRoute(paramsSwap);
			}

			case SwapType.SWAP_TRON:
				return SunSwap.getRoute(paramsSwap, signal);
			case SwapType.SWAP_SUI:
				return SevenKSwap.getRoute(paramsSwap, signal);
			case SwapType.SWAP_ECLIPSE:
				return OrcaSwap.getRoute(paramsSwap, signal);

			default: {
				return null;
			}
		}
	}
	static async fetchRouteBySwapType({
		paramsSwap,
		signal,
		withRetry,
		skipFee,
	}: {
		paramsSwap: ArgsGetRoute;
		signal?: AbortSignal;
		withRetry?: boolean;
		skipFee?: boolean;
	}): Promise<SelectedRoute | null> {
		const { tokenIn, amountIn, tokenOut } = paramsSwap;
		let feeInfo: SwapFeeResponse;
		let newParams;
		try {
			const swapType = getSwapType(tokenIn, tokenOut);
			if (
				!skipFee &&
				isChainHasSwapFee(tokenIn?.chainId) &&
				![SwapType.WRAP_EVM, SwapType.UNWRAP_EVM].includes(swapType)
			) {
				feeInfo = await SwapServiceAPI.getSwapFee({
					amount: formatUnits(amountIn, tokenIn.decimals),
				});
				const feeAmount = parseUnits(truncateToFixed(feeInfo.fee, tokenIn.decimals), tokenIn.decimals);
				feeInfo.feeAmount = feeAmount.toString();
				newParams = {
					...paramsSwap,
					amountIn: (BigInt(amountIn) - feeAmount).toString(),
					originalAmountIn: amountIn,
				};
				console.log({ newParams });
			}
		} catch (error) {
			console.log('get fee error', error);
		}
		if (newParams && feeInfo) {
			const route = await this._fetchRouteBySwapType(newParams, signal, withRetry);
			return SwapService.populateFee(route, feeInfo);
		}
		return this._fetchRouteBySwapType(paramsSwap, signal, withRetry);
	}

	static async buildRoute(data: {
		slippage: number;
		userAmount: string;
		route: SelectedRoute;
	}): Promise<SelectedRoute> {
		const route = await mapSwapInstance[data.route.provider].buildRoute(data);
		if (!route) throw new Error('Empty route data');
		return route;
	}

	static async buildRouteAndPopulateFee(data: {
		slippage: number;
		userAmount: string;
		route: SelectedRoute;
	}): Promise<SelectedRoute> {
		const route = await SwapService.buildRoute(data);
		route.feeInfo = data.route.feeInfo;
		return route;
	}

	static populateFee(route: SelectedRoute, feeInfo: SwapFeeResponse) {
		if (!route) return;
		route.feeInfo = feeInfo;
		route.allRoutes?.forEach((e) => {
			e.feeInfo = feeInfo;
		});
		return route;
	}
	static changeRoute(oldRoute: SelectedRoute, newRoute: SelectedRoute): SelectedRoute | undefined {
		return {
			...newRoute,
			allRoutes: oldRoute.allRoutes,
		};
	}
	static extractRouteInfo({ route, ...pricesInfo }: PayloadExtractRoute): ExtractRouteInfo {
		const usdPriceIn = pricesInfo?.usdPriceIn;
		const usdPriceOut = pricesInfo?.usdPriceOut;
		const { tokenIn, tokenOut, params, disabled } = route || {};
		const info: ExtractRouteInfo = mapSwapInstance[route?.provider]?.extractRoute(route, pricesInfo) || {};

		const amountIn =
			!route?.feeInfo || disabled === SwapDisableType.MIN_AMOUNT ? info?.amountIn : params?.originalAmountIn;

		const amountOut = info?.amountOut;

		const amountInUsd =
			usdPriceIn && amountIn && tokenIn?.decimals
				? +ethers.formatUnits(amountIn, tokenIn?.decimals) * usdPriceIn
				: undefined;

		const amountOutUsd =
			usdPriceOut && amountOut && tokenOut?.decimals
				? +ethers.formatUnits(amountOut, tokenOut?.decimals) * usdPriceOut
				: undefined;

		return {
			...info,
			// common field
			amountIn,
			amountInUsd,
			amountOutUsd,
			priceImpact: calculatePriceImpact(amountInUsd, amountOutUsd),
			rate: calcRateSwap({ ...info, amountIn }),
			feeInfo: route?.feeInfo,
		};
	}
	static formatSteps(provider, ...args): SwapStepInfo[] {
		return mapSwapInstance[provider]?.formatSteps(...args) ?? [];
	}
}

export const useExecuteRoute = (quickSwap: boolean) => {
	const { selectedRoute, amount } = useSwapStore(quickSwap);
	const { tokenIn } = selectedRoute ?? {};

	const { mutateAsync: wrapUnWrapToken } = useWrapUnWrapToken();
	const { mutateAsync: execSwapLifi } = useExecuteRouteLifi(tokenIn?.chainId);
	const { mutateAsync: execSwapSton } = useExecuteRouteSton();
	const { mutateAsync: execSwapRocket } = useExecuteRouteRocketX(tokenIn);
	const { mutateAsync: execSwapKyber } = useExecuteRouteKyberSwap(tokenIn?.chainId);
	const { mutateAsync: execSwapDebridge } = useExecuteRouteDebridge(tokenIn?.chainId);
	const { mutateAsync: execSwapJupiter } = useExecuteRouteJupiter();
	const { mutateAsync: execSwapRaydium } = useExecuteRouteRaydium();
	const { mutateAsync: execSwapSwing } = useExecuteRouteSwing();
	const { mutateAsync: execRouteBera } = useExecuteRouteBera();
	const { mutateAsync: execRoutePiperX } = useExecuteRoutePiperX();

	const { mutateAsync: execRouteDedust } = useExecuteRouteDedust();
	const { mutateAsync: execRouteRetroBridge } = useExecuteRouteRetroBridge();
	const { mutateAsync: execRouteSol2Tcat } = useExecuteRouteSol2Tcat(tokenIn, quickSwap);
	const { mutateAsync: execRouteSunSwap } = useExecuteRouteSunSwap();
	const { mutateAsync: execRoute7k } = useExecuteRoute7K();
	const { mutateAsync: execRouteOwlto } = useExecuteRouteOwlto(tokenIn?.chainId);
	const { mutateAsync: execRouteOrca } = useExecuteRouteOrca();
	const { mutateAsync: execRoutePumpFun } = useExecuteRoutePumpFun();
	const { mutateAsync: execRouteVirtual } = useExecuteVirtual();
	const { mutateAsync: execRouteStoryHunt } = useExecuteRouteStoryHunt();

	return async ({
		provider,
		onUpdateDetail: onUpdate,
		onUpdateStatus,
	}: {
		provider: SwapProvider;
		onUpdateDetail: (data: any) => void;
		onUpdateStatus: UpdateStatusFn;
	}): Promise<{ hash: string }> => {
		switch (provider) {
			case SwapProvider.LIFI:
				return execSwapLifi({ route: selectedRoute as SelectedRoute<RouteLifi>, onUpdate });

			case SwapProvider.STON: {
				const resp = await execSwapSton(selectedRoute as SelectedRoute<RouteSton>);
				return { hash: resp.hash };
			}
			case SwapProvider.DEDUST: {
				const { queryId } = await execRouteDedust({
					route: selectedRoute as SelectedRoute<RouteDedust>,
				});
				return { hash: queryId.toString() };
			}
			case SwapProvider.ROCKET: {
				return execSwapRocket({ route: selectedRoute as SelectedRoute<RouteRocketX> });
			}
			case SwapProvider.DEBRIDGE: {
				return execSwapDebridge({ route: selectedRoute as SelectedRoute<RouteDebridge>, onUpdateStatus });
			}
			case SwapProvider.SWING: {
				return execSwapSwing({ route: selectedRoute as SelectedRoute<RouteSwing>, onUpdate, onUpdateStatus });
			}
			case SwapProvider.KYBER: {
				return execSwapKyber(selectedRoute as SelectedRoute<BuildRouteDataKyber>);
			}
			case SwapProvider.CONTRACT: {
				const swapType = getSwapType(selectedRoute.tokenIn, selectedRoute.tokenOut);
				switch (swapType) {
					case SwapType.UNWRAP_EVM:
					case SwapType.WRAP_EVM:
						return wrapUnWrapToken({
							...selectedRoute,
							amount: tryParseAmount(amount, tokenIn?.decimals)?.toString(),
						});
					default:
						return;
				}
			}
			case SwapProvider.JUPITER: {
				return execSwapJupiter({ route: selectedRoute as SelectedRoute<RouteJupiter>, onUpdateStatus });
			}
			case SwapProvider.RAYDIUM: {
				return execSwapRaydium({ route: selectedRoute as SelectedRoute<RouteRaydium>, onUpdateStatus });
			}
			case SwapProvider.BERA: {
				return execRouteBera(selectedRoute as SelectedRoute<RouteBera>);
			}
			case SwapProvider.PIPERX: {
				return execRoutePiperX(selectedRoute as SelectedRoute<RoutePiperX>);
			}
			case SwapProvider.STORY_HUNT: {
				return execRouteStoryHunt(selectedRoute as SelectedRoute<RouteStoryHunt>);
			}
			case SwapProvider.Sol2Tcat: {
				return execRouteSol2Tcat({ route: selectedRoute as SelectedRoute<any>, onUpdate });
			}
			case SwapProvider.RETROBRIDGE: {
				return execRouteRetroBridge({ route: selectedRoute as SelectedRoute<RouteRetroBridge> });
			}
			case SwapProvider.SUNSWAP: {
				return execRouteSunSwap(selectedRoute as SelectedRoute<RouteSunSwap>);
			}
			case SwapProvider.SEVEN_K_SWAP: {
				return execRoute7k(selectedRoute as SelectedRoute<Route7K>);
			}
			case SwapProvider.OWLTO: {
				return execRouteOwlto(selectedRoute as SelectedRoute<RouteOwlto>);
			}
			case SwapProvider.ORCA: {
				return execRouteOrca(selectedRoute as SelectedRoute<RouteOrca>);
			}
			case SwapProvider.PUMPFUN: {
				return execRoutePumpFun({ route: selectedRoute as SelectedRoute<RoutePumpFun>, onUpdateStatus });
			}
			case SwapProvider.VIRTUAL: {
				return execRouteVirtual({ route: selectedRoute as SelectedRoute<RouteVirtual> });
			}
			default: {
				throw new Error('Unknown swap method: ' + provider);
			}
		}
	};
};

const points = {
	[ChainId.BASE]: 1000,
	[ChainId.ARB]: 900,
	[ChainId.OP]: 800,
	[ChainId.AVAX]: 700,
	[ChainId.POLYGON]: 600,
	[ChainId.BNB]: 500,
};

const getTopTokens = (balances: ITokenSearch[], maxRoute?: number) => {
	const pointResult = {};

	balances.forEach((e) => {
		pointResult[getTokenId(e)] =
			(points[getTokenInfo(e).chainId] || 0) +
			(isNativeTobiToken(e) ? 10 : isStableCoin(getTokenInfo(e)) ? 5 : 0);
	});

	const rs = balances.sort((a, b) => pointResult[getTokenId(b)] - pointResult[getTokenId(a)]);
	return maxRoute ? rs.slice(0, maxRoute) : rs;
};

const maxSuggestRoute = 3;

const fetchListRoute = async (paramsDebounce: ArgsGetRoute[], signal: AbortSignal) => {
	const data = await Promise.allSettled(
		paramsDebounce.map((e) =>
			promiseWithTimeout<SelectedRoute>(SwapService.fetchRouteBySwapType({ paramsSwap: e, signal })),
		),
	);
	const routes = data
		.map((e) => (e.status === 'fulfilled' ? e.value : null))
		.filter(Boolean)
		.sort((a, b) => (a.disabled ? 1 : b.disabled ? -1 : 0));
	return { routes };
};

const useGetParamsSuggestRoute = (tokenOutParam: ITokenSearch | undefined) => {
	const tokenOut = getTokenInfo(tokenOutParam);
	const { slippage } = useUserSettingsStore();

	const {
		data: { mainTokens: balances },
	} = usePortfolioBalanceByCategories();
	const params = useMemo(() => {
		if (!tokenOut?.chainId) return [];

		const filterFn = (e: ITokenSearch) =>
			tokenHasBalance(e) &&
			compareChain(getTokenInfo(e).chainId, tokenOut.chainId) &&
			!isNativeTobiToken(e) &&
			!compareTobiToken(tokenOut as any, e);

		const result = getTopTokens(balances.filter(filterFn), maxSuggestRoute);

		const paramsSwap: ArgsGetRoute[] = result.map((e) => ({
			amountIn: e.balance,
			tokenIn: getTokenInfo(e),
			tokenOut,
			slippage,
		}));

		let paramsCrossChain: ArgsGetRoute[] = [];

		if (paramsSwap.length < maxSuggestRoute) {
			const filterFn = (e: ITokenSearch) =>
				tokenHasBalance(e) && !compareChain(getTokenInfo(e).chainId, tokenOut.chainId);
			const resultCrossChain = getTopTokens(balances.filter(filterFn));

			paramsCrossChain = resultCrossChain
				.map((e) => ({ amountIn: e.balance, tokenIn: getTokenInfo(e), tokenOut, slippage }))
				.filter((e) => getSwapType(e.tokenIn, e.tokenOut) !== SwapType.NOT_SUPPORT)
				.slice(0, maxSuggestRoute - paramsSwap.length);
		}

		return paramsSwap.concat(paramsCrossChain);
	}, [tokenOut, balances, slippage]);

	const paramsDebounce = useDebounce(params, 500);
	return paramsDebounce;
};

export const useGetSuggestedRoutes = ({
	tokenOut,
	enabled,
}: {
	tokenOut: ITokenSearch | undefined;
	enabled: boolean;
}) => {
	const params = useGetParamsSuggestRoute(tokenOut);
	return useQuery({
		queryKey: [
			'suggested-route',
			params.map((e) => `${e.amountIn}${getTokenId(e.tokenIn)}${getTokenId(e.tokenOut)}`).join(''),
		],
		queryFn: async ({ signal }) => fetchListRoute(params, signal),
		refetchInterval: 60_000,
		enabled: !!tokenOut && enabled,
	});
};

export const useGetAllPriceSwap = ({
	tokenIn,
	tokenOut,
	disabled,
}: {
	tokenIn: TokenInfo | undefined;
	tokenOut: TokenInfo | undefined;
	disabled?: boolean;
}) => {
	const { data: usdPriceNative } = usePriceNativeToken({ chainId: disabled ? null : tokenIn?.chainId });
	return { usdPriceNative, usdPriceTokenIn: tokenIn?.priceUsd, usdPriceTokenOut: tokenOut?.priceUsd };
};

export type AmountPayload = {
	amount?: string | number | undefined;
	amountUsd?: string | number | undefined;
	token: ITokenSearch | undefined;
	formatUsd?: boolean;
};

export const useChangeInputAmount = (quickSwap?: boolean) => {
	const { setAmount } = useSwapStore(quickSwap);

	const onChangeAmount = useCallback(
		(payload: AmountPayload) => {
			const { amount, amountUsd } = calcAmount(payload);
			setAmount(amount, amountUsd);
		},
		[setAmount],
	);
	return onChangeAmount;
};

const getNonNull = (obj) => {
	const key = Object.keys(obj).find((e) => !!obj[e]);
	return obj[key] ?? '';
};
const getProviderId = (selectedRoute: SelectedRoute) => {
	const route = selectedRoute.route as any;
	const { metadata } = selectedRoute;
	const ids = {
		rocketXRequestId: route?.requestId ?? '',
		debridgeOrderId: route?.orderId ?? '',
		retroBridgeTxsId: metadata?.transaction_id,
		swingTxsId: metadata?.txsId ?? '',
	};
	return getNonNull(ids);
};

export const getPayloadSwapTracking = (routeParams: SelectedRoute, payload?: any) => {
	const selectedRoute = routeParams?.subRoutes ? routeParams?.subRoutes[0] : routeParams;
	const { tokenIn, tokenOut, provider, metadata } = selectedRoute;
	return {
		swapProvider: SwapProviderTracking[provider] ?? '',
		rawProvider: provider ?? '',
		providerId: getProviderId(selectedRoute),
		swapType: compareChain(tokenIn.chainId, tokenOut.chainId)
			? TransactionType.Swap
			: TransactionType.SwapCrossChain,
		...payload,
	};
};

export type FeeSwapParams = {
	route: SelectedRoute;
	hash?: string;
	feeAmount: string;
	signatureFeeTransaction: string;
	backupFeeSignature?: string;
	affiliateCode: string;
	communityId: string;
};
export const getPayloadFeeSwap = ({
	route: selectedRoute,
	hash,
	backupFeeSignature,
	feeAmount,
	...rest
}: FeeSwapParams): ObserverTransaction => {
	const { provider, tokenIn, tokenOut, params, feeInfo } = selectedRoute;
	const { chainId, idTobi, priceUsd } = tokenIn;
	return {
		...rest,
		provider: SwapProviderTracking[provider] || provider,
		providerId: getProviderId(selectedRoute),
		isSwapSameChain: compareChain(chainId, tokenOut.chainId),
		hash,
		chainId: chainId.toString(),
		tobiId: idTobi,
		amount: feeAmount,
		backupFeeSignature,
		tokenOutChainId: tokenOut.chainId,
		tokenOutTobiId: tokenOut.idTobi,
		usdAmount: priceUsd * +ethers.formatUnits(params.originalAmountIn, tokenIn.decimals),
		percentage: feeInfo?.percentage,
	};
};

/**
 * for dev debug purpose
 */
export const trackingSwapDebugData = (selectedRoute: SelectedRoute) => {
	dataDogAddAction(DATADOG_ACTIONS.TRADE_DEBUG, getPayloadSwapTracking(selectedRoute));
	console.log(DATADOG_ACTIONS.TRADE_DEBUG, getPayloadSwapTracking(selectedRoute));
};

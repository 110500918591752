import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useWalletStore } from '@/app-store';
import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';

interface RequireWalletProps extends BoxProps {
	loadingComponent?: React.ReactNode;
}
export const RequireWallet: React.FC<React.PropsWithChildren<RequireWalletProps>> = ({
	children,
	loadingComponent,
	...props
}) => {
	const { walletStatus } = useWalletStore();
	const navigate = useNavigate();
	console.log('walletStatus:', walletStatus);
	return (
		<Box position="relative" {...props}>
			<Box
				onClickCapture={(e) => {
					if (walletStatus === 'REQUIRE_BACKUP') {
						e.stopPropagation();
						return navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
					}
					if (walletStatus === 'REQUIRE_CREATE') {
						e.stopPropagation();
						return navigate(NAVIGATE_PATHS.CreateWallet.Onboarding);
					}
					if (walletStatus === 'REQUIRE_RECOVERY') {
						e.stopPropagation();
						return navigate(NAVIGATE_PATHS.Recovery.SelectMethod);
					}
				}}
			>
				{children}
			</Box>
			{(walletStatus === 'CREATING' || walletStatus === 'INITIALIZING' || walletStatus === 'CREATE_FAILED') && (
				<Box
					position="absolute"
					top={0}
					left={0}
					zIndex={1}
					width="100%"
					height="100%"
					cursor="not-allowed"
					onClick={(e) => {
						e?.stopPropagation();
					}}
				>
					{loadingComponent}
				</Box>
			)}
		</Box>
	);
};

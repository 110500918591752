import { BlockHeader } from '@/app-components/common';
import { ELEMENT_IDS, QUERY_KEYS } from '@/app-constants';
import { BffServiceAPI, ITokenSearch } from '@/app-cores/api/bff';
import { queryClient } from '@/app-cores/query-client';
import { copilotManager } from '@/app-features/copilot/helper';
import { compareChain, isNativeToken } from '@/app-helpers/address';
import { getTokenInfo, isTestnetChain } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { usePullToRefresh } from '@/app-hooks/common';
import { useSupportedTestnetChainList } from '@/app-hooks/wallet/useChainList';
import { useAppState } from '@/app-store/app';
import ChainSelector from '@/app-views/wallet/components/Portfolio/ChainSelector';
import ListTokens, { TokenTypes } from '@/app-views/wallet/components/Portfolio/ListTokens';
import { FiPortfolioIcon } from '@/assets/icons/fi-portfolio-icon';
import { FiRefreshIcon } from '@/assets/icons/fi-refresh-icon';
import { Box, Card, Flex, Spinner } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { TOUR_GUIDE_STEPS_TARGET } from '../TourGuide/stepConfigs';
export const MAP_TOKEN_LIST_LABEL = {
	[TokenTypes.DEFAULT]: 'cryptos.myTokens',
	[TokenTypes.ALL]: 'cryptos.main',
	[TokenTypes.SPAM]: 'cryptos.spamTokens',
	[TokenTypes.HIDDEN]: 'cryptos.hiddenTokens',
	[TokenTypes.IMPORTED]: 'cryptos.importTokens',
	[TokenTypes.TESTNET]: 'cryptos.testnetTokens',
};

export const MyPortfolio = () => {
	const { t } = useTranslation();
	const { selectedChain, setRefreshingBalance, refreshingBalance } = useAppState();
	const { data: balanceByList, isFetched } = usePortfolioBalanceByCategories({ refetchInterval: 3000 });
	const [tokenType, setTokenType] = useState(TokenTypes.DEFAULT);

	const { isSupportTestnet } = useSupportedTestnetChainList();

	const { visibleBalances, tokenOptions } = useMemo(() => {
		const { mainTokens, spamTokens, hiddenTokens, importTokens, allTokens, testnetTokens } = balanceByList;
		const tokenByType = {
			[TokenTypes.ALL]: {
				data: allTokens,
				parent: null,
			},
			[TokenTypes.DEFAULT]: {
				data: mainTokens,
				parent: null,
			},
			[TokenTypes.IMPORTED]: {
				data: importTokens,
				parent: TokenTypes.DEFAULT,
			},
			[TokenTypes.HIDDEN]: {
				data: hiddenTokens,
				parent: null,
			},
			[TokenTypes.SPAM]: {
				data: spamTokens,
				parent: null,
			},
			[TokenTypes.TESTNET]: {
				data: testnetTokens,
				parent: null,
			},
		};

		let visibleBalances: ITokenSearch[];
		const tokenOptions = Object.keys(tokenByType)
			.map((value: TokenTypes) => {
				const tokens = selectedChain
					? tokenByType[value].data.filter((e) => compareChain(getTokenInfo(e).chainId, selectedChain))
					: tokenByType[value].data;
				if (tokenType === value) visibleBalances = tokens;
				return {
					label: t(MAP_TOKEN_LIST_LABEL[value]),
					total: tokens.length,
					value,
					parent: tokenByType[value].parent,
				};
			})
			.filter((e) => (isSupportTestnet ? true : e.value !== TokenTypes.TESTNET));

		return { visibleBalances, tokenOptions };
	}, [tokenType, balanceByList, selectedChain, t, isSupportTestnet]);

	const showGasWarning = useMemo(() => {
		const native = visibleBalances.find((item) => {
			const { address } = getTokenInfo(item);
			return isNativeToken(address);
		});
		const nativeValue = native?.usdValue || 0;
		if (isTestnetChain(selectedChain)) {
			return !native?.balance;
		}
		return tokenType === TokenTypes.DEFAULT && selectedChain && nativeValue < 1;
	}, [visibleBalances, tokenType, selectedChain]);

	const refreshBalance = async (isPullToRefresh = false) => {
		if (refreshingBalance || copilotManager.isDragging()) return;
		setRefreshingBalance(true, isPullToRefresh);
		try {
			await BffServiceAPI.getPortfolioBalance(true);
			await queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
			});
		} catch (error) {
		} finally {
			setRefreshingBalance(false);
		}
	};

	usePullToRefresh({
		shouldIgnore: !isMobile,
		containerId: ELEMENT_IDS.HOME_CONTAINER,
		onRefresh: refreshBalance,
		triggerThreshold: 0,
	});

	const isLoading = !isFetched;
	const emptyPortfolio = balanceByList.mainTokensHasBalance?.length === 0;

	return (
		<Box mt={8} id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.MY_PORTFOLIO}>
			<BlockHeader
				leftContent={
					<Flex alignItems={'center'} gap={2} role="button">
						<Flex justify={'center'} align={'center'} gap={2}>
							<FiPortfolioIcon />
							{t('cryptos.myPortfolio')}
						</Flex>

						{refreshingBalance ? (
							<Spinner size={'sm'} />
						) : (
							<FiRefreshIcon size={18} onClick={() => refreshBalance(false)} />
						)}
					</Flex>
				}
				to={''}
			/>

			<Card py={4} px={1} border={'1px'} borderRadius={'1rem'} borderColor={'#00000014'} gap={'20px'}>
				<Box px={3}>
					<ChainSelector
						balances={balanceByList.mainTokensHasBalance}
						isLoading={isLoading}
						onClickChain={(e) => {
							if (isTestnetChain(e)) setTokenType(TokenTypes.TESTNET);
							else {
								setTokenType(TokenTypes.DEFAULT);
							}
						}}
					/>
				</Box>
				<ListTokens
					balances={visibleBalances}
					isLoading={isLoading}
					{...{
						setTokenType,
						tokenType,
						tokenOptions,
						showGasWarning,
						emptyPortfolio,
					}}
				/>
			</Card>
		</Box>
	);
};

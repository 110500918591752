import { ButtonSecondary } from '@/app-components/common';
import { usePagination } from '@/app-components/common/Pagination';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatUsd } from '@/app-helpers/number';
import {
	useCalculateAffiliateTokenInfo,
	useGetAffiliateUserInfo,
	useGetAffiliateUserStat,
} from '@/app-hooks/api/user/affiliate';
import { AffiliateUser } from '@/app-services/fee';
import { colors } from '@/app-theme/theme';
import { useInviteFriend } from '@/app-views/account-setting/pages/InviteCode/InviteCode';
import { FiUserColorIcon } from '@/assets/icons/fi-user-icon';
import { ShareIcon } from '@/assets/images/svg/share-icon';
import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListGroup } from './ListGroup';

const pageSize = 10;
export const UserContent = ({ compact }: { compact?: boolean }) => {
	const { t } = useTranslation();
	const { onInvite } = useInviteFriend();
	const { data } = useGetAffiliateUserStat();
	const [page, setPage] = useState(1);
	const { data: userInfo, isFetching, isFetched } = useGetAffiliateUserInfo({ page, limit: pageSize });
	const groups = userInfo?.items;
	const { onBack, onNext } = usePagination({ page, setPage, data: groups });

	const { data: tokenInfo } = useCalculateAffiliateTokenInfo(data?.totalTokensEarned ?? []);
	return (
		<VStack w={'100%'} spacing={3}>
			<Flex w={'100%'} direction={'column'} gap={4}>
				<Flex alignItems={'flex-end'} justifyContent={'space-between'} gap={2}>
					<Text lineHeight={'24px'} fontWeight={500}>
						Users
					</Text>
					<ButtonSecondary size={'sm'} pl={2} onClick={onInvite}>
						<ShareIcon height={16} /> Invite User
					</ButtonSecondary>
				</Flex>
				<Box bgColor="white" borderRadius={8} p={3}>
					<Flex width={'100%'} justifyContent={'space-between'}>
						<VStack spacing={1} fontSize={'10px'} flex={1}>
							<Flex gap={2} height={'24px'} alignItems={'center'}>
								<FiUserColorIcon />
								<Text as="span" lineHeight={'16px'} fontWeight={600} fontSize={'16px'}>
									{data?.totalUsers || 0}
								</Text>
							</Flex>
							<Text>{t('affiliate.totalUser')}</Text>
						</VStack>

						<Divider
							orientation="vertical"
							h={'40px'}
							borderStyle={'dotted'}
							borderColor={colors.gray[300]}
						/>

						<VStack spacing={1} fontSize={'10px'} flex={1}>
							<Flex
								alignItems={'center'}
								height={'24px'}
								lineHeight={'16px'}
								fontWeight={600}
								fontSize={'16px'}
							>
								{formatUsd(tokenInfo?.totalUsd || 0)}
							</Flex>
							<Text>{t('affiliate.feeEarned')}</Text>
						</VStack>

						<Divider
							orientation="vertical"
							h={'40px'}
							borderStyle={'dotted'}
							borderColor={colors.gray[300]}
						/>

						<VStack spacing={1} fontSize={'10px'} flex={1}>
							<Flex
								alignItems={'center'}
								height={'24px'}
								lineHeight={'16px'}
								fontWeight={600}
								fontSize={'16px'}
							>
								{data?.totalTransactions || 0}
							</Flex>
							<Text>Transactions</Text>
						</VStack>
					</Flex>
				</Box>
			</Flex>
			<ListGroup<AffiliateUser>
				hasMore={!userInfo?.outOfData}
				pageSize={pageSize}
				compact={compact}
				data={groups}
				isFetching={isFetching}
				onBack={onBack}
				onNext={onNext}
				page={page}
			/>
			{compact && groups?.length > 0 && (
				<Link to={NAVIGATE_PATHS.Affiliate.User}>
					<Text textDecoration={'underline'} fontSize={'12px'} fontWeight={600}>
						All Users
					</Text>
				</Link>
			)}
		</VStack>
	);
};

import { ChainId } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { BffServiceAPI, ITokenSearch } from '@/app-cores/api/bff';
import { getTokenId, getTokenInfo } from '@/app-helpers/token';

export type AffiliateFeeInfo = {
	totalTransactions: number;
	tokenList: AffiliateTokenInfo[];
};

export type AffiliateStatInfo = {
	totalGroups: number;
	totalUsers: number;
	totalTransactions: number;
	totalTokensEarned: AffiliateTokenInfo[];
};

export type AffiliateGroup = {
	id: string;
	telegramId: string;
	title: string;
	photoUrl: string;
	totalTransactions: number;
	totalTokensEarned: AffiliateTokenInfo[];
	totalMembers: number;
	affiliatePercentage: string;
	feeDetail?: { tokens: ITokenSearch[]; totalUsd: number };
};

export type AffiliateGroupInfo = {
	totalPage: number;
	currentPage: number;
	// custom
	items: AffiliateGroup[];
	outOfData: boolean;
};

export type AffiliateUser = {
	id: string;
	telegramId: string;
	name: string;
	photoUrl: string;
	totalTransactions: number;
	totalTokensEarned: AffiliateTokenInfo[];
	dateJoined: string; // Assuming date format will be validated separately, keeping as string for flexibility
	affiliatePercentage: string;
};

export type AffiliateUserInfo = {
	totalPage: number;
	currentPage: number;
	// custom
	items: AffiliateUser[];
	outOfData: boolean;
};

export type AffiliateTokenInfo = {
	tobiId: string;
	chainId: ChainId;
	amount: string; // decimals amount
	totalTransactions: number;
};

export type AffiliateGroupFilterItem = { chatId: string; photoUrl: string; title: string };
export type AffiliateUserFilterItem = { id: string; name: string; username: string; photoUrl: string };
export type AffiliateTokenFilerParams = {
	communityIds?: string[];
	userIds?: string[];
};

const formatData = (resp, key: 'groups' | 'users') => {
	if (resp) {
		resp.items = resp[key];
		resp.outOfData = resp.currentPage?.toString() === resp.totalPage?.toString();
	}
	return resp;
};

const populateUsdInfo = async (data: AffiliateUserInfo | AffiliateGroupInfo, promises) => {
	const feeDetails = await promises;
	return {
		...data,
		items: data.items
			.map((e, i) => {
				const feeDetail = feeDetails[i].status === 'fulfilled' ? feeDetails[i].value : null;
				return { ...e, feeDetail };
			})
			.sort((a, b) => b.feeDetail?.totalUsd - a.feeDetail?.totalUsd),
	};
};

class AffiliateService {
	async getFeeInfo(): Promise<AffiliateFeeInfo> {
		const data = await axiosBotAPI.get('/affiliate/total-fees-earned', {});
		return data.data;
	}
	async getGroupStat(): Promise<AffiliateStatInfo> {
		const data = await axiosBotAPI.get('/affiliate/groups', {});
		return data.data;
	}

	calculateAffiliateTokenInfo = async (
		payload: AffiliateTokenInfo[],
	): Promise<{ tokens: ITokenSearch[]; totalUsd: number }> => {
		const tokens = await BffServiceAPI.searchExactListTokens({ query: payload });
		let totalUsd = 0;
		const mapTokens = tokens
			.map((e) => {
				const token = payload.find((t) => getTokenId(t) === getTokenId(e));
				const { priceUsd = 0 } = getTokenInfo(e);
				const usdValue = priceUsd * +token.amount;
				totalUsd += usdValue;
				return { ...e, totalTransactions: token.totalTransactions, usdValue, amount: token.amount };
			})
			.filter(Boolean)
			.sort((a, b) => b.usdValue - a.usdValue);
		return {
			tokens: mapTokens,
			totalUsd,
		};
	};

	async getGroupInfo(params: { page: number; limit: number }): Promise<AffiliateGroupInfo> {
		const data = await axiosBotAPI.get('/affiliate/groups/list', { params });
		const format: AffiliateGroupInfo = formatData(data.data, 'groups');
		const feeDetails = Promise.allSettled(
			format.items.map((e) => this.calculateAffiliateTokenInfo(e.totalTokensEarned)),
		);
		return populateUsdInfo(format, feeDetails);
	}
	async getUserStat(): Promise<AffiliateStatInfo> {
		const data = await axiosBotAPI.get('/affiliate/users', {});
		return data.data;
	}
	async getUserInfo(params: { page: number; limit: number }): Promise<AffiliateUserInfo> {
		const data = await axiosBotAPI.get('/affiliate/users/list', { params });
		const format: AffiliateUserInfo = formatData(data.data, 'users');
		const feeDetails = Promise.allSettled(
			format.items.map((e) => this.calculateAffiliateTokenInfo(e.totalTokensEarned)),
		);
		return populateUsdInfo(format, feeDetails);
	}

	async getTokensInfo(params: AffiliateTokenFilerParams): Promise<{ tokens: AffiliateTokenInfo[] }> {
		const data = await axiosBotAPI.get('/affiliate/tokens', { params });
		return data.data;
	}

	async getGroupFilter(params: {
		communityIds?: string;
		userIds?: string;
	}): Promise<{ groups: AffiliateGroupFilterItem[] }> {
		const data = await axiosBotAPI.get('/affiliate/communities', { params });
		return data.data;
	}
	async getUserFilter(params: {
		communityIds?: string;
		userIds?: string;
	}): Promise<{ users: AffiliateUserFilterItem[] }> {
		const data = await axiosBotAPI.get('/affiliate/referred-users', { params });
		return data.data;
	}
}

export const AffiliateServiceAPI = new AffiliateService();

import { ITransactionStatus } from '@/app-types';
import { FiCheckIcon, FiClockIcon, FiWaringIcon } from '@/assets/icons';

export const EMPTY_ARRAY: any = [];

const colorSuccess = { bgColor: '#00C170', icon: <FiCheckIcon fill="white" /> };

export const STATUS_BADGE_CONFIG: Record<
	string,
	{
		bgColor: string;
		icon: React.ReactNode;
	}
> = {
	[ITransactionStatus.PROCESSING]: {
		bgColor: '#FF4D00',
		icon: <FiClockIcon fill="white" />,
	},
	[ITransactionStatus.SUCCESS]: colorSuccess,
	[ITransactionStatus.CANCEL_SUCCESS]: colorSuccess,
	[ITransactionStatus.ERROR]: {
		bgColor: '#E32A2A',
		icon: <FiWaringIcon fill="white" />,
	},
	[ITransactionStatus.CANCELING]: {
		bgColor: '#FF4D00',
		icon: <FiClockIcon fill="white" />,
	},
	[ITransactionStatus.CANCEL_ERROR]: {
		bgColor: '#E32A2A',
		icon: <FiWaringIcon fill="white" />,
	},
};

export const QUERY_KEYS = {
	GET_USER_SETTING_TOKEN: 'get-setting-tokens',
	GET_PORTFOLIO_BALANCE: 'portfolio-balance',
	GET_TOKEN_ALLOWANCE: 'allowance',
	GET_CLAIM_TESTNET_STATUS: 'get-status-claim-testnet',
	GET_TOBI_FARM_POINT: 'get-farm-points',

	GET_WALLET_ACTIVITY: 'use-wallet-activities',
	GET_WALLET_ACTIVITY_DETAIL: 'use-wallet-activity-detail',
	GET_INFURA_FEE_DATA: 'get-infura-fee',
	GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',

	GET_LIMIT_ORDERS: 'get-limit-orders',
	GET_LIMIT_ORDERS_ACTIVE_AMOUNT: 'get-limit-orders-active-amount',
	GET_TOTAL_LIMIT_ORDER: 'get-total-limit-order',

	GET_ROUTE_SWAP: 'fetch-route-swap',

	GET_REQ_PAYMENTS: 'get-req-payment',
	GET_CONTACT_LIST: 'get-contact-list',

	GET_WALLET_ACTIVITIES: 'wallet-activities',
	GET_SWAP_FEE: 'get-swap-fee',

	GET_FLAGS: 'feature-config',

	GET_MY_CREATED_MEMES: 'get-created-memes',
	GET_MY_MEME_BALANCE: 'get-balance-memes',
};

export const ELEMENT_IDS = {
	HOME_CONTAINER: 'containerHome',
};

export const TOBI_SUPPORT_BOT_LINK = 'https://t.me/Tobiapp_SupportBot';
export const COMMUNITY_LINK = 'https://t.me/TobiCommunity';

export const IOS_KEYBOARD_HEIGHT = 310;

export enum InputMode {
	AMOUNT = 'Token',
	USD = 'USD',
}

import { RequireWallet } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { Box, Center, Skeleton } from '@chakra-ui/react';
import { CSSProperties, ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Global } from './Global';
import { Activity } from './Header/Activity';
import { GlobalSearch } from './Header/GlobalSearch';
import { QRScanner } from './Header/QRScanner';
import { UserAvatarOrHome } from './Header/UserAvatarOrHome';
import { WalletAddress } from './Header/WalletAddress';
import { WalletStatus } from './WalletStatus';

export const AppHeader = ({
	nodes,
	style,
	activity,
	search = true,
}: {
	nodes?: ReactNode;
	style?: CSSProperties;
	activity?: boolean;
	search?: boolean;
}) => {
	return (
		<Center
			gap={1}
			pb={3}
			px={4}
			position="sticky"
			top={3}
			left={0}
			zIndex={10}
			backgroundColor="#ebeff6"
			sx={style}
		>
			<UserAvatarOrHome />
			{search && <GlobalSearch style={{ flex: 1 }} />}
			{nodes}
			{activity && <Activity />}
		</Center>
	);
};

export const AppLayout = () => {
	const { pathname } = useLocation();
	const isShow = [
		NAVIGATE_PATHS.Home,
		NAVIGATE_PATHS.Affiliate.Main,
		NAVIGATE_PATHS.Wallet.Wallet,
		NAVIGATE_PATHS.AccountSettings.Menu,
	].includes(pathname);

	const renderActionsHeader = () => {
		return (
			<>
				<RequireWallet
					loadingComponent={<Skeleton height="48px" width="48px" startColor="white" endColor="#EBEFF6" />}
				>
					<QRScanner />
				</RequireWallet>
				<RequireWallet
					loadingComponent={<Skeleton height="48px" width="48px" startColor="white" endColor="#EBEFF6" />}
				>
					<WalletAddress />
				</RequireWallet>
				<RequireWallet
					loadingComponent={<Skeleton height="48px" width="48px" startColor="white" endColor="#EBEFF6" />}
				>
					<Activity />
				</RequireWallet>
			</>
		);
	};
	return (
		<Box position="relative" pt={getFullScreenSpace(0, 100)}>
			<WalletStatus />
			{isShow && <AppHeader nodes={renderActionsHeader()} />}
			<Global />
			<Outlet />
		</Box>
	);
};

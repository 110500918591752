import { ServiceUser } from '@/app-cores/api';
import { TelegramCore } from '@/app-cores/telegram';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ONE_HOUR, ONE_MINUTE } from '../portfolio/constant';

export const useQueryUserProfile = () => {
	const response = useQuery({
		queryKey: ['query-user-profile', TelegramCore.getUserId()],
		queryFn: async () => {
			return await ServiceUser.getUserProfile();
		},
		placeholderData: keepPreviousData,
		staleTime: ONE_HOUR,
	});

	return response;
};

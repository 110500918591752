import { Address, Cell, OpenedContract, beginCell, external, storeMessage } from '@ton/core';
import { getDecimalCount } from '../token';
import BigNumber from 'bignumber.js';
import { WalletContractV4 } from '@/app-cores/mpc-wallet/ton/WalletContractV4';
import { TonAddressState } from '@/app-cores/api/toncenter/type';

export const jettonTransferBody = async (params: {
	jettonAmount: bigint;
	toAddress: Address;
	responseAddress: Address;
	forwardAmount: bigint;
	forwardPayload: Cell | null;
}) => {
	return beginCell()
		.storeUint(0xf8a7ea5, 32) // request_transfer op
		.storeUint(0, 64)
		.storeCoins(params.jettonAmount)
		.storeAddress(params.toAddress)
		.storeAddress(params.responseAddress)
		.storeBit(false) // null custom_payload
		.storeCoins(params.forwardAmount)
		.storeMaybeRef(params.forwardPayload) // storeMaybeRef put 1 bit before cell (forward_payload in cell) or 0 for null (forward_payload in slice)
		.endCell();
};

export const jettonForwardPayload = (message: string = '') => {
	return beginCell()
		.storeUint(0, 32) // 0 opcode means  have a comment
		.storeStringTail(message)
		.endCell();
};

export function getTonCoinTransferBalance(amount: string, decimals: number) {
	const decimal = getDecimalCount(amount, decimals);
	if (!amount) return 0n;
	const numBig = new BigNumber(amount);
	const amountRounded = BigNumber(numBig.toFixed(decimal, BigNumber.ROUND_DOWN));
	return BigInt(amountRounded.multipliedBy(Math.pow(10, decimals)).toString());
}

export const buildExternalMessage = ({
	contract,
	seqno,
	body,
}: {
	contract: OpenedContract<WalletContractV4>;
	seqno: number;
	body: Cell;
}) => {
	return beginCell()
		.storeWritable(
			storeMessage(
				external({
					to: contract.address.toString({ bounceable: false }),
					init: seqno === 0 ? contract.init : undefined,
					body: body,
				}),
			),
		)
		.endCell();
};

export const getFinalTonGasFee = (fee: bigint, bufferPercent = 20n) => {
	return fee + (fee * bufferPercent) / 100n;
};

export const seeIfAddressBounceable = (address: string) => {
	return Address.isFriendly(address) ? Address.parseFriendly(address).isBounceable : true;
};

export const seeIfTransferBounceble = (receiptAddress, receiptAddressStatus: TonAddressState) => {
	if (!seeIfAddressBounceable(receiptAddress)) {
		return false;
	}

	return receiptAddressStatus === 'active';
};

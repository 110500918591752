import { QUERY_KEYS } from '@/app-constants';
import { BffServiceAPI, ITokenSearch } from '@/app-cores/api/bff';
import { getTokenInfo } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useBalanceStore } from '@/app-store/balance';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export const useUserTokenSettings = ({
	type,
}: {
	type?: 'hidden' | 'show'; // spam but force show :))
}) => {
	const { setHideTokens, setForceShowTokens, hideTokens, forceShowTokens } = useBalanceStore();
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_USER_SETTING_TOKEN, type ?? null],
		queryFn: async () => {
			return BffServiceAPI.getPortfolioBalanceByType(type).then((data) => {
				switch (type) {
					case 'hidden':
						setHideTokens(data);
						break;
					case 'show':
						setForceShowTokens(data);
						break;
				}
				return data;
			});
		},
		placeholderData: (args) => {
			switch (type) {
				case 'hidden': {
					if (hideTokens?.length) return hideTokens;
					break;
				}
				case 'show':
					if (forceShowTokens?.length) return forceShowTokens;
					break;
			}
			return keepPreviousData(args);
		},
		retry: false,
	});
	return response;
};

export type TokenLabelType = 'spam' | 'hidden' | 'imported' | 'testnet';

export const useGetTokenLabels = () => {
	const { data } = usePortfolioBalanceByCategories();
	return useCallback(
		(token: ITokenSearch): TokenLabelType[] => {
			const { spamTokens, importTokens, hiddenTokens, testnetTokens } = data || {};
			const tobiId = getTokenInfo(token).idTobi;

			const findToken = (list: ITokenSearch[] = []) =>
				!!list?.some((e) => {
					return tobiId === getTokenInfo(e).idTobi;
				});

			const labels = [];
			if (findToken(spamTokens)) labels.push('spam');
			if (findToken(hiddenTokens)) labels.push('hidden');
			if (findToken(testnetTokens)) labels.push('testnet');
			if (findToken(importTokens)) labels.push('imported');
			return labels;
		},
		[data],
	);
};

export const useTokenLabels = (payload: ITokenSearch) => {
	const getTokenLabels = useGetTokenLabels();
	return useMemo(() => getTokenLabels(payload), [getTokenLabels, payload]);
};

import { ChainId } from '@/app-constants/chains';
import { PumpToken } from '@/app-services/pump.fun';

type Core = {
	id: number;
	name: string;
	coreId: number;
};

type MetadataItem = {
	value: string;
	trait_type: string;
};

type ContributionMeta = {
	commit: {
		s3: string;
		uid: string;
		filename: string;
		metadata: {
			filename: string;
			contentType: string;
		}[];
		contentType: string;
	};
	coreId: number;
	metadata: {
		title: string;
		packageName: string;
		packageBetter: string;
	};
	coreServiceId: number;
};

type SocialLinks = {
	DISCORD: string;
	TWITTER: string;
	WEBSITE: string;
	YOUTUBE: string;
	TELEGRAM: string;
};

type Image = {
	id: number;
	url: string;
};

type UserSocial = {
	id: number;
	walletAddress: string;
};

type Creator = {
	id: number;
	username: string;
	email: string;
	displayName: string | null;
	userSocials: UserSocial[];
};

export type VirtualToken = {
	id: number;
	uid: string;
	name: string;
	metadata: Record<string, MetadataItem>;
	status: string;
	tokenAddress: string | null;
	tbaAddress: string | null;
	daoAddress: string | null;
	configUrl: string;
	description: string;
	category: string;
	virtualId: string | null;
	personaTxnHash: string | null;
	txnHash: string | null;
	personaProposalId: string | null;
	proposalId: string | null;
	virtualLocked: boolean | null;
	hasPlayground: boolean;
	createdAt: string;
	updatedAt: string;
	veTokenAddress: string | null;
	lpAddress: string | null;
	symbol: string;
	isPublic: boolean;
	proxyKey: string | null;
	contributionMeta: ContributionMeta[];
	walletAddress: string;
	coreServices: unknown | null;
	faKey: string | null;
	premiumCharge: number;
	totalValueLocked: string | null;
	role: string;
	priority: number;
	tgName: string | null;
	tgStatus: string;
	virtualTokenValue: string;
	isPremium: boolean;
	factory: string;
	tgGoal: string | null;
	twitterGoal: string | null;
	twitterStatus: boolean;
	twitterPostFrequency: string | null;
	twitterPostFrequencyUnit: string | null;
	forumStatus: boolean;
	forumPostFrequency: string | null;
	forumPostFrequencyUnit: string | null;
	preToken: string;
	preTokenTx: string;
	preTokenPair: string;
	twitterHandle: string | null;
	holderCount: number | null;
	livestreamStatus: boolean;
	memeGenStatus: boolean;
	sentientStatus: boolean;
	musicGenStatus: boolean;
	aidesc: string | null;
	firstMessage: string | null;
	mcapInVirtual: string | null;
	socials: {
		USER_LINKS: SocialLinks;
	};
	sentientWalletAddress: string | null;
	image: Image;
	cores: Core[];
	creator: Creator;
	// portfolio
	price_24h_change?: number;
	amount?: number;
	price?: number;
};

type Virtual = {
	id: number;
	name: string;
	image: {
		url: string;
	};
};

type ParentMessage = Record<string, unknown>;

export type VirtualMessage = {
	id: number;
	text: string;
	createdAt: string;
	virtual: Virtual;
	user: {
		walletAddress: string | null;
		displayName: string | null;
	};
	parentMessage: ParentMessage;
};

export type VirtualTokenList = VirtualToken[];
export type MemeToken = PumpToken | VirtualToken;

export enum MemePlatform {
	PUMP_FUN = 'pump.fun',
	VIRTUALS_IO = 'virtuals.io',
}

export type MemeTokenInfo = {
	id: string;
	name: string;
	symbol: string;
	decimals: number;
	complete: boolean;
	logo: string;
	createdTime: number;
	creator: string;
	marketCapUsd: number;
	address: string;
	description: string;
	uri: string;
	bondingCurve: string;
	chainId: ChainId | string;
	platform: MemePlatform;
	platformLogo: string;
	poolLink: string;
	twitter: string;
	telegram: string;
	website: string;
	dex: 'Raydium' | 'Uniswap';

	assetTobiId: string; //buy via sol/virtual

	// for portfolio
	usdValue?: number;
	amount?: number;
	price?: number;
	percentChange24h?: number;
};

export type MemeChat = {
	address: string;
	avatar: string;
	timestamp: number;
	text: string;
	id: string;
};

import { QUERY_KEYS } from '@/app-constants';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { ServiceConfiguration } from '@/app-cores/api/bot/service-configuration';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { ONE_MINUTE } from '../portfolio/constant';

export enum FeatureConfig {
	BALANCE_ANALYTIC = 'balance_analytics',
	TRENDING_TOKEN = 'trending_token',
	TRADING_TOKEN = 'trading_token',
	IMPORT_TOKEN = 'import_token',
	DAPP_HOMEPAGE = 'dapp_homepage',
	DAPP_SETTING = 'dapp_setting',
	INVITATION_CODE = 'invitation_code',
	TRANSAK_DEPOSIT = 'transak_deposit',
	CANCEL_PENDING_TRANSACTIONS = 'cancel_pending_transactions',
	ROCKETX_IP_BLOCKING = 'rocketx_ip_blocking',
	DEBRIDGE_IP_BLOCKING = 'debridge_ip_blocking',
	MAINTENANCE_NOTICE = 'maintenance_notice',
	WALLET_CONNECT_TEST = 'wallet_connect_test',
	DAPP_BROWSER_SEARCH_BAR = 'dapp_browser_search_bar',
	CONNECT_BUTTON_ON_DESKTOP = 'connect_button_on_desktop',
	CONNECT_BUTTON_ON_MOBILE = 'connect_button_on_mobile',
	DAPP_BROWSER = 'dapp_browser',
	SHOW_DAPP_ON_ANDROID_DEVICE = 'show_dapp_on_android_device',
	RECOVERY_MY_ACCOUNT = 'recovery_my_account',
	ALLOW_CONNECT_DAPP_WITH_URI = 'allow_connect_dapp_with_uri',
	LIMIT_ORDER = 'limit_order',
	BERA_TESTNET_SUPPORT = 'bera_testnet_support',
	TOBI_FARM = 'tobi_farm',
	IN_APP_COPILOT = 'in_app_copilot',
	TOBI_HOME_WORK_THROUGH = 'tobi_home_work_through',
	TOBI_TRADE_WORK_THROUGH = 'tobi_trade_work_through',
	TELEGRAM_BACKUP = 'telegram_backup',
	REQUEST_FUND = 'request_fund',
	BACKUP_G_DRIVE = 'backup_gg_drive',
	RECOVERY_G_DRIVE = 'recovery_gg_drive',
	DISABLE_SUI_NETWORK = 'disable_sui_network',
	DISABLE_ECLIPSE_NETWORK = 'disable_eclipse_network',
	FEE_EARN = 'fee_earn',
	MEMEPAD = 'memepad',
	TOBI_DISABLE_ORBS_RPC = 'tobi_disable_orbs_rpc',
	TOBI_DISABLE_TON_X_RPC = 'tobi_disable_tonx_rpc',
	DISABLE_MANTLE_NETWORK = 'disable_mantle_network',
	ENABLE_STORY_TESTNET_NETWORK = 'enable_story_testnet_network',
}

const useConfig = () => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_FLAGS],
		queryFn: async () => {
			return ServiceConfiguration.getFeatureConfig() as unknown as Record<FeatureConfig, boolean>;
		},
		staleTime: ONE_MINUTE * 10,
	});
};

export const useQueryFeatureConfig = (key: FeatureConfig) => {
	const { data } = useConfig();
	return !!data?.[key];
};

export const useQueryAppConfigs = () => {
	const { data, isLoading } = useConfig();
	useEffect(() => {
		if (isEmpty(data)) return;
		localStorage.setItem(
			STORAGE_KEYS.TOBI_DISABLE_ORBS_RPC,
			data?.[FeatureConfig.TOBI_DISABLE_ORBS_RPC] ? '1' : '0',
		);
		localStorage.setItem(
			STORAGE_KEYS.TOBI_DISABLE_TON_X_RPC,
			data?.[FeatureConfig.TOBI_DISABLE_TON_X_RPC] ? '1' : '0',
		);
	}, [data]);

	return {
		isEnableMaintenance: data?.[FeatureConfig.MAINTENANCE_NOTICE],
		isLoading: isLoading,
	};
};

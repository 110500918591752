import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { TobiFun } from '@/app-views/home/containers/TobiFun';
import { Container, Flex } from '@chakra-ui/react';
import { Portfolio } from './containers/Portfolio';
import { Rewards } from './containers/Rewards';
import { Swap } from './containers/Swap';
import { TrendingTabs } from './containers/Trending';
// import { Introduction } from '../introduction';
import { TelegramCore } from '@/app-cores/telegram';
import { getDisplayedTobiIntro } from '@/app-helpers/display';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { Introduction } from '../introduction';

export const HomePage = () => {
	const [isShowIntro, setIsShowIntro] = useState(() => !getDisplayedTobiIntro());
	return (
		<PageNavigation backable={false}>
			{isShowIntro ? (
				<Introduction onDone={() => setIsShowIntro(false)} />
			) : (
				<Container className="overflow-y-auto hide-scrollbar" pb={getFullScreenSpace(80, 86)}>
					<Flex flexDirection="column" gap={3} mt={isDesktop || !TelegramCore.WebApp.isFullscreen ? 3 : 0}>
						<Flex gap={3}>
							<Portfolio />
							<Rewards />
						</Flex>
						<TobiFun />
						<Swap />
						<TrendingTabs />
					</Flex>
				</Container>
			)}
		</PageNavigation>
	);
};

import { getShortAddress } from '@/app-helpers/address';
import { formatTimeRecent } from '@/app-helpers/time';
import { MemeChat, MemeTokenInfo } from '@/app-services/virtual/type';
import { colors } from '@/app-theme/theme';
import { Avatar, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const maxLength = 300;
export default function CommentItem({ data }: { data: MemeChat; token: MemeTokenInfo }) {
	const { address, text, avatar, timestamp } = data;

	const [expand, setExpand] = React.useState(text.length < maxLength);
	return (
		<Flex w={'100%'} direction={'column'} fontSize={'12px'} gap={2}>
			<Flex justify={'space-between'}>
				<Flex fontSize={'12px'} gap={1} align={'center'}>
					<Avatar src={avatar} width={'16px'} height={'16px'} />
					<Text>{getShortAddress(address)}</Text>
				</Flex>
				<Text color={colors.gray[300]}>{formatTimeRecent(timestamp)}</Text>
			</Flex>
			<Flex justify={'space-between'}>
				<Text>
					{expand ? text : `${text.substring(0, maxLength)}...`}{' '}
					{text.length > maxLength && (
						<Text as="span" fontWeight={'500'} color={colors.cyan[400]} onClick={() => setExpand(!expand)}>
							{!expand ? 'See more' : 'See less'}
						</Text>
					)}
				</Text>
			</Flex>
		</Flex>
	);
}

import { QUERY_KEYS } from '@/app-constants';
import { queryClient } from '@/app-cores/query-client';
import { useMutation } from '@tanstack/react-query';
import { AddRemoveTokenPayload, ServiceToken } from '../../../app-cores/api/bot/service-token';

const onSuccess = () => {
	setTimeout(() => {
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_USER_SETTING_TOKEN],
		});
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
		});
	}, 500);
};

export const useMutationHideUnhideToken = () => {
	const response = useMutation({
		mutationKey: ['mutation-add-user-tokens'],
		mutationFn: (payload: AddRemoveTokenPayload) => ServiceToken.hideUnHideTokens(payload),
		onSuccess,
	});
	return response;
};

import { LocalLoader } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { Balance } from '@/app-components/common/Balance';
import NoData from '@/app-components/common/NoData';
import { AppHeader } from '@/app-components/layout/AppLayout';
import PageLayout from '@/app-components/layout/PageLayout';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TelegramCore } from '@/app-cores/telegram';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { toQueryString } from '@/app-helpers/url';
import { useGetCoinsByWallet, useGetPortfolioMeme, useMemeMarketCapData } from '@/app-hooks/api/memepad';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { useAppState } from '@/app-store/app';
import { colors } from '@/app-theme/theme';
import ListTokens from '@/app-views/affiliate/containers/FeeEarn/ListTokens';
import { PriceChange } from '@/app-views/portfolio/pages/token/components/Balance';
import { MemeTokenItem } from '@/app-views/tobi-fun/containers/MemeItem';
import {
	Card,
	CardBody,
	Flex,
	ListItem,
	Skeleton,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	VStack,
} from '@chakra-ui/react';
import { truncate } from 'lodash';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const WrapListCryptoItem = ({ data, index, style }: { data: MemeTokenInfo[]; index: number; style: CSSProperties }) => {
	const token = data[index];
	const {
		chainId,
		amount: balance,
		usdValue,
		price: usdPrice,
		symbol,
		logo: imageUrl,
		name,
		percentChange24h: value24hChange,
		address,
		marketCapUsd,
	} = token;

	const { data: onChain, isFetching } = useMemeMarketCapData(token);
	const { memePlatform } = useAppState();

	const navigate = useNavigate();
	const handleSelectToken = () => {
		navigate(toQueryString(NAVIGATE_PATHS.TobiFun.Detail, { mint: address, chainId, platform: memePlatform }));
	};

	const usdPriceText = formatUsd(usdPrice);
	const myBalanceUsd = usdValue ? formatUsd(usdValue) : null;
	const myTokenBalance = formatCurrency(balance);

	return (
		<ListItem cursor={'pointer'} onClick={handleSelectToken} style={style} maxWidth={'100%'} userSelect={'none'}>
			<Flex justifyContent="space-between" align={'center'} height={'100%'}>
				<Flex gap={'12px'} alignItems={'center'} maxWidth={'60%'}>
					<TokenLogo
						tokenStyle={{ background: colors.gray[100] }}
						size={33}
						symbol={symbol}
						logo={imageUrl}
						chainId={chainId}
					/>
					<Flex
						gap={'4px'}
						flexDirection={'column'}
						height={'42px'}
						justifyContent={usdPriceText ? 'space-between' : 'center'}
					>
						<Flex alignItems={'center'} gap={'6px'}>
							<Text
								as={'span'}
								fontWeight={600}
								fontSize={'13px'}
								lineHeight={'18px'}
								whiteSpace={'nowrap'}
							>
								{truncate(name || symbol || '--', { length: 24 })}
							</Text>
						</Flex>
						<Flex
							align={'center'}
							fontSize="xs"
							whiteSpace={'nowrap'}
							gap={'4px'}
							color={'gray.400'}
							fontWeight={'500'}
						>
							Mcap:{' '}
							{isFetching ? (
								<Skeleton
									opacity="0.3"
									borderRadius={'4px'}
									style={{ height: '12px', width: '60px' }}
								/>
							) : (
								formatUsd(onChain?.marketCap || marketCapUsd)
							)}
						</Flex>
					</Flex>
				</Flex>
				<Flex
					flex={1}
					flexDirection={'column'}
					alignItems={'flex-end'}
					gap={'4px'}
					fontWeight={'500'}
					justifyContent={'space-between'}
				>
					<Flex gap={2} as="span" fontWeight={500} fontSize="12px">
						{myBalanceUsd} <PriceChange price={value24hChange} hideWhenZero />
					</Flex>
					<Text fontSize="xs" whiteSpace={'nowrap'} color={'gray.400'}>
						{myTokenBalance} {truncate(symbol, { length: 14 })}
					</Text>
				</Flex>
			</Flex>
		</ListItem>
	);
};

const props = {
	fontSize: 'xs',
	fontWeight: 600,
	color: colors.gray[300],
	_selected: {
		color: 'black',
		borderColor: 'black',
	},
};

const Content = ({ children, data, isFetching }) => {
	const { t } = useTranslation();
	return (
		<Flex direction={'column'} gap={3} pb={6}>
			{data?.length ? (
				children
			) : isFetching ? (
				<LocalLoader height="150px" />
			) : (
				<NoData msg={t('cryptos.noTokenInThisList')} />
			)}
		</Flex>
	);
};

enum TabType {
	Portfolio,
	Created,
}
export default function TobiFunProfile() {
	const [tab, setTab] = useState(TabType.Portfolio);
	const { data, isFetching: isFetchingCoinCreated } = useGetCoinsByWallet(tab === TabType.Created);
	const { t } = useTranslation();
	const { data: portfolio, isFetching } = useGetPortfolioMeme();

	return (
		<PageLayout
			header={
				<AppHeader
					activity
					style={{ paddingBottom: 0, marginTop: !TelegramCore.WebApp.isFullscreen ? '12px' : '0px' }}
				/>
			}
		>
			<VStack spacing={3}>
				<Balance amount={portfolio?.totalUsd} />
				<Tabs isLazy w={'100%'} index={tab} onChange={setTab}>
					<TabList borderBottom="1px" borderColor="rgba(0, 0, 0, 0.08)">
						<Tab {...props}>{t('cryptos.myPortfolio')}</Tab>
						<Tab {...props}>Created</Tab>
					</TabList>
					<TabPanels>
						<TabPanel px={0}>
							<Content isFetching={isFetching} data={portfolio?.tokens}>
								<Card borderRadius={'8px'}>
									<CardBody px={4} py={2}>
										<ListTokens<MemeTokenInfo>
											itemRender={WrapListCryptoItem}
											balances={portfolio?.tokens}
											isLoading={isFetching}
										/>
									</CardBody>
								</Card>
							</Content>
						</TabPanel>
						<TabPanel px={0}>
							<Content data={data} isFetching={isFetchingCoinCreated}>
								{data?.map((e) => (
									<MemeTokenItem key={e.address} data={e} label />
								))}
							</Content>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</VStack>
		</PageLayout>
	);
}


			const isProd = import.meta.env.VITE_ENVIRONMENT === 'prod';
			window?.Telegram?.WebApp?.expand();
			window?.Telegram?.WebApp?.enableClosingConfirmation();
			window?.Telegram?.WebApp?.enableVerticalSwipes();
			import('react-device-detect')
				.then((device) => {
					if (device.isIOS) {
						window?.Telegram?.WebApp?.requestFullscreen();
					}
				})
				.catch((err) => {
					console.error('Failed to load the react-device-detect library:', err);
				});

			if (!isProd && window.location.host !== 'telifi.local:3001') {
				import('eruda')
					.then((module) => {
						const eruda = module.default;
						eruda?.init();
					})
					.catch((err) => {
						console.error('Failed to load the eruda library:', err);
					});
			}
		
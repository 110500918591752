import { CopyToClipboard } from '@/app-components/common';
import { ChainLogo } from '@/app-components/common/Avatar/TokenLogo';
import { ITokenSearch } from '@/app-cores/api/bff';
import { getShortAddress } from '@/app-helpers/address';
import { formatUsd } from '@/app-helpers/number';
import { getTokenInfo, isSolanaChain } from '@/app-helpers/token';
import { useMemeMarketCapData } from '@/app-hooks/api/memepad';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

export default function TokenInformation({
	memeInfo,
	token,
}: {
	memeInfo: MemeTokenInfo | undefined;
	token: ITokenSearch;
}) {
	const { volume24hUsd, priceUsd, marketCapUsd: marketCap } = getTokenInfo(token);
	const { address, marketCapUsd: mCap, chainId } = memeInfo ?? {};

	const { data: onChain } = useMemeMarketCapData(isSolanaChain(chainId) ? null : memeInfo);
	const marketCapUsd = mCap || marketCap || onChain?.marketCap;
	const fields = useMemo(
		() => [
			{
				name: 'Contract Address',
				value: (
					<Flex gap={2} align={'center'}>
						<ChainLogo chainId={chainId} style={{ width: '16px' }} />
						<CopyToClipboard text={address}>{getShortAddress(address)}</CopyToClipboard>
					</Flex>
				),
			},
			{ name: 'Price', value: priceUsd, dollar: true },
			{ name: 'Market cap', value: marketCapUsd, dollar: true },
			{ name: 'Volume (24h)', value: volume24hUsd, dollar: true },
		],
		[address, priceUsd, volume24hUsd, marketCapUsd, chainId],
	);

	return (
		<Card>
			<CardBody p={4}>
				<Flex flexDirection={'column'} gap={'12px'}>
					{fields.map((el) => {
						if (!el.value) return null;
						return (
							<Flex justifyContent={'space-between'} fontSize={'xs'} key={el.name}>
								<Text as="span">{el.name}</Text>
								<Text as="b">
									{!el.value
										? '--'
										: typeof el.value === 'number' || typeof el.value === 'string'
										? formatUsd(el.value, { showDollar: el.dollar })
										: el.value}
								</Text>
							</Flex>
						);
					})}
				</Flex>
			</CardBody>
		</Card>
	);
}

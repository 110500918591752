import { QUERY_KEYS } from '@/app-constants';
import { ChainId, VIRTUAL_TOKEN_ADDRESS, VIRTUAL_TOKEN_DECIMALS } from '@/app-constants/chains';
import { axiosPumpFun } from '@/app-cores/api/axios';
import { BffServiceAPI } from '@/app-cores/api/bff';
import { ChartData } from '@/app-cores/api/bff/chart';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getNativeToken, getTokenInfo, isSolanaChain } from '@/app-helpers/token';
import { ONE_HOUR, ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import MemePadService from '@/app-services/memepad';
import { ListMemeType } from '@/app-services/memepad/const';
import {
	MemeOnChainData,
	PumpChartData,
	PumpFunSDK,
	PumpHolder,
	PumpToken,
	PumpTrade,
	TokenMetadata,
} from '@/app-services/pump.fun';
import VirtualService from '@/app-services/virtual';
import { MemeChat, MemePlatform, MemeTokenInfo } from '@/app-services/virtual/type';
import { useAppState } from '@/app-store/app';
import { MEME_TOKEN_TOTAL_SUPPLY } from '@/app-views/tobi-fun/helpers';
import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ethers, formatUnits, parseUnits } from 'ethers';

export const useMemeMetaData = (uri: string, chainId: ChainId | string) => {
	const response = useQuery({
		queryKey: ['get-meme-metadata-info', uri, chainId],
		queryFn: async (): Promise<TokenMetadata> => {
			const data = await axios.get(uri);
			return data.data;
		},
		enabled: !!uri && isSolanaChain(chainId),
		gcTime: ONE_HOUR,
		staleTime: ONE_HOUR,
	});
	return response;
};
export const useMemeMarketCapData = (token: MemeTokenInfo | null) => {
	const { address: tokenAddress, chainId, platform, assetTobiId, complete } = token ?? {};
	const response = useQuery({
		queryKey: ['get-meme-market-info', tokenAddress, chainId],
		queryFn: async (): Promise<MemeOnChainData> => {
			const getMCapBackend = async () => {
				const token = await BffServiceAPI.searchTokens({
					query: tokenAddress,
					chainId: ChainId.BASE,
					limit: 1,
				});
				if (token.length === 0) return 0;
				const { marketCapUsd } = getTokenInfo(token[0]);
				return +marketCapUsd;
			};
			if (complete) {
				switch (platform) {
					case MemePlatform.VIRTUALS_IO:
						return { marketCap: await getMCapBackend() };
					case MemePlatform.PUMP_FUN:
						return { marketCap: 0 };
				}
			}
			const getMCap = async () => {
				switch (platform) {
					case MemePlatform.PUMP_FUN: {
						const sdk = await PumpFunSDK.create();
						const bonding = await sdk.getBondingCurveAccount(new PublicKey(tokenAddress));
						const marketCap = await bonding.getMarketCapSOL();
						const formatMarketCap = +formatUnits(marketCap, getNativeToken(chainId).decimals);
						return formatMarketCap;
					}
					case MemePlatform.VIRTUALS_IO: {
						const mCap = await VirtualService.calcMarketCapVirtual(token);
						return mCap;
					}
				}
			};
			const [mCap, tokenSearch] = await Promise.all([
				getMCap(),
				BffServiceAPI.searchExactSingleToken({ tobiId: assetTobiId, chainId }),
			]);
			const { priceUsd } = getTokenInfo(tokenSearch);
			return { marketCap: mCap * priceUsd };
		},
		enabled: !!token && !!tokenAddress && !!chainId,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useMemeBondingCurvePercent = (memeInfo: MemeTokenInfo | undefined) => {
	const { address: tokenAddress, bondingCurve: curveAddress, complete, platform } = memeInfo ?? {};
	const response = useQuery({
		queryKey: ['useMemeBondingCurvePercent', tokenAddress, curveAddress],
		queryFn: async () => {
			switch (platform) {
				case MemePlatform.PUMP_FUN: {
					const sdk = await PumpFunSDK.create();
					return sdk.getBondingPercent(new PublicKey(tokenAddress), curveAddress);
				}
				case MemePlatform.VIRTUALS_IO: {
					return VirtualService.calcBondingPercent(memeInfo);
				}
			}
		},
		enabled: !complete && !!memeInfo,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useMemeBondingCurveInfo = (token: MemeTokenInfo) => {
	const { address, platform, bondingCurve, complete } = token ?? {};
	const response = useQuery({
		queryKey: ['useMemeBondingCurve', address, bondingCurve, platform],
		queryFn: async () => {
			switch (platform) {
				case MemePlatform.PUMP_FUN: {
					const sdk = await PumpFunSDK.create();
					if (!address) return;
					return sdk.getBondingCurveProgress(address);
				}
				case MemePlatform.VIRTUALS_IO: {
					if (!bondingCurve) return;
					return VirtualService.getBondingCurveProgress(token);
				}
			}
		},
		enabled: !complete && !!platform && (!!bondingCurve || !!address),
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useGetCoinsByWallet = (enabled: boolean) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_MY_CREATED_MEMES],
		queryFn: async (): Promise<MemeTokenInfo[]> => {
			try {
				const data = await Promise.allSettled([
					axiosPumpFun
						.get(
							`/coins/user-created-coins/${MpcWallet.getSolanaWalletAddress()}?offset=0&limit=50&includeNsfw=false`,
						)
						.then((data) => data.data.map((e) => MemePadService.formatToken(e, MemePlatform.PUMP_FUN))),
					VirtualService.getCreatedTokens(MpcWallet.getEvmWalletAddress()).then((data) =>
						data.map((e) => MemePadService.formatToken(e, MemePlatform.VIRTUALS_IO)),
					),
				]);
				const formatData = data.map((e) => (e.status === 'fulfilled' ? e.value : []));
				return formatData.flat();
			} catch (error) {
				return [];
			}
		},
		enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

const fetchVirtual = async () => {
	const data = await BffServiceAPI.searchTokens({
		query: VIRTUAL_TOKEN_ADDRESS,
		chainId: ChainId.BASE,
		limit: 1,
	});
	return data?.[0];
};
export const useGetPortfolioMeme = () => {
	const { evmAddress, solAddress } = MpcWallet.getWalletAddress();
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_MY_MEME_BALANCE, evmAddress, solAddress],
		queryFn: async () => {
			const response = await Promise.allSettled([
				VirtualService.getHoldTokens(MpcWallet.getEvmWalletAddress()),
				axiosPumpFun.get<{ data: PumpToken[] }>(
					`/balances/${MpcWallet.getSolanaWalletAddress()}?limit=50&offset=0&minBalance=-1`,
				),
			]);
			const dataEvm = response[0].status === 'fulfilled' ? response[0].value : [];
			const dataSol = (response[1].status === 'fulfilled' ? response[1].value?.data : []) as PumpToken[];

			let totalUsd = 0;

			const virtual = dataEvm?.length ? await fetchVirtual() : null;
			const promisesEvm = dataEvm.map(async (e) => {
				const token = MemePadService.formatToken(e, MemePlatform.VIRTUALS_IO);
				const { price, amount, percentChange24h, decimals, address } = token;
				let realPrice = price;
				if (!realPrice) {
					const resp = await VirtualService.estimateSwap({
						amountIn: parseUnits(amount.toString(), decimals).toString(),
						tokenIn: address,
						tokenOut: VIRTUAL_TOKEN_ADDRESS,
					}).catch(() => ({ amountOut: '0' }));
					const amountVirtual = ethers.formatUnits(resp.amountOut, VIRTUAL_TOKEN_DECIMALS);
					realPrice = (+amountVirtual / amount) * getTokenInfo(virtual).priceUsd;
				}
				const usdValue = +amount * realPrice;
				totalUsd += usdValue;
				return { ...token, usdValue, percentChange24h };
			});

			// todo better if backend support search token by list chain address
			const solTokensPumpFun: PumpToken[] = dataSol
				?.filter((e) => e.balance)
				.map((e) => ({ ...e, address: e.mint }));
			const promiseSol = solTokensPumpFun.map((e) =>
				BffServiceAPI.searchTokens({
					query: e.mint,
					chainId: ChainId.SOL,
					limit: 1,
				}).then((data) => {
					const balance = e.balance ?? '0';
					const { decimals, priceUsd, percentChange24h, marketCapUsd } = getTokenInfo(data?.[0]);
					const floatBalance = +formatUnits(balance, decimals);
					const usdValue = floatBalance * priceUsd;
					totalUsd += usdValue;
					const newToken: MemeTokenInfo = {
						...MemePadService.formatToken(e, MemePlatform.PUMP_FUN),
						amount: floatBalance,
						usdValue,
						price: priceUsd,
						percentChange24h,
						marketCapUsd: e.usd_market_cap || +marketCapUsd,
					};
					return newToken;
				}),
			);

			const [responseEvm, responseSol] = await Promise.all([
				Promise.allSettled(promisesEvm),
				Promise.allSettled(promiseSol),
			]);

			const evmTokens = responseEvm.map((e) => (e.status === 'fulfilled' ? e.value : null)).filter(Boolean);
			const solTokens = responseSol.map((e) => (e.status === 'fulfilled' ? e.value : null)).filter(Boolean);
			return { tokens: solTokens.concat(evmTokens), totalUsd };
		},
		enabled: !!evmAddress && !!solAddress,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useGetListMemeByType = (type: ListMemeType, pageSize: number, page = 1) => {
	const { memePlatform } = useAppState();

	const response = useQuery({
		queryKey: ['get-meme-trending-info', type, pageSize, page, memePlatform],
		queryFn: async (): Promise<MemeTokenInfo[]> => {
			let tokens = [];
			switch (memePlatform) {
				case MemePlatform.VIRTUALS_IO:
					tokens = await VirtualService.getListMeme(type, pageSize, page);
					break;
				case MemePlatform.PUMP_FUN: {
					const params = {
						limit: pageSize,
						includeNsfw: false,
						offset: pageSize * (page - 1),
						order: 'DESC',
					};
					const data = await axiosPumpFun.get(type, {
						params,
					});
					tokens = data.data;
					break;
				}
			}
			return tokens.map((e) => MemePadService.formatToken(e, memePlatform));
		},
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useSearchMeme = ({
	keyword,
	enabled,
	limit,
	platform: _platform,
}: {
	keyword: string;
	enabled: boolean;
	limit: number;
	platform?: string;
}) => {
	const { memePlatform } = useAppState();
	const platform = _platform || memePlatform;
	const response = useQuery({
		queryKey: ['get-search-meme-info', keyword, platform],
		queryFn: async (): Promise<MemeTokenInfo[]> => {
			let tokens = [];
			switch (platform) {
				case MemePlatform.PUMP_FUN: {
					const data = await axiosPumpFun.get(
						`/coins?offset=0&limit=${limit}&sort=market_cap&order=DESC&includeNsfw=false&searchTerm=${keyword}`,
					);
					tokens = data.data;
					return tokens.map((e) => MemePadService.formatToken(e, MemePlatform.PUMP_FUN));
				}
				case MemePlatform.VIRTUALS_IO: {
					tokens = await VirtualService.searchMeme(keyword);
					return tokens.map((e) => MemePadService.formatToken(e, MemePlatform.VIRTUALS_IO));
				}
			}
			return [];
		},
		enabled,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetMemeTrades = (address: string) => {
	const response = useQuery({
		queryKey: ['get-created-meme', address],
		queryFn: async (): Promise<PumpTrade[]> => {
			const data = await axiosPumpFun.get(`/trades/all/${address}?limit=200&offset=0&minimumSize=0`);
			return data.data;
		},
		enabled: !!address,
		gcTime: ONE_MINUTE,
		staleTime: ONE_MINUTE,
	});
	return response;
};

export const useGetMemeReply = (address: string, platform: MemePlatform) => {
	const { memePlatform } = useAppState();
	const response = useQuery({
		queryKey: ['get-cmt-meme', address, platform],
		queryFn: async (): Promise<MemeChat[]> => {
			let comments = [];
			switch (memePlatform) {
				case MemePlatform.PUMP_FUN: {
					const data = await axiosPumpFun.get(
						`/replies/${address}?limit=1000&offset=0&user=${MpcWallet.getSolanaWalletAddress()}`,
					);
					comments = data?.data?.replies ?? [];
					break;
				}
				case MemePlatform.VIRTUALS_IO:
					comments = await VirtualService.getChat(address);
					break;
			}

			return comments
				.map((e) => MemePadService.formatChat(e, platform))
				.sort((a, b) => b.timestamp - a.timestamp);
		},
		enabled: !!address,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

type ChartParams = {
	address: string;
	limit: number;
	timeframe: number;
	offset: number;
	id: string;
	platform: MemePlatform;
};
export const fetchMemeChart = async ({ platform, ...params }: ChartParams): Promise<ChartData> => {
	switch (platform) {
		case MemePlatform.PUMP_FUN: {
			const data = await axiosPumpFun.get(`/candlesticks/${params.address}`, { params });
			const chartData: PumpChartData[] = data?.data ?? [];
			return chartData.map((e) => ({
				timestamp: e.timestamp,
				open: e.open,
				low: e.low,
				high: e.high,
				close: e.close,
			}));
		}
		case MemePlatform.VIRTUALS_IO: {
			return VirtualService.getChartData({ ...params, tokenId: params.id });
		}
	}
};

export const useGetMemeChart = (params: ChartParams, enabled = true) => {
	const { id, offset, timeframe, platform } = params;
	const response = useQuery({
		queryKey: ['get-chart-meme', id, offset, timeframe, platform],
		queryFn: async (): Promise<ChartData> => {
			return fetchMemeChart(params);
		},
		enabled: !!id && enabled,
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useEstimateCreateMeme = ({ buyAmountSol, buyAmountMeme }) => {
	const response = useQuery({
		queryKey: ['est-fee-create-meme', buyAmountSol?.toString(), buyAmountMeme?.toString()],
		queryFn: async () => {
			const sdk = await PumpFunSDK.create();
			const estimateAmountOut = buyAmountSol ? await sdk.getEstAmountOutFirstBuy(buyAmountSol) : buyAmountMeme;
			const estimateAmountSol = buyAmountSol
				? buyAmountSol
				: await sdk.getEstAmountOutFirstBuyViaTokenAmount(buyAmountMeme);
			const gasFee = parseUnits('0.026', getNativeToken(ChainId.SOL).decimals); // gas + fee created
			return { estimateAmountOut, estimateAmountSol, gasFee };
		},
		enabled: !!(buyAmountSol || buyAmountMeme),
		gcTime: 15_000,
		staleTime: 15_000,
	});
	return response;
};

export const useMemeTopHolder = (address) => {
	const { memePlatform } = useAppState();
	const response = useQuery({
		queryKey: ['useMemeTopHolder', address],
		queryFn: async (): Promise<PumpHolder[]> => {
			switch (memePlatform) {
				case MemePlatform.PUMP_FUN: {
					const { connection } = await SolWallet.init('mainnet-beta', { commitment: 'confirmed' });
					const data = await connection.getTokenLargestAccounts(new PublicKey(address));
					const results = await Promise.all(
						data.value.slice(0, 10).map(async (account) => {
							const accountInfo = await connection.getParsedAccountInfo(new PublicKey(account.address));
							const owner = (accountInfo?.value?.data as any).parsed?.info?.owner;
							return {
								address: owner,
								percent: (account.uiAmount / MEME_TOKEN_TOTAL_SUPPLY) * 100,
							};
						}),
					);
					return results.filter((e) => !!e.address).sort((a, b) => b.percent - a.percent);
				}
				case MemePlatform.VIRTUALS_IO:
					return VirtualService.getTopHolders(address);
			}
		},
		enabled: !!address,
		gcTime: 45_000,
		staleTime: 45_000,
	});
	return response;
};

import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const StoryIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="45.5" cy="45.5" r="45.5" fill="black"/>
        <path d="M45.5853 72C56.3015 72 65 65.5011 65 54.5357C65 44.307 57.4262 37.1266 45.5853 37.1266V44.6999C40.1419 44.6999 36.1035 42.2686 36.1035 37.2955C36.1035 32.3223 39.5811 29.3814 45.8688 29.3814C51.0318 29.3814 54.1162 31.5303 54.9026 34.3546H64.4423C63.7138 26.4405 56.3046 20 45.6432 20C34.3632 20 26.3414 27.122 26.3414 37.5196C26.3414 47.9171 34.7015 54.1919 45.5884 54.1919V47.0146C51.3671 47.0146 55.3536 49.6147 55.3536 54.7567C55.3536 59.8434 51.3122 62.726 45.6463 62.726C40.5412 62.726 37.0057 60.4666 35.939 57.3569H26C27.402 65.5564 34.8661 72 45.5853 72Z" fill="white"/>
        </svg> 
    );
};

import { useWalletStore } from '@/app-store';
import { CreateWalletStatus } from './CreateWalletStatus';
import { RequireBackup } from './RequireBackup';
import { Box } from '@chakra-ui/react';
import { CreateWalletFailed } from './CreateWalletFailed';

export const WalletStatus = () => {
	const { walletStatus, displayedTobiInto } = useWalletStore();
	const renderContent = () => {
		if (!displayedTobiInto) return null;
		if (walletStatus === 'CREATING') return <CreateWalletStatus />;
		if (walletStatus === 'CREATE_FAILED') return <CreateWalletFailed />;
		if (walletStatus === 'REQUIRE_BACKUP') return <RequireBackup />;
		return null;
	};
	return (
		<Box position="fixed" bottom={0} left={0} right={0} zIndex={10}>
			{renderContent()}
		</Box>
	);
};

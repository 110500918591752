import {
	CHAIN_CONFIG,
	ChainId,
	VIRTUAL_TOBI_ID,
	VIRTUAL_TOKEN_ADDRESS,
	VIRTUAL_TOKEN_DECIMALS,
	ZERO_ADDRESS,
} from '@/app-constants/chains';
import { compareAddress } from '@/app-helpers/address';
import { getSignerContract } from '@/app-helpers/web3';
import { RouteVirtual } from '@/app-hooks/swap/virtual';
import { getEvmBalance } from '@/app-hooks/wallet/useWalletBalance';
import { ListMemeType } from '@/app-services/memepad/const';
import { PumpHolder } from '@/app-services/pump.fun';
import { MemePlatform, MemeTokenInfo, VirtualMessage, VirtualToken } from '@/app-services/virtual/type';
import { SelectedRoute } from '@/app-store/swap';
import { MEME_TOKEN_TOTAL_SUPPLY } from '@/app-views/tobi-fun/helpers';
import axios from 'axios';
import { TransactionResponse, ethers, isAddress, parseUnits } from 'ethers';

const abi = [
	'function getAmountsOut(address token, address assetToken_, uint256 amountIn) view returns (uint256 amountOut)',
];

const axiosVirtual = axios.create({
	baseURL: `https://api.virtuals.io/api`,
	timeout: 20000,
});

const sortMap = {
	[ListMemeType.NEW_CREATED]: 'id%3Adesc',
	[ListMemeType.TOP_MARKET_CAP]: 'virtualTokenValue%3Adesc',
	[ListMemeType.TRENDING]: 'createdAt%3Adesc',
};

class Virtual {
	readContractAddress = '0x8292b43ab73efac11faf357419c38acf448202c5';
	writeContractAddress = '0xf66dea7b3e897cd44a5a231c61b6b4423d613259';
	reservedTokens = 125000000;
	bondingCurve: Record<string, string> = {};

	async getListMeme(type: ListMemeType, pageSize: number, page = 1): Promise<VirtualToken[]> {
		const sort = sortMap[type];
		if (!sort) return [];
		const { data } = await axiosVirtual.get(
			type === ListMemeType.TRENDING
				? `/virtuals?filters[status][$in][0]=AVAILABLE&filters[status][$in][1]=ACTIVATING&filters[priority][$ne]=-1&sort[0]=totalValueLocked%3Adesc&sort[1]=createdAt%3Adesc&populate[0]=image&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
				: `/virtuals?filters[$or][0][name][$contains]=&filters[$or][1][symbol][$contains]=&filters[$or][2][preToken][$contains]=&filters[preToken][$ne]=&filters[status]=UNDERGRAD&sort[0]=${sort}&populate[0]=image&populate[1]=creator&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
		);

		return data.data ?? [];
	}

	async searchMeme(address: string): Promise<VirtualToken[]> {
		`/virtuals?filters[status][$in][0]=AVAILABLE&filters[status][$in][1]=ACTIVATING&filters[status][$in][2]=UNDERGRAD&filters[priority][$ne]=-1&filters[$or][0][name][$contains]=${address}&filters[$or][1][symbol][$contains]=${address}&filters[$or][2][tokenAddress][$contains]=${address}&filters[$or][3][preToken][$contains]=${address}&sort[0]=totalValueLocked%3Adesc&sort[1]=createdAt%3Adesc&populate[0]=image&pagination[page]=1&pagination[pageSize]=10`;

		const getFn = async (forceKeyword = false) => {
			const { data } = await axiosVirtual.get(
				isAddress(address) && !forceKeyword
					? `/virtuals?filters[preToken]=${address}&populate[0]=image&populate[1]=creator&populate[2]=tier&pagination[page]=1&pagination[pageSize]=1`
					: `/virtuals?filters[status][$in][0]=AVAILABLE&filters[status][$in][1]=ACTIVATING&filters[status][$in][2]=UNDERGRAD&filters[priority][$ne]=-1&filters[$or][0][name][$contains]=${address}&filters[$or][1][symbol][$contains]=${address}&filters[$or][2][tokenAddress][$contains]=${address}&filters[$or][3][preToken][$contains]=${address}&sort[0]=totalValueLocked%3Adesc&sort[1]=createdAt%3Adesc&populate[0]=image&pagination[page]=1&pagination[pageSize]=10`,
			);
			return data?.data ?? [];
		};
		const data = await getFn();
		if (!data.length) return getFn(true);
		return data;
	}
	async getTopHolders(address: string): Promise<PumpHolder[]> {
		const { data } = await axiosVirtual.get(`/tokens/${address}/holders`);
		return data.data?.map((e) => ({ address: e[0], percent: e[1] })) ?? [];
	}

	async getChat(id: string): Promise<VirtualMessage[]> {
		const { data } = await axiosVirtual.get(
			`/forum-messages?pagination[page]=1&pagination[pageSize]=10&populate[0]=user&populate[1]=parentMessage&populate[2]=virtual.image&populate[3]=user.userSocials&sort=createdAt%3Adesc&filters[virtual]=${id}`,
		);
		return data.data ?? [];
	}

	async getHoldTokens(wallet: string): Promise<VirtualToken[]> {
		const { data } = await axiosVirtual.get(`/wallets/${wallet}/holdings`);
		return data.data ?? [];
	}
	async getCreatedTokens(wallet: string): Promise<VirtualToken[]> {
		const { data } = await axiosVirtual.get(
			`/virtuals?filters[status][$in][0]=AVAILABLE&filters[status][$in][1]=ACTIVATING&filters[status][$in][2]=UNDERGRAD&filters[priority][$ne]=-1&filters[walletAddress]=${wallet}&sort[0]=mcapInVirtual%3Adesc&sort[1]=id%3Adesc&populate[0]=image&pagination[page]=1`,
		);
		return data.data ?? [];
	}
	async getChartData({ tokenId, offset, limit, timeframe }) {
		const end = new Date();
		const start = new Date();
		const delta = (offset / limit) | 0;
		end.setDate(end.getDate() - 7 * delta);
		start.setDate(end.getDate() - 7);
		const { data = [] } = await axios.get(
			`https://iggmgp5xpg.ap-southeast-1.awsapprunner.com/api/ohlc-data/chart`,
			{
				params: {
					virtualId: tokenId,
					interval: timeframe + 'm',
					start: start.toISOString(),
					end: end.toISOString(),
				},
			},
		);
		return data.map((e) => ({
			timestamp: e.time,
			open: e.open,
			low: e.low,
			high: e.high,
			close: e.close,
		}));
	}

	formatMemeToken(token: VirtualToken): MemeTokenInfo {
		return {
			...token,
			dex: 'Uniswap',
			chainId: ChainId.BASE,
			decimals: 18,
			address: token.tokenAddress || token.preToken,
			complete: !!token.lpAddress,
			bondingCurve: token.preTokenPair,
			createdTime: new Date(token.createdAt).getTime(),
			creator: token?.creator?.userSocials?.[0]?.walletAddress || '',
			platform: MemePlatform.VIRTUALS_IO,
			platformLogo: 'virtual.svg',
			logo: token.image?.url,
			id: token.id?.toString(),
			uri: '',
			poolLink: `https://www.geckoterminal.com/base/pools/${token.lpAddress}`,
			percentChange24h: token.price_24h_change,
			telegram: token?.socials?.USER_LINKS?.TELEGRAM,
			twitter: token?.socials?.USER_LINKS?.TWITTER,
			website: token?.socials?.USER_LINKS?.WEBSITE,
			marketCapUsd: 0,
			assetTobiId: VIRTUAL_TOBI_ID,
		};
	}

	async estimateSwap({ tokenIn, tokenOut, ...params }: { tokenIn: string; tokenOut: string; amountIn: string }) {
		const amountIn = BigInt(params.amountIn);
		const { contract } = await getSignerContract(this.readContractAddress, ChainId.BASE, abi);
		const isBuy = compareAddress(VIRTUAL_TOKEN_ADDRESS, tokenIn);
		const fee = 1n / 100n;
		let result: bigint;
		// todo why lech
		if (isBuy) {
			const txFee = fee * amountIn;
			result = await contract.getAmountsOut(tokenOut, VIRTUAL_TOKEN_ADDRESS, amountIn - txFee);
		} else {
			const amountOut: bigint = await contract.getAmountsOut(tokenIn, ZERO_ADDRESS, amountIn);
			const txFee = fee * amountOut;
			result = amountOut - txFee;
		}
		return { amountOut: result.toString(), fee: BigInt(CHAIN_CONFIG[ChainId.BASE].minForGas) };
	}

	async swap({ tokenIn, tokenOut, params }: SelectedRoute<RouteVirtual>): Promise<TransactionResponse> {
		const isBuy = compareAddress(VIRTUAL_TOKEN_ADDRESS, tokenIn?.address);
		const contractABI = [
			`function buy(uint256 amountIn, address tokenAddress) public payable returns (bool)`,
			`function sell(uint256 amountIn, address tokenAddress) public payable returns (bool)`,
		];
		const { contract } = await getSignerContract(this.writeContractAddress, ChainId.BASE, contractABI);
		return isBuy
			? contract.buy(params.amountIn, tokenOut.address)
			: contract.sell(params.amountIn, tokenIn.address);
	}

	async calcMarketCapVirtual({ bondingCurve: curve, decimals, complete, address }: MemeTokenInfo) {
		let bondingCurve = curve || this.bondingCurve[address.toLowerCase()];
		if (!bondingCurve) {
			const token = await this.searchMeme(address);
			const tokenInfo = token?.[0];
			bondingCurve = tokenInfo?.preTokenPair;
		}
		if (!bondingCurve) return 0;
		this.bondingCurve[address.toLowerCase()] = bondingCurve;
		const contractABI = ['function getReserves() external view returns (uint256, uint256)'];
		const { contract } = await getSignerContract(bondingCurve, ChainId.BASE, contractABI);
		const [reserve0, reserve1] = await contract.getReserves();
		const mCap = (parseUnits(MEME_TOKEN_TOTAL_SUPPLY.toString(), decimals) * reserve1) / reserve0;
		return +ethers.formatUnits(mCap, VIRTUAL_TOKEN_DECIMALS);
	}

	getFinalMarketCap() {
		// not correct
		const assetRate = 5000;
		const K = 3_000_000_000_000;
		const k = (K * 10000) / assetRate;
		return (((k * 10000) / MEME_TOKEN_TOTAL_SUPPLY) * 1) / 10000;
	}

	async calcBondingPercent({ bondingCurve, address, decimals }: MemeTokenInfo) {
		const tokenBalance = await getEvmBalance(bondingCurve, ChainId.BASE, address);
		const balance = +ethers.formatUnits(tokenBalance, decimals);
		return 100 - ((balance - this.reservedTokens) * 100) / (MEME_TOKEN_TOTAL_SUPPLY - this.reservedTokens);
	}

	async getBondingCurveProgress({ bondingCurve, address, decimals }: MemeTokenInfo) {
		const [tokenBalance, nativeBalance] = await Promise.all([
			getEvmBalance(bondingCurve, ChainId.BASE, address),
			getEvmBalance(bondingCurve, ChainId.BASE, VIRTUAL_TOKEN_ADDRESS),
		]);
		return {
			nativeLeft: ethers.formatUnits(nativeBalance, VIRTUAL_TOKEN_DECIMALS), // balance virtual of pair,
			tokenLeft: +ethers.formatUnits(tokenBalance, decimals) - this.reservedTokens,
			targetMCap: this.getFinalMarketCap(),
			symbol: 'VIRTUAL',
		};
	}
}
const VirtualService = new Virtual();
export default VirtualService;

import { ChainId, SOLANA_URL } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { SwapServiceAPI } from '@/app-cores/api/swap';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { getNativeTobiId, getNativeToken } from '@/app-helpers/token';
import { getSwapFeeType } from '@/app-hooks/swap/helper';
import { MAX_FEE_SOL_SWAP } from '@/app-hooks/swap/type';
import { PumpFun } from '@/app-services/pump.fun/IDL/pump-fun';
import { MemePlatform, MemeTokenInfo } from '@/app-services/virtual/type';
import { SolanaFeeType } from '@/app-types';
import { MEME_TOKEN_TOTAL_SUPPLY, PUMP_TOKEN_DECIMALS } from '@/app-views/tobi-fun/helpers';
import { AnchorProvider, Program } from '@coral-xyz/anchor';
import NodeWallet from '@coral-xyz/anchor/dist/cjs/nodewallet';
import { bs58 } from '@coral-xyz/anchor/dist/cjs/utils/bytes';
import { createAssociatedTokenAccountInstruction, getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import { Commitment, Connection, Keypair, PublicKey, Transaction } from '@solana/web3.js';
import axios from 'axios';
import { BN } from 'bn.js';
import { formatUnits } from 'ethers';
import { IDL } from './IDL';
import { BondingCurveAccount } from './bondingCurveAccount';
import { toCreateEvent, toTradeEvent } from './events';
import { GlobalAccount } from './globalAccount';
import { CreateEvent, CreateTokenMetadata, PumpFunEventHandlers, PumpFunEventType, PumpToken } from './types';
import { DEFAULT_COMMITMENT, buildTx, calculateWithSlippageBuy, calculateWithSlippageSell } from './util';
const PROGRAM_ID = '6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P';
const MPL_TOKEN_METADATA_PROGRAM_ID = 'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s';

export const GLOBAL_ACCOUNT_SEED = 'global';
export const MINT_AUTHORITY_SEED = 'mint-authority';
export const BONDING_CURVE_SEED = 'bonding-curve';
export const METADATA_SEED = 'metadata';

export const DEFAULT_DECIMALS = 6;
type SwapParams = {
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
	amountIn: string;
	slippageBasisPoints: bigint; // 5%  => 500n
	isBuy: boolean;
};

export class PumpFunSDK {
	public program: Program<PumpFun>;
	public connection: Connection;
	public publicKey: PublicKey;

	async initialize() {
		const { connection, fromPubKey } = await SolWallet.init('mainnet-beta', { commitment: 'finalized' });
		this.publicKey = fromPubKey;

		const wallet = new NodeWallet(new Keypair()); //note this is not used
		const provider = new AnchorProvider(connection, wallet, {
			commitment: 'finalized',
		});
		this.program = new Program<PumpFun>(IDL as PumpFun, provider);
		this.connection = this.program.provider.connection;
	}

	static async create() {
		const sdk = new PumpFunSDK();
		await sdk.initialize();
		return sdk;
	}

	// est amount out when first buy
	async getEstAmountOutFirstBuy(buyAmountSol: bigint) {
		const globalAccount = await this.getGlobalAccount(DEFAULT_COMMITMENT);
		return globalAccount.getInitialBuyPrice(buyAmountSol);
	}

	async getEstAmountOutFirstBuyViaTokenAmount(tokenAmount: bigint) {
		const globalAccount = await this.getGlobalAccount(DEFAULT_COMMITMENT);
		return globalAccount.getInitialBuyPriceViaTokenAmount(tokenAmount);
	}

	async getPriorityFeeEstimate(transaction: Transaction): Promise<{ unitPrice: number }> {
		try {
			transaction.recentBlockhash = (await this.connection.getLatestBlockhash()).blockhash;
			transaction.feePayer = this.publicKey;
			const { solanaGasFeeType } = getSwapFeeType();
			const feeLevel = {
				[SolanaFeeType.FAST]: 'Low',
				[SolanaFeeType.TURBO]: 'Medium',
				[SolanaFeeType.ULTRA]: 'High',
			}[solanaGasFeeType];

			const response = await axios({
				url: SOLANA_URL,
				method: 'POST',
				data: {
					jsonrpc: '2.0',
					id: '1',
					method: 'getPriorityFeeEstimate',
					params: [
						{
							transaction: bs58.encode(transaction.serialize({ requireAllSignatures: false })), // Pass the serialized transaction in Base58
							options: { priorityLevel: feeLevel },
						},
					],
				},
			});
			return { unitPrice: response.data.result.priorityFeeEstimate };
		} catch (error) {
			return;
		}
	}

	async createAndBuy({
		slippageBasisPoints,
		createTokenMetadata,
		buyAmountSol,
		estimateOnly,
	}: {
		createTokenMetadata: CreateTokenMetadata;
		buyAmountSol: bigint;
		slippageBasisPoints: string | number;
		estimateOnly: boolean;
	}) {
		const tokenMetadata = estimateOnly
			? createTokenMetadata
			: await SwapServiceAPI.createPumpfunTokenMetadata(createTokenMetadata);
		const mint = Keypair.generate();
		const createTx = await this.getCreateInstructions(
			this.publicKey,
			createTokenMetadata.name,
			createTokenMetadata.symbol,
			tokenMetadata.metadataUri,
			mint,
		);

		const newTx = new Transaction().add(createTx);

		let estimateAmountOut = 0n;
		if (buyAmountSol > 0) {
			const globalAccount = await this.getGlobalAccount(DEFAULT_COMMITMENT);
			const buyAmount = globalAccount.getInitialBuyPrice(buyAmountSol);
			const buyAmountWithSlippage = calculateWithSlippageBuy(buyAmountSol, BigInt(slippageBasisPoints));
			estimateAmountOut = buyAmount;
			const buyTx = await this.getBuyInstructions(
				this.publicKey,
				mint.publicKey,
				globalAccount.feeRecipient,
				buyAmount,
				buyAmountWithSlippage,
			);

			newTx.add(buyTx);
		}

		const fee = await this.getPriorityFeeEstimate(newTx);
		const tx = await buildTx(this.connection, newTx, this.publicKey, [mint], fee);
		return { tx, estimateAmountOut, signer: mint };
	}

	async swap({ tokenIn, tokenOut, amountIn, slippageBasisPoints, isBuy }: SwapParams) {
		const mint = new PublicKey(!isBuy ? tokenIn.address : tokenOut.address);
		const tx = await (isBuy
			? this.getBuyInstructionsBySolAmount(mint, BigInt(amountIn), slippageBasisPoints, DEFAULT_COMMITMENT)
			: this.getSellInstructionsByTokenAmount(mint, BigInt(amountIn), slippageBasisPoints, DEFAULT_COMMITMENT));
		const fee = await this.getPriorityFeeEstimate(tx);
		return buildTx(this.connection, tx, this.publicKey, [], fee);
	}

	static formatToken(token: PumpToken): MemeTokenInfo {
		return {
			...token,
			dex: 'Raydium',
			decimals: PUMP_TOKEN_DECIMALS,
			chainId: ChainId.SOL,
			logo: token.image_uri,
			platform: MemePlatform.PUMP_FUN,
			id: token.mint,
			platformLogo: 'pumpfun.png',
			marketCapUsd: token.usd_market_cap,
			poolLink: `https://www.geckoterminal.com/solana/pools/${token?.raydium_pool}`,
			createdTime: token.created_timestamp,
			bondingCurve: token.bonding_curve,
			address: token.mint,
			uri: token.uri,
			assetTobiId: getNativeTobiId(ChainId.SOL),
		};
	}

	//create token instructions
	async getCreateInstructions(creator: PublicKey, name: string, symbol: string, uri: string, mint: Keypair) {
		const mplTokenMetadata = new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID);

		const [metadataPDA] = PublicKey.findProgramAddressSync(
			[Buffer.from(METADATA_SEED), mplTokenMetadata.toBuffer(), mint.publicKey.toBuffer()],
			mplTokenMetadata,
		);

		const associatedBondingCurve = await getAssociatedTokenAddress(
			mint.publicKey,
			this.getBondingCurvePDA(mint.publicKey),
			true,
		);

		return this.program.methods
			.create(name, symbol, uri)
			.accounts({
				mint: mint.publicKey,
				associatedBondingCurve: associatedBondingCurve,
				metadata: metadataPDA,
				user: creator,
			})
			.signers([mint])
			.transaction();
	}

	async getBuyInstructionsBySolAmount(
		mint: PublicKey,
		buyAmountSol: bigint,
		slippageBasisPoints: bigint = 500n,
		commitment: Commitment = DEFAULT_COMMITMENT,
	) {
		const bondingCurveAccount = await this.getBondingCurveAccount(mint, commitment);
		if (!bondingCurveAccount) {
			throw new Error(`Bonding curve account not found: ${mint.toBase58()}`);
		}

		const buyAmount = bondingCurveAccount.getBuyPrice(buyAmountSol);
		const buyAmountWithSlippage = calculateWithSlippageBuy(buyAmountSol, slippageBasisPoints);

		const globalAccount = await this.getGlobalAccount(commitment);

		return await this.getBuyInstructions(
			this.publicKey,
			mint,
			globalAccount.feeRecipient,
			buyAmount,
			buyAmountWithSlippage,
		);
	}

	async estimateSwap({ slippageBasisPoints, amountIn: _amountIn, isBuy, tokenIn, tokenOut }: SwapParams) {
		const amountIn = BigInt(_amountIn);
		const mint = new PublicKey(!isBuy ? tokenIn.address : tokenOut.address);
		const bondingCurveAccount = await this.getBondingCurveAccount(mint, DEFAULT_COMMITMENT);
		if (!bondingCurveAccount) {
			throw new Error(`Bonding curve account not found: ${mint.toBase58()}`);
		}
		if (isBuy) {
			const buyAmount = bondingCurveAccount.getBuyPrice(amountIn);
			return { amountOut: buyAmount.toString(), fee: MAX_FEE_SOL_SWAP + amountIn / 100n }; // 1% fee;
		} else {
			const globalAccount = await this.getGlobalAccount(DEFAULT_COMMITMENT);
			const minSolOutput = bondingCurveAccount.getSellPrice(amountIn, globalAccount.feeBasisPoints);
			const sellAmountWithSlippage = calculateWithSlippageSell(minSolOutput, slippageBasisPoints);
			return { amountOut: sellAmountWithSlippage.toString(), fee: MAX_FEE_SOL_SWAP };
		}
	}

	async getBondingPercent(mint: PublicKey, curveAddress?: string | BondingCurveAccount) {
		// docs.bitquery.io/docs/examples/Solana/Pump-Fun-Marketcap-Bonding-Curve-API/#bonding-curve-progress-api
		// BondingCurveProgress = 100 - (((realTokenReserves - reservedTokens)*100) / (totalSupply - reservedTokens))
		// leftTokens = realTokenReserves - reservedTokens
		// initialRealTokenReserves = totalSupply - reservedTokens
		const reservedTokens = 206900000;

		const curve = curveAddress
			? curveAddress instanceof BondingCurveAccount
				? curveAddress.publicKey
				: new PublicKey(curveAddress)
			: (await this.getBondingCurveAccount(new PublicKey(mint), DEFAULT_COMMITMENT)).publicKey;

		const tokenAccount = await getAssociatedTokenAddress(new PublicKey(mint), curve, true);
		const tokenAccountBalance = await this.connection.getTokenAccountBalance(tokenAccount);
		const balance = tokenAccountBalance.value.uiAmount;
		return 100 - ((balance - reservedTokens) * 100) / (MEME_TOKEN_TOTAL_SUPPLY - reservedTokens);
	}

	async getBondingCurveProgress(mint: string) {
		const [bondingCurveAccount] = await Promise.all([
			this.getBondingCurveAccount(new PublicKey(mint), DEFAULT_COMMITMENT),
		]);

		const sol = getNativeToken(ChainId.SOL);
		const targetMCap = formatUnits(bondingCurveAccount.getFinalMarketCapSOL()?.toString() || 0, sol.decimals);

		return {
			nativeLeft: formatUnits(bondingCurveAccount.realSolReserves, sol.decimals),
			tokenLeft: formatUnits(bondingCurveAccount.realTokenReserves, PUMP_TOKEN_DECIMALS),
			targetMCap,
			symbol: 'SOL',
		};
	}

	//buy
	async getBuyInstructions(
		buyer: PublicKey,
		mint: PublicKey,
		feeRecipient: PublicKey,
		amount: bigint,
		solAmount: bigint,
		commitment: Commitment = DEFAULT_COMMITMENT,
	) {
		const associatedBondingCurve = await getAssociatedTokenAddress(mint, this.getBondingCurvePDA(mint), true);

		const associatedUser = await getAssociatedTokenAddress(mint, buyer, false);

		const transaction = new Transaction();

		try {
			await getAccount(this.connection, associatedUser, commitment);
		} catch (e) {
			transaction.add(createAssociatedTokenAccountInstruction(buyer, associatedUser, buyer, mint));
		}

		transaction.add(
			await this.program.methods
				.buy(new BN(amount.toString()), new BN(solAmount.toString()))
				.accounts({
					feeRecipient: feeRecipient,
					mint: mint,
					associatedBondingCurve: associatedBondingCurve,
					associatedUser: associatedUser,
					user: buyer,
				})
				.transaction(),
		);

		return transaction;
	}

	//sell
	async getSellInstructionsByTokenAmount(
		mint: PublicKey,
		sellTokenAmount: bigint,
		slippageBasisPoints: bigint = 500n,
		commitment: Commitment = DEFAULT_COMMITMENT,
	) {
		const bondingCurveAccount = await this.getBondingCurveAccount(mint, commitment);
		if (!bondingCurveAccount) {
			throw new Error(`Bonding curve account not found: ${mint.toBase58()}`);
		}

		const globalAccount = await this.getGlobalAccount(commitment);

		const minSolOutput = bondingCurveAccount.getSellPrice(sellTokenAmount, globalAccount.feeBasisPoints);

		const sellAmountWithSlippage = calculateWithSlippageSell(minSolOutput, slippageBasisPoints);

		return await this.getSellInstructions(
			this.publicKey,
			mint,
			globalAccount.feeRecipient,
			sellTokenAmount,
			sellAmountWithSlippage,
		);
	}

	async getSellInstructions(
		seller: PublicKey,
		mint: PublicKey,
		feeRecipient: PublicKey,
		amount: bigint,
		minSolOutput: bigint,
	) {
		const associatedBondingCurve = await getAssociatedTokenAddress(mint, this.getBondingCurvePDA(mint), true);

		const associatedUser = await getAssociatedTokenAddress(mint, seller, false);

		const transaction = new Transaction();

		transaction.add(
			await this.program.methods
				.sell(new BN(amount.toString()), new BN(minSolOutput.toString()))
				.accounts({
					feeRecipient: feeRecipient,
					mint: mint,
					associatedBondingCurve: associatedBondingCurve,
					associatedUser: associatedUser,
					user: seller,
				})
				.transaction(),
		);

		return transaction;
	}

	async getBondingCurveAccount(mint: PublicKey, commitment: Commitment = DEFAULT_COMMITMENT) {
		const curve = this.getBondingCurvePDA(mint);
		const tokenAccount = await this.connection.getAccountInfo(curve, commitment);
		if (!tokenAccount) {
			return null;
		}
		return BondingCurveAccount.fromBuffer(tokenAccount!.data, curve);
	}

	async getGlobalAccount(commitment: Commitment = DEFAULT_COMMITMENT) {
		const [globalAccountPDA] = PublicKey.findProgramAddressSync(
			[Buffer.from(GLOBAL_ACCOUNT_SEED)],
			new PublicKey(PROGRAM_ID),
		);

		const tokenAccount = await this.connection.getAccountInfo(globalAccountPDA, commitment);

		return GlobalAccount.fromBuffer(tokenAccount!.data);
	}

	getBondingCurvePDA(mint: PublicKey) {
		return PublicKey.findProgramAddressSync(
			[Buffer.from(BONDING_CURVE_SEED), mint.toBuffer()],
			this.program.programId,
		)[0];
	}

	//EVENTS
	addEventListener<T extends PumpFunEventType>(
		eventType: T,
		callback: (event: PumpFunEventHandlers[T], slot: number, signature: string) => void,
	) {
		return this.program.addEventListener(eventType, (event: any, slot: number, signature: string) => {
			let processedEvent;
			switch (eventType) {
				case 'createEvent':
					processedEvent = toCreateEvent(event as CreateEvent);
					callback(processedEvent as PumpFunEventHandlers[T], slot, signature);
					break;
				case 'tradeEvent':
					processedEvent = toTradeEvent(event, signature);
					callback(processedEvent as PumpFunEventHandlers[T], slot, signature);
					break;
				default:
					console.error('Unhandled event type:', eventType);
			}
		});
	}

	removeEventListener(eventId: number) {
		this.program.removeEventListener(eventId);
	}
}

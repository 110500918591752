import { CHAIN_CONFIG } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { countDecimals, formatUnits } from '@/app-helpers/number';
import { getTobiChainName } from '@/app-helpers/token';
import { useDebounce } from '@/app-hooks/common';
import { ErrorMsg, SwapErrorInfo } from '@/app-hooks/swap';
import { SwapErrorType } from '@/app-hooks/swap/type';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const PUMP_TOKEN_DECIMALS = 6;
export const MEME_TOKEN_TOTAL_SUPPLY = 1000000000;

export enum TokenMode {
	NATIVE,
	MEME,
}

export const formatPumpTokenToTobiToken = (data: MemeTokenInfo): ITokenSearch => {
	const { address, symbol, description, name, logo: imageUrl, chainId, decimals } = data || {};
	return {
		...data,
		chainId: chainId as string,
		tokenMutableDataDto: {
			platforms: { [chainId]: address },
			description,
			totalSupply: null,
			circulatingSupply: null,
			imageUrl,
			socials: {},
			name,
		},
		tokenImmutableDataDto: {
			symbol,
			decimals,
			chainId: getTobiChainName(chainId),
			isNative: false,
			address: address,
			maxSupply: null,
			idTobi: address,
		},
		tokenMarketDataDto: { idTobi: address, priceUsd: 0, marketCapUsd: '' } as any,
	};
};

export const useValidateInput = ({
	amountInDebounced,
	totalPay,
	parseNativeBalance,
	native,
	tokenMode,
}): SwapErrorInfo => {
	const { t } = useTranslation();

	const result = useMemo(() => {
		const errors: ErrorMsg[] = [];

		const decimals = tokenMode === TokenMode.MEME ? PUMP_TOKEN_DECIMALS : native.decimals;

		if (amountInDebounced && countDecimals(amountInDebounced) > decimals) {
			errors.push({
				type: 'error',
				msg: `Input is invalid, exceed decimals, max decimals is ${decimals}`,
				errorType: SwapErrorType.VALIDATE_AMOUNT,
			});
		}
		if (totalPay > parseNativeBalance) {
			errors.push({
				type: 'error',
				errorType: SwapErrorType.GAS,
				msg: t('memepad.notEnoughTokenCreateMeme', {
					token: native?.symbol,
					chainName: CHAIN_CONFIG[native?.chainId].name,
					amount: formatUnits(totalPay, native.decimals),
				}),
			});
		}

		return { hasError: errors.some((e) => e.type === 'error'), messages: errors };
	}, [amountInDebounced, totalPay, parseNativeBalance, native, t, tokenMode]);

	return useDebounce(result, 400);
};

import CursorPagination, { usePagination } from '@/app-components/common/Pagination';
import { useGetMemeReply } from '@/app-hooks/api/memepad';
import { MemeTokenInfo } from '@/app-services/virtual/type';
import { colors } from '@/app-theme/theme';
import CommentItem from '@/app-views/tobi-fun/containers/CommentItem';
import { Box, Card, CardBody, Center, Divider, Text } from '@chakra-ui/react';
import { Fragment, useState } from 'react';

const pageSize = 4;
export default function Comments({ memeInfo: token }: { memeInfo: MemeTokenInfo | undefined }) {
	const { data = [] } = useGetMemeReply(token?.id, token?.platform);
	const [page, setPage] = useState(1);
	const { onBack, onNext } = usePagination({ page, setPage, data });
	const start = (page - 1) * pageSize;
	return (
		<Box>
			<Box display="flex" marginY={3} justifyContent="space-between">
				<Text fontWeight={500}>Thread</Text>
			</Box>
			<Card>
				<CardBody display={'flex'} flexDirection={'column'} gap={2} p={4}>
					{data?.slice(start, start + pageSize)?.map((el, i) => (
						<Fragment key={el.id}>
							<CommentItem data={el} token={token} />
							{i !== data?.length - 1 && (
								<Divider borderStyle={'dotted'} borderColor={colors.gray[300]} />
							)}
						</Fragment>
					))}
					{!data?.length && <Center fontSize={'12px'}>No recent reply</Center>}
				</CardBody>
				<CursorPagination
					style={{ marginBottom: 3 }}
					totalCount={data?.length}
					pageSize={pageSize}
					currentPage={page}
					onNext={onNext}
					onBack={onBack}
				/>
			</Card>
		</Box>
	);
}

import { ITokenSearch } from '@/app-cores/api/bff';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const initializeData = {
	balances: [],
	hideTokens: [],
	forceShowTokens: [],
};

export type HiddenToken = {
	chainId: string;
	tobiChainId: string;
	tobiId: string;
	address?: string;
};

interface IContactStore {
	balances: ITokenSearch[];
	setBalances: (value: ITokenSearch[]) => void;

	hideTokens: HiddenToken[];
	forceShowTokens: HiddenToken[];
	setHideTokens: (v: HiddenToken[]) => void;
	setForceShowTokens: (v: HiddenToken[]) => void;
}
export const useBalanceStore = create<IContactStore>()(
	persist(
		(set) => ({
			...initializeData,
			setBalances: (balances) => set({ balances }),
			setHideTokens: (hideTokens) => set({ hideTokens }),
			setForceShowTokens: (forceShowTokens) => set({ forceShowTokens }),
		}),
		{
			name: 'balance-store',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

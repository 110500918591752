import { Toast } from '@/app-components/common';
import { TokenInfo } from '@/app-cores/api/bff';
import { AddRemoveTokenPayload } from '@/app-cores/api/bot/service-token';
import { compareAddress } from '@/app-helpers/address';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useMutationHideUnhideToken } from '@/app-hooks/api/tokens/useMutationUserSettingTokens';
import { FiSettingIcon } from '@/assets/icons';
import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	List,
	ListItem,
	Switch,
	useDisclosure,
} from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const SettingItem = ({
	text,
	selected,
	onChange,
}: {
	text: string;
	selected: boolean;
	onChange: (v: boolean) => void;
}) => {
	return (
		<ListItem
			display={'flex'}
			cursor={'pointer'}
			justifyContent={'space-between'}
			fontSize={'14px'}
			fontWeight={'500'}
			alignItems={'center'}
		>
			{text}
			<Switch
				size={'lg'}
				isChecked={selected}
				colorScheme={'green'}
				onChange={(e) => onChange(e.target.checked)}
			/>
		</ListItem>
	);
};

export function SettingToken({
	isSpam,
	isHidden,
	tobiId,
	chainId,
	style,
	tokenInfo,
}: {
	isSpam: boolean;
	isHidden: boolean;
	tobiId: string;
	chainId: string;
	style: CSSProperties;
	tokenInfo: TokenInfo;
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { mutateAsync } = useMutationHideUnhideToken();

	const [checkedAlwaysShow, setCheckedAlwaysShow] = useState(!isSpam);
	const [checkedHidden, setCheckedHidden] = useState(isHidden);

	useEffect(() => {
		setCheckedAlwaysShow(!isSpam);
	}, [isSpam]);

	useEffect(() => {
		setCheckedHidden(isHidden);
	}, [isHidden]);

	const onChange = async (action: AddRemoveTokenPayload['action']) => {
		try {
			const payload: AddRemoveTokenPayload = {
				action,
				tokens:
					tokenInfo?.allTokens?.map((e) =>
						compareAddress(tobiId, e.address)
							? { chainId: e.chainId, address: e.address }
							: { tobiId, chainId: e.chainId },
					) ?? [],
			};
			await mutateAsync(payload);
		} catch (error) {
			toast(<Toast type="error" message={`Save error: ${parseErrorMessage(error)}`} />, {
				autoClose: 5000,
			});
		}
	};

	return (
		<>
			<FiSettingIcon onClick={onOpen} style={style} />
			<Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Setting</DrawerHeader>
					<DrawerBody py={5}>
						<List spacing={3}>
							{tokenInfo?.isScam ? (
								<SettingItem
									text="Always show in my portfolio"
									selected={checkedAlwaysShow}
									onChange={(val) => {
										onChange(val ? 'show' : 'unshow');
										setCheckedAlwaysShow(val);
									}}
								/>
							) : (
								<SettingItem
									text="Hide from my portfolio"
									selected={checkedHidden}
									onChange={(val) => {
										onChange(val ? 'hide' : 'unhide');
										setCheckedHidden(val);
									}}
								/>
							)}
						</List>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

import { isEmpty } from 'lodash-es';
import { KeyshareV0 } from '@telifi/dkls-wasm';
import CryptoJS from 'crypto-js';
import { decodeHex, encodeHex } from '../mpc/lib';
import { AbstractKeyShareManager, MAX_ITEM_SIZE } from './abstract-keyshare-manager';
import { TelegramCore } from '@/app-cores/telegram';
import { TGCloudStorage } from './tg-cloud-storage';
import { PRE_COMPUTE_KEYSHARE_ERROR } from './constant';
import { compareAddress } from '@/app-helpers/address';
import { getWalletAddress } from './helper';
import { STORAGE_KEYS, USE_BACKUP_KEYSHARE_VALUE } from '@/app-constants/storage';
import { ServiceUser } from '@/app-cores/api';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

class KeyShareManagerV2 extends AbstractKeyShareManager {
	readonly KEY_SHARE_VERSION = 2;

	constructor() {
		super();
	}

	getKeyshareVersion(): number {
		return this.KEY_SHARE_VERSION;
	}
	syncBackupTgCloud() {
		ServiceUser.syncBackupTgCloud({
			tgBackupVer: this.KEY_SHARE_VERSION.toString(),
		})
			.then(() => {
				console.log('backup tgCloud successfully');
			})
			.catch((e) => {
				console.log('backup tgCloud error', e);
			});
	}
	async saveKeyshareToTGCloud(): Promise<boolean> {
		console.log('Uploading to TG Cloud');
		console.time('UploadKeyshareToTGCloud');
		const keyShareObj = {
			ecdsaKeyShare: encodeHex(this.keyShares.ecdsaKeyShare.toBytes()),
			eddsaKeyShare: encodeHex(this.keyShares.eddsaKeyShare.toBytes()),
		};
		const keyShareStore = JSON.stringify(keyShareObj);
		const keyShareStoreEncrypted = CryptoJS.AES.encrypt(
			keyShareStore,
			TelegramCore.getUserId().toString(),
		).toString();

		const parts: string[] = [];
		for (let i = 0; i < keyShareStoreEncrypted.length; i += MAX_ITEM_SIZE) {
			const chunk = keyShareStoreEncrypted.slice(i, i + MAX_ITEM_SIZE);
			parts.push(chunk);
		}
		console.log('total parts', parts.length);
		const metadata = {
			version: this.getKeyshareVersion(),
			totalParts: parts.length,
		};
		localStorage.setItem(this.TC_KEY_SHARE_METADATA, JSON.stringify(metadata));

		const uploadData = await Promise.all(
			parts.map((item, index) => {
				const partKey = `${this.TC_KEY_SHARE_PART}${index + 1}`;
				localStorage.setItem(partKey, item);
				console.log('🚀 ~ KeyShareManagerV2 ~ parts.map ~ partKey:', partKey);
				return TGCloudStorage.setItem(partKey, item, () => {
					console.log('start remove partKey', partKey);
					localStorage.removeItem(partKey);
				}).catch((error) => {
					console.error(`Failed to upload part ${index + 1}:`, error);
					return null;
				});
			}),
		);
		if (uploadData.filter((value) => !value).length == 0) {
			TGCloudStorage.setItem(this.TC_KEY_SHARE_METADATA, JSON.stringify(metadata)).then(() => {
				console.timeEnd('UploadKeyshareToTGCloud');
				dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.SUCCESS);
				localStorage.removeItem(this.TC_KEY_SHARE_METADATA);
				this.syncBackupTgCloud();
				return true;
			});
		} else {
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.ERROR);
			return false;
		}
	}

	async saveKeyshareToCloudIfNeeded(): Promise<boolean> {
		const metadataStr = localStorage.getItem(this.TC_KEY_SHARE_METADATA);
		if (isEmpty(metadataStr)) return;
		console.log('Continue uploading to TG Cloud');
		const metadata = JSON.parse(metadataStr);
		const parts: Promise<any>[] = [];
		for (let i = 0; i < metadata.totalParts; i++) {
			const keySharePart = localStorage.getItem(`${this.TC_KEY_SHARE_PART}${i + 1}`);
			if (!isEmpty(keySharePart)) {
				parts.push(
					TGCloudStorage.setItem(`${this.TC_KEY_SHARE_PART}${i + 1}`, keySharePart, () => {
						localStorage.removeItem(`${this.TC_KEY_SHARE_PART}${i + 1}`);
					}),
				);
			}
		}

		Promise.all(parts).then((values) => {
			if (values.filter((value) => !value).length == 0) {
				TGCloudStorage.setItem(this.TC_KEY_SHARE_METADATA, JSON.stringify(metadata)).then(() => {
					dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.SUCCESS);
					localStorage.removeItem(this.TC_KEY_SHARE_METADATA);
					this.syncBackupTgCloud();
				});
			} else {
				dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.ERROR);
			}
		});
	}

	async loadFromCloud(version: number, totalParts: number): Promise<string> {
		console.log('TGC keyshare version:', version);
		switch (version) {
			case 2:
				return await this.loadFromCloudVersion2(totalParts);
			default:
				await TGCloudStorage.removeItems([
					this.TC_KEY_SHARE_METADATA,
					...Array.from({ length: totalParts }).map((_, i) => `${this.TC_KEY_SHARE_PART}${i + 1}`),
				]);
				throw Error('Unsupported KeyShare Version');
		}
	}
	async loadFromCloudVersion2(totalParts: number): Promise<string> {
		const parts = await Promise.all(
			Array.from({ length: totalParts }).map((_, i) =>
				TGCloudStorage.getItem(`${this.TC_KEY_SHARE_PART}${i + 1}`, (value: string) => {
					return {
						part: i + 1,
						data: value,
					};
				}),
			),
		);

		if (parts.filter((p) => p.part <= 0).length > 0) throw Error('Error retrieving the cloud keyshare');
		parts.sort((a, b) => a.part - b.part);

		const encryptedData = parts.reduce((a, c) => (a += c.data), '');
		console.log('TGC encryptedData length', encryptedData?.length);
		const keyshareEncrypted = CryptoJS.AES.decrypt(encryptedData, TelegramCore.getUserId().toString()).toString(
			CryptoJS.enc.Utf8,
		);
		return keyshareEncrypted;
	}

	shouldMigrate(): boolean {
		return !this.keyShares.ecdsaKeyShare;
	}
	public getLocalKeyshareStored() {
		return this.getKeyshareStored();
	}
}

export default new KeyShareManagerV2();

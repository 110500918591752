import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { uniqueId } from '@/app-helpers/random';
import { isRouteParamsEmpty } from '@/app-hooks/swap';
import { calcMinAmountOutFromSlippage } from '@/app-hooks/swap/helper';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';
import { WIP_ADDRESS, piperv3SwapRouterAddress, v2RouterAddress } from '@piperx/sdk/dist/constant';
import { swap } from '@piperx/sdk/dist/core';
import { routingExactInput } from '@piperx/sdk/dist/routing';
import { useMutation } from '@tanstack/react-query';

import { getSigner } from '@/app-helpers/web3';
import { useUserSettingsStore } from '@/app-store/settings';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import BigNumber from 'bignumber.js';

// from their sdk routerTokenApproval()
const getRouterAddress = (path: string[]) => {
	if (path[1].length < 10) {
		// v3 swap
		return piperv3SwapRouterAddress;
	} else {
		// v2 swap
		return v2RouterAddress;
	}
};

const getTokenAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? WIP_ADDRESS : token.address);

export type RoutePiperX = {
	bestRoute: string[];
	maxAmountOut: BigNumber[] | BigNumber;
};
class Piperx extends SwapAbstract<RoutePiperX> {
	provider = SwapProvider.PIPERX;
	formatSlippage(slippage: string | number): number {
		return +slippage === AUTO_SLIPPAGE ? 1 : +slippage;
	}

	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		if (isRouteParamsEmpty(paramsSwap)) return;

		const { tokenIn, tokenOut, amountIn } = paramsSwap;
		const { signer } = await getSigner(ChainId.STORY_TESTNET);
		const route = await routingExactInput(getTokenAddress(tokenIn), getTokenAddress(tokenOut), amountIn, signer);
		console.log({ route });
		if (signal.aborted) throw new Error('Cancelled');
		return formatRoute(route, paramsSwap);
	}

	extractRoute(params: SelectedRoute<RoutePiperX>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const PiperxSwap = new Piperx();

const formatRoute = (routeData: RoutePiperX, paramsSwap: ArgsGetRoute): SelectedRoute<RoutePiperX> =>
	routeData
		? {
				...routeData,
				routerAddress: getRouterAddress(routeData.bestRoute),
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.PIPERX,
				timestamp: Date.now(),
				params: paramsSwap,
				tokenIn: paramsSwap.tokenIn,
				tokenOut: paramsSwap.tokenOut,
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RoutePiperX>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const amountIn = selectedRoute?.params?.amountIn;
	const amountOut = Array.isArray(routeSwap?.maxAmountOut)
		? routeSwap?.maxAmountOut?.[routeSwap?.maxAmountOut?.length - 1]?.toString?.()
		: routeSwap?.maxAmountOut?.toString?.();

	return {
		amountOut,
		amountIn,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative: BigInt(CHAIN_CONFIG[ChainId.STORY_TESTNET].minForGas),
		gasDisplay: '< 0.01$',
		dappInfo: {
			logo: '/icons/brands/piperx.jpeg',
			domain: SwapProvider.PIPERX,
		},
	};
};

export const useExecuteRoutePiperX = () => {
	const { slippage } = useUserSettingsStore();
	const response = useMutation({
		mutationKey: ['exe-route-piperx'],
		mutationFn: async (routeData: SelectedRoute<RoutePiperX>) => {
			const { tokenOut, route } = routeData;
			const { amountIn, amountOut } = PiperxSwap.extractRoute(routeData, {});
			const minOut = calcMinAmountOutFromSlippage({
				tokenOut,
				amountOut,
				slippage: PiperxSwap.formatSlippage(slippage),
			});
			const { signer } = await getSigner(ChainId.STORY_TESTNET);
			const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
			const deadline = currentTimestamp + 600; // Add 10 minutes
			const resp = await swap(amountIn, minOut, route.bestRoute, deadline, signer);
			return { hash: resp.hash };
		},
	});
	return response;
};

import { ButtonSecondary, CTAButton, TooltipInfo } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ITokenSearch } from '@/app-cores/api/bff';
import { displayAmount } from '@/app-helpers/display';
import { formatUnits } from '@/app-helpers/number';
import { getTokenInfo } from '@/app-helpers/token';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { CreateTokenMetadata } from '@/app-services/pump.fun';
import { colors } from '@/app-theme/theme';
import { InfoItem } from '@/app-views/swap/pages/ConfirmTransaction';
import { createMemeFields } from '@/app-views/tobi-fun/CreateMeme';
import { PUMP_TOKEN_DECIMALS } from '@/app-views/tobi-fun/helpers';
import {
	Card,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Content = ({ label, children }) => {
	return (
		<Flex direction={'column'} gap={2}>
			<Text color={colors.gray[400]} fontSize={'14px'} fontWeight={'500'}>
				{label}
			</Text>
			<Card
				flexDirection={'column'}
				background={'white'}
				gap={'10px'}
				borderRadius={'12px'}
				padding={'12px 16px'}
			>
				{children}
			</Card>
		</Flex>
	);
};

export default function ConfirmCreateMeme({
	isOpen = false,
	onClose,
	onConfirm,
	tokenInfo,
	logo,
	estimateAmountSol,
	tokenIn: _tokenIn,
	estimateAmountOut,
	estimateFee,
}: {
	isOpen: boolean;
	onConfirm: () => Promise<boolean>;
	onClose: () => void;
	tokenInfo: CreateTokenMetadata;
	estimateAmountSol: bigint;
	logo: string;
	tokenIn: ITokenSearch;
	estimateAmountOut: bigint;
	estimateFee: bigint;
}) {
	const { t } = useTranslation();
	const tokenIn = getTokenInfo(_tokenIn);

	const renderTradeInfo = () => {
		return (
			<Content label={'Token info'}>
				{createMemeFields.map(
					(field) =>
						tokenInfo[field.name] && (
							<InfoItem
								key={field.name}
								title={field.label}
								info1={
									<Center gap={1}>
										<Text fontSize="sm">{tokenInfo[field.name] || '-'}</Text>
									</Center>
								}
							/>
						),
				)}
				<InfoItem title={'Logo'} info1={<TokenLogo size={22} logo={logo} />} />
			</Content>
		);
	};

	const renderSwapInfo = () => {
		return (
			<Content label={'Buy Info'}>
				<InfoItem
					title="You pay"
					info1={
						<Center gap={1}>
							<Text fontSize="sm" textAlign={'right'}>
								{displayAmount({ amount: estimateAmountSol, token: tokenIn })}
							</Text>
						</Center>
					}
				/>
				<InfoItem
					title="Receive"
					info1={
						<Center gap={1}>
							<Text fontSize="sm" textAlign={'right'}>
								≈ {formatUnits(estimateAmountOut, PUMP_TOKEN_DECIMALS)} {tokenInfo?.symbol}
							</Text>
						</Center>
					}
				/>
				<InfoItem
					title="Estimated fee"
					info1={
						<Center gap={1}>
							<Text fontSize="sm" textAlign={'right'}>
								≈ {displayAmount({ amount: estimateFee, token: tokenIn })}
							</Text>
							<TooltipInfo label={t('tokenTrading.gasFeeTooltip')}></TooltipInfo>
						</Center>
					}
				/>
			</Content>
		);
	};

	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!isOpen) {
			setLoading(false);
			setSubmitted(false);
		}
	}, [isOpen]);

	return (
		<Drawer isOpen={isOpen} placement="bottom" isFullHeight onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent pt={getFullScreenSpace(0, 76)}>
				<DrawerCloseButton top={getFullScreenSpace(0, 86)} />
				<DrawerHeader>Confirm Launch Token</DrawerHeader>
				<DrawerBody display={'flex'} flexDirection={'column'} gap={4}>
					<Flex flex={1} flexDirection={'column'} gap={4}>
						{renderTradeInfo()}
						{renderSwapInfo()}
					</Flex>
					{submitted && (
						<ButtonSecondary
							background={colors.gray[100]}
							size="lg"
							width="100%"
							onClick={() => navigate(NAVIGATE_PATHS.TobiFun.Profile)}
						>
							Go to Profile
						</ButtonSecondary>
					)}
					<CTAButton
						isDisabled={loading}
						colorScheme="cyan"
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={async () => {
							if (submitted) {
								onClose();
								return;
							}
							if (loading) return;
							setLoading(true);
							const ok = await onConfirm();
							setSubmitted(ok);
							setLoading(false);
						}}
					>
						{loading ? <Spinner size={'sm'} /> : submitted ? t('button.back') : 'Launch'}
					</CTAButton>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}

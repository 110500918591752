import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { SelectTokenLabel } from '@/app-components/common/SelectToken/TokenAmount';
import { CryptoSearchDrawer, SearchMode } from '@/app-components/common/crypto-search';
import { ChainId, MAINNET_CHAINS } from '@/app-constants/chains';
import { getTokenInfo } from '@/app-helpers/token';
import { useOnEventCallback } from '@/app-hooks/common';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { FiRightIcon } from '@/assets/icons';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
import { Box, Center, Flex, Skeleton, SlideOptions, Text } from '@chakra-ui/react';
import { truncate } from 'lodash';
import { CSSProperties, ReactNode, memo, useState } from 'react';
import { ITokenSearch } from '../../../app-cores/api/bff';

type Props = {
	token: ITokenSearch | undefined;
	chainIds?: ChainId[]; // to filter out tokens in search modal
	chainId?: ChainId | string; // to filter out token in search modal
	onSelectToken: (token: ITokenSearch) => void;
	chainStyle?: CSSProperties;
	tokenStyle?: {
		logoSize?: number;
		symbolGap?: string;
	};
	searchMode: SearchMode;
	style?: CSSProperties;
	autoFocus?: boolean;
	arrowRight?: boolean;
	disabled?: boolean;
	defaultQuery?: string;
	placement?: SlideOptions['direction'];
	networkText?: string;
	inputPlaceholder?: string;
	loading?: boolean;
};

const supportChainIds = MAINNET_CHAINS.map((e) => e.id);

export const SelectToken = ({
	token,
	chainId,
	chainIds,
	onSelectToken,
	chainStyle,
	tokenStyle,
	searchMode,
	style,
	autoFocus,
	arrowRight = false,
	disabled,
	defaultQuery: defaultQuery,
	placement,
	networkText,
	inputPlaceholder,
	loading: loadingParam,
}: Props) => {
	const [openSearchToken, setOpenSearchToken] = useState(false);
	const wrapOnSelectToken = useOnEventCallback((token) => {
		onSelectToken(token);
		setOpenSearchToken(false);
	});

	const loading = loadingParam && !token;

	const { symbol, imageUrl, ...rest } = getTokenInfo((token || {}) as ITokenSearch);

	return (
		<>
			<Flex
				onClick={() => !disabled && setOpenSearchToken(true)}
				sx={{
					height: '40px',
					borderRadius: '100px',
					fontSize: '12px',
					fontWeight: '600',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '4px',
					paddingLeft: '8px',
					paddingRight: '4px',
					border: `1px solid ${BASE_BORDER_COLOR}`,
					cursor: disabled ? 'not-allowed' : 'pointer',
					userSelect: 'none',
					whiteSpace: 'nowrap',
					backgroundColor: '#fff',
					...style,
				}}
			>
				<Flex alignItems={'center'} gap={tokenStyle?.symbolGap || '6px'} flex={1}>
					{token || loading ? (
						<Center gap={3}>
							{loading ? (
								<Skeleton
									opacity="0.3"
									width={`${tokenStyle?.logoSize || 22}px`}
									height={`${tokenStyle?.logoSize || 22}px`}
									borderRadius={'100%'}
								/>
							) : (
								<TokenLogo
									size={tokenStyle?.logoSize || 22}
									symbol={symbol}
									logo={imageUrl}
									chainId={rest?.chainId}
									chainStyle={chainStyle}
								/>
							)}
							<Box>
								<Text>{loading ? 'fetching' : truncate(symbol, { length: 20 }) || 'Select token'}</Text>
								{networkText && (
									<Text fontSize="smaller" color="gray.400">
										{networkText}
									</Text>
								)}
							</Box>
						</Center>
					) : (
						'Select Token'
					)}
				</Flex>
				{disabled ? null : arrowRight ? <FiRightIcon width={20} height={20} /> : <ChevronDownIcon width={18} />}
			</Flex>
			<CryptoSearchDrawer
				mode={searchMode}
				chainId={chainId}
				chainIds={chainIds}
				isOpen={openSearchToken}
				onClose={() => setOpenSearchToken(false)}
				onSelectToken={wrapOnSelectToken}
				autoFocus={autoFocus}
				defaultQuery={defaultQuery}
				placement={placement}
				inputPlaceholder={inputPlaceholder}
				selectedToken={token}
				hasChildren={true}
				backButtonCallback={() => setOpenSearchToken(false)}
				skipNavigationWhenBack={true}
			/>
		</>
	);
};

const SelectTokenWithLabel = (props: Props & { label: ReactNode }) => {
	const { label } = props;
	return (
		<Flex flexDirection={'column'} gap={'10px'}>
			<SelectTokenLabel text={label} />
			<Flex
				sx={{
					borderRadius: '12px',
					padding: '10px 16px',
					backgroundColor: '#F9F9F9',
				}}
			>
				<SelectToken
					{...props}
					arrowRight
					style={{ background: 'none', border: 'none', width: '100%', paddingLeft: 0 }}
				/>
			</Flex>
		</Flex>
	);
};

export default memo(SelectTokenWithLabel);

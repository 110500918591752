import { Loading } from '@/app-components/common';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { Container } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageNavigation from '../../app-components/layout/PageWrapper/PageWrapper';

import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { ELEMENT_IDS } from '@/app-constants';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { useGetAffiliateTokensStat } from '@/app-hooks/api/user/affiliate';
import { AffiliateTokenFilerParams } from '@/app-services/fee';
import { WalletBalance } from '@/app-views/affiliate/containers/FeeEarn/MyBalance';
import { MyEarning } from '@/app-views/affiliate/containers/FeeEarn/MyEarning';

const FeeEarn: React.FC = () => {
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();
	const [filter, setFilter] = useState({});
	const { data, isFetching } = useGetAffiliateTokensStat(filter);

	const onRefresh = (data: AffiliateTokenFilerParams) => {
		setFilter(data);
	};

	if (!userProfile) {
		return <Loading />;
	}

	return (
		<PageTransition>
			<PageNavigation>
				<Container
					py={4}
					pb={getFullScreenSpace(80, 86)}
					className="overflow-y-auto hide-scrollbar"
					id={ELEMENT_IDS.HOME_CONTAINER}
				>
					<WalletBalance totalUsd={data?.totalUsd ?? 0} />
					<MyEarning data={data} isFetching={isFetching} onRefresh={onRefresh} />
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};

export default FeeEarn;

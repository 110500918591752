import { Header } from '@/app-components/common';
import BackgroundImage from '@/app-components/layout/BackGround/ContainerWithBackgroundImage';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { Box, BoxProps } from '@chakra-ui/react';
import { CSSProperties, MutableRefObject, ReactNode } from 'react';

interface AppLayoutProps extends BoxProps {
	header?: ReactNode;
	footer?: ReactNode;
	children: ReactNode;
	backAble?: boolean;
	headerStyle?: CSSProperties;
	containerRef?: MutableRefObject<HTMLDivElement>;
	disableTransition?: boolean;
	hasBackground?: boolean;
	wrapperStyle?: CSSProperties;
}
// without header: search, ...
const PageLayout: React.FC<AppLayoutProps> = ({
	children,
	header,
	footer,
	backAble = true,
	onScroll,
	headerStyle,
	containerRef,
	disableTransition,
	hasBackground = false,
	wrapperStyle,
	...rest
}) => {
	const { background, ...style } = rest?.style || {};
	return (
		<PageTransition disableTransition={disableTransition}>
			<PageNavigation backable={backAble}>
				<BackgroundImage hasBackground={hasBackground} className="pageContent">
					<Box className="pageFixed" pb={getFullScreenSpace(0, 100)} style={wrapperStyle}>
						{header && <Header style={headerStyle}>{header}</Header>}
						<Box
							flexDirection="column"
							overflow="hidden"
							className="scrollbar-trigger"
							flexGrow={1}
							{...rest}
							background={background as string}
							style={style}
						>
							<Box
								px={4}
								overflow="auto"
								className="hide-scrollbar"
								height="100%"
								onScroll={onScroll}
								id="pageLayout"
								ref={(ref) => containerRef && (containerRef.current = ref)}
							>
								{children}
							</Box>
						</Box>
						{footer}
					</Box>
				</BackgroundImage>
			</PageNavigation>
		</PageTransition>
	);
};
export default PageLayout;

import { Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { ELEMENT_IDS } from '@/app-constants';
import { getFullScreenSpace } from '@/app-helpers/ui';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useResetStorageData } from '@/app-hooks/common';
import { Container } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageNavigation from '../../../app-components/layout/PageWrapper/PageWrapper';
import { CreateEddsaWalletAlert } from '../components/CreateEddsaWallet';
import { MenuAction } from '../components/MenuAction';
import { MyPortfolio } from '../components/Portfolio';
import { RefreshLoading } from '../components/RefreshLoading';
import { WalletBalance } from '../components/WalletBalance';

const Wallet: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	useResetStorageData();
	const { data: userProfile } = useQueryUserProfile();
	const [, setSearchState] = useState(false);
	useEffect(() => {
		if (location.state && 'search' in location.state) {
			setSearchState(true);
		} else {
			setSearchState(false);
		}
	}, [location.state]);

	const openSearch = useCallback(() => {
		const search = new URLSearchParams(location.search);
		search.delete('page');
		search.delete('action');
		navigate(
			{
				...location,
				search: search.toString(),
			},
			{
				state: {
					search: '',
				},
			},
		);
	}, [location, navigate]);

	const closeSearch = useCallback(() => {
		const search = new URLSearchParams(location.search);
		navigate(
			{
				...location,
				search: search.toString(),
			},
			{
				state: undefined,
			},
		);
	}, [location, navigate]);

	if (!userProfile) {
		return <Loading />;
	}

	return (
		<PageTransition>
			<PageNavigation>
				<Container
					py={4}
					id={ELEMENT_IDS.HOME_CONTAINER}
					pb={getFullScreenSpace(80, 86)}
					className="overflow-y-auto hide-scrollbar"
				>
					{/* {tourGuideManager.isEnableHomePage() && (
						<FeatureConfigWrap feature={FeatureConfig.TOBI_HOME_WORK_THROUGH}>
							<HomeTourGuide />
						</FeatureConfigWrap>
					)} */}
					<RefreshLoading />
					<CreateEddsaWalletAlert />
					{/* <Header userProfile={userProfile} openSearch={openSearch} /> */}
					<WalletBalance />
					<MenuAction />
					{/* <FeatureConfigWrap feature={FeatureConfig.TRADING_TOKEN}>
						<TokenTrading />
					</FeatureConfigWrap> */}
					{/* <FeatureConfigWrap
						feature={FeatureConfig.DAPP_HOMEPAGE}
						deviceFeatures={{
							android: FeatureConfig.SHOW_DAPP_ON_ANDROID_DEVICE,
							forceShowOnAndroid: false,
						}}
					>
						<Dapp />
					</FeatureConfigWrap> */}
					{/* <FeatureConfigWrap feature={FeatureConfig.TRENDING_TOKEN}>
						<TrendingTokens />
					</FeatureConfigWrap> */}
					{/* <FeatureConfigWrap feature={FeatureConfig.TOBI_FARM}>
						<TobiFarm />
					</FeatureConfigWrap> */}
					<MyPortfolio />
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};

export default Wallet;
